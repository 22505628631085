import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Footer } from "../components/footer";
import { Header2 } from "../components/header2"
import { applyMutation, applyMutationVariables } from "../__generated__/applyMutation";
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { MainHeader } from "../components/mainHeader";
import Debate from '../images/main.jpg';
import Idea from '../images/idea.jpg';
import Logo from '../images/mast_logo.png'
import Analytics from "../analytics";
import { Link } from "react-router-dom";


export const APPLY_MUTATION = gql`
  mutation applyMutation($applyInput: applyProgramInput!) {
    apply(input: $applyInput) {
      ok,
      error
    }
  }
`;

interface IForm {
  name: string;
  phone: string;
  region: string;
  idea: string;
  experience: string;
  comment: string;
}

declare global {
  interface Window {
      IMP:any;
  }
}

export const Payment = () => {
  const [open, setOpen] = useState(true);
  
  const requestPay = () => {
    const { name, phone, region, idea, experience, comment } = getValues();
    // IMP.request_pay(param, callback) 결제창 호출
    var IMP = window.IMP; // 생략 가능
    IMP.init("imp10245804");
    
    IMP.request_pay({ // param
      pg: "html5_inicis",
      pay_method: "card",
      merchant_uid: `mast_${new Date().getTime()}`,  //각각 다르게 설정해줘야
      name: "아이디어 발굴하기",
      amount: 110000,
      buyer_email: localStorage.getItem('email'),
      buyer_name: name,
      buyer_tel: phone,
      buyer_addr: region,
    }, (rsp: any) => { // callback
      if (rsp.success) {
        console.log("success")
        onSubmit(`mast_${new Date().getTime()}`);
        // Analytics.purchaseConversionEvent;
      } else {
        console.log(rsp)
        console.log("fail")
      }
    });

  }
  const apply = () => {
    if(localStorage.getItem('token')) {
      history.push('/program/idea/apply')
    } else {
      // localStorage.setItem('directpage', '/program/idea/apply')
      history.push('/login')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if(!localStorage.getItem('token')) {
      alert('간단 회원가입 후 신청해주세요')
      history.push('/login')
    }
  },[]);

  const history = useHistory();
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();
  const onCompleted = (data: applyMutation) => {
    const {
      apply: { ok, error },
    } = data;

    if (ok) {
      alert('수강신청이 완료되었습니다.');
      localStorage.setItem('role', 'PaidStudent')
      history.push('/')
    } 
  };
  const [ applyMutation, { data: applyMutationResult, loading } ] = useMutation<applyMutation, applyMutationVariables>(APPLY_MUTATION, { onCompleted});
  const onSubmit = (merchant_uid: any) => {
      const { name, phone, region, idea, experience, comment } = getValues();
      applyMutation({
        variables: {
          applyInput: {
            name,
            phone,
            paid: true,
            region,
            merchant_uid,
            idea: (idea === 'yes'),
            experience: (experience === 'yes'),
            comment
          }
        }
      });
  }
  const inValid = () => {
    console.log(errors)
  }
  return (
    <>
      <Helmet>
        <script type="text/javascript" src="https://code.jquery.com/jquery-1.12.4.min.js" ></script>
        <script type="text/javascript" src="https://cdn.iamport.kr/js/iamport.payment-1.1.8.js"></script>
      </Helmet>
      <div className="w-11/12 sm:max-w-7xl mx-auto mt-5">
        <a href="/">
          <img
            className="h-8 w-auto sm:h-10"
            src={Logo}
            alt=""
          />
        </a>
      </div>
      <form className="" onSubmit={handleSubmit(requestPay, inValid)}>
        <div className="mt-24 w-11/12  sm:w-screen md:max-w-7xl mx-auto  sm:grid md:grid-cols-7">
          <div className="md:col-span-5">
            <div className="text-xl md:text-3xl font-semibold">수강신청하기</div>
            <div className="mt-10 text-lg md:text-xl font-semibold">강의 정보</div>
            <div className="md:grid md:grid-cols-12 mt-4">
              <div style={{backgroundImage: `url(${Idea})`, backgroundSize: '90% 100%', backgroundRepeat:"no-repeat", height:"120px"}} 
                className="h-96 rounded-sm col-span-2 hidden sm:inline-block"></ div>
              <div style={{backgroundImage: `url(${Idea})`, backgroundSize: '100% 100%', backgroundRepeat:"no-repeat", height:"200px"}} 
              className="h-96 rounded-sm col-span-2 sm:hidden"></ div>
              <div className="col-span-7 font-semibold mt-3">
                <div className="text-2xl">아이디어 발굴하기</div>
                <div className="mt-2 text-md text-red-500">
                  <span className="text-gray-500">가격: </span>
                  <span className="text-gray-500 line-through mr-2">550,000원</span>110,000원 (-80%)
                </div>
                <div className="mt-2 text-md text-gray-500">개강: 3월 9일 ( 수강기간: 4주 )</div>
              </div>
              <div className="col-span-2 mt-12">
              <span className="border bg-blue-500 p-3  text-white font-normal"><a href='https://vigorous-poincare-85189a.netlify.app/curriculum'>커리큘럼 상세보기</a></span>
              </div>
              <div className="col-span-12 sm:col-span-11 h-px w-full bg-gray-300 mt-4"></div>
            </div>
            <div className="mt-10 text-lg md:text-xl font-semibold">수강생 정보</div>
            <div className="grid grid-cols-12">
              <div className="mt-3 sm:mt-5 col-span-3">이름</div>
              <div className="mt-2 sm:mt-3 col-span-9">
                <input
                  {...register("name", {
                    required: "이름을 입력해주세요",
                  })}
                  type="text"
                  name="name"
                  autoComplete="given-name"
                  placeholder="신청자명을 입력해주세요"
                  className="max-w-lg block w-full border-2 py-2 px-1  focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-sm"
                />
              </div>
              <div className="mt-3 sm:mt-5 col-span-3">연락처</div>
              <div className="mt-2 sm:mt-3 col-span-9">
                <input
                  {...register("phone", {
                    required: "휴대폰번호를 입력해주세요",
                  })}
                  name="phone"
                  type="phone"
                  autoComplete="phone"
                  placeholder="휴대폰 번호를 입력해주세요 ex) 010-0000-000"
                  className="max-w-lg block w-full border-2 py-2 px-1  focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                />
              </div>
              <div className="mt-3 sm:mt-5 col-span-3">지역</div>
              <div className="mt-2 sm:mt-3 col-span-9">
                <select
                  {...register("region", {
                    required: "지역을 입력해주세요",
                  })}
                  id="region"
                  name="region"
                  className="max-w-lg block w-full border-2 py-2 px-1  focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                  defaultValue="서울"
                >
                  <option>서울</option>
                  <option>경기</option>
                  <option>부산</option>
                  <option>광주</option>
                  <option>대구</option>
                  <option>대전</option>
                  <option>제주</option>
                  <option>인천</option>
                </select>
                {/* <input
                  {...register("region", {
                    required: "지역을 입력해주세요.",
                  })}
                  name="region"
                  type="region"
                  autoComplete="region"
                  placeholder="지역을 입력해주세요 ex) 서울, 광주, 대구"
                  className="max-w-lg block w-full border-2 py-2 px-1  focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                /> */}
              </div>
              <div className="mt-3 sm:mt-5 col-span-12 sm:col-span-3">아이디어 유무</div>
              <div className="pt-6 sm:pt-5 col-span-12 sm:col-span-9">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:items-baseline">
                    <div className="">
                      <div className="">
                        <p className="text-sm text-gray-500">현재 가지고 있는 창업 아이디어가 있으신가요?</p>
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              {...register("idea", {
                                required: "아이디어 유무를 체크해주세요",
                              })}
                              name="idea"
                              type="radio"
                              value="yes"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="idea" className="ml-3 block text-sm font-medium text-gray-700">
                              아이디어 있음
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              {...register("idea", {
                                required: "아이디어 유무를 체크해주세요",
                              })}
                              name="idea"
                              type="radio"
                              value="no"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="no-idea" className="ml-3 block text-sm font-medium text-gray-700">
                              아이디어 없음
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 sm:mt-5 col-span-12 sm:col-span-3">창업 경험</div>
              <div className="pt-6 sm:pt-5 col-span-12 sm:col-span-9">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:items-baseline">
                    <div className="">
                      <div className="">
                        <p className="text-sm text-gray-500">창업을 해본 경험이 있거나 현재 사업을 하고 계신가요?</p>
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              {...register("experience", {
                                required: "아이디어 유무를 체크해주세요",
                              })}
                              name="experience"
                              type="radio"
                              value="yes"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="experience" className="ml-3 block text-sm font-medium text-gray-700">
                              경험 있음
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              {...register("experience", {
                                required: "아이디어 유무를 체크해주세요",
                              })}
                              name="experience"
                              type="radio"
                              value="no"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="no-experience" className="ml-3 block text-sm font-medium text-gray-700">
                              경험 없음
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 sm:mt-6 col-span-12 sm:col-span-3">남기고 싶은 말</div>
              <div className="mt-2 sm:mt-5 col-span-12 sm:col-span-9">
                <textarea
                  {...register("comment", {
                    required: "남기고 싶은 말을 적어주세요",
                  })}
                  name="comment"
                  rows={6}
                  className="p-2 max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={''}
                />
              </div>
            </div>
          </div>
          <div className="mt-28 sm:mt-1 col-span-12 sm:col-span-2">
            <div className="sm:sticky sm:top-14 border rounded-md px-6 pt-10 pb-6">
              <div className="md:text-xl font-semibold">결제 정보</div>
              <div className="grid grid-cols-2">
                <div className="text-sm mt-3 text-gray-500">총 상품 금액</div>
                <div className="text-sm mt-3 text-gray-500 grid justify-end">550,000 원</div>
                <div className="text-sm mt-3 text-gray-500">할인 금액</div>
                <div className="text-sm mt-3 grid justify-end text-red-600">(-80%) - 440,000 원</div>
                <div className="col-span-2 h-px w-full bg-gray-300 mt-4"></div>
                <div className="text-sm mt-3 text-gray-500">결제 가격</div>
                <div className="text-sm mt-3 text-gray-500 grid justify-end">110,000 원</div>
                {/* <div className="text-md mt-4 text-gray-900">6개월 할부</div>
                <div className="text-md mt-4 text-gray-900 grid justify-end">월 50,000 원</div> */}
                <button className="mt-3 py-2 col-span-2 bg-red-500 rounded-md text-white text-base sm:text-xl">수강신청하기</button> 
                <div className="col-span-2 text-xs mt-2 text-gray-400 grid justify-center">개인정보수집, 전자금융거래 기본약관,</div>           
                <div className="col-span-2 text-xs mt-1 text-gray-400 grid justify-center">개인정보처리방침에 동의합니다.</div>           
              </div>

            </div>
          </div>
        </div>
      </form>
      
      
      
      <Footer />
    </>
  )
}
