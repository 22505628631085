import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Design from '../images/design3.png';


interface ProgramCardProps {
  reviewPosts: any
}


export const SubRegular1LectureCard:React.FC<ProgramCardProps> = ({reviewPosts}) => {
  const moveToDetail = () => {
    alert('배 설계 및 조립반 과정은 12월에 시작될 예정입니다.')
  }
  return (
    <div key={reviewPosts.id} style={{backgroundImage: `url(${Design})`, backgroundSize: '100% 41%', backgroundRepeat:"no-repeat", height:"490px"}} 
      className="transform  hover:scale-105 p-5 border-main cursor-pointer bg-white  bg-opacity-50 shadow-lg h-96 border-2 rounded-2xl border-transparent border-opacity-50 hover:border-opacity-100"
      onClick={moveToDetail}
    >
      <div className="w-full mt-3 text-main GmarketB text-2xl">2. 예비창업단계 </div>
      {/* <div className="w-full ml-2 text-main GmarketB text-2xl">(예비창업단계)</div> */}
      <div className="w-8/12 mt-6 text-main GmarketM text-lg max-w-lg font-bold">아이템이 만들어졌다면 고객을 자세히 보는 단계</div>
      <div className="mt-20 text-black GmarketB text-2xl">"3달간 믿고 따라오세요."</div>
      <div className="mt-4 text-black GmarketL text-sm">- 배우고, 정리하고, 발표합니다.</div>
      <div className="text-black GmarketL text-sm">- 지금 당장 실현 가능한 사업을 실행합니다.</div>
      <div className="text-black GmarketL text-sm">- 자본금, 팀원 걱정은 나중에!</div>
      <div className="text-black GmarketL text-sm">- 가벼운 매출을 만드는 단계</div>
      <div className="text-black GmarketL text-sm">- 지금은 가장 쉽게 사업을 시작해봅시다.</div>
      <div className="text-black GmarketL text-sm">- 안되면 될 때까지 함께 합니다.</div>
      <div className="grid justify-items-center">
        <button className="mt-8 text-white GmarketM text-sm px-6 py-2 shadow-lg rounded-3xl border justify-center bg-main"><Link to={'/program/idea'}>수강신청 하러가기</Link></button>
      </div>
    </div>
  )
}