/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useHistory } from 'react-router';
import { SubLogBook1_1 } from '../subcomponents/logbook1-1';
import { SubLogBook2 } from '../subcomponents/logbook2';
import { SubLogBook3_1 } from '../subcomponents/logbook3-1';

const steps = [
  { name: '1번째 항해일지', href: '#', status: 'current' },
  { name: '2번째 항해일지', href: '#', status: 'upcoming' },
  { name: '3번째 항해일지', href: '#', status: 'upcoming' },
  { name: '4번째 항해일지', href: '#', status: 'upcoming' },
  { name: '5번째 항해일지', href: '#', status: 'upcoming' },
  { name: '6번째 항해일지', href: '#', status: 'upcoming' },
  { name: '7번째 항해일지', href: '#', status: 'upcoming' },
  { name: '8번째 항해일지', href: '#', status: 'upcoming' },
  { name: '9번째 항해일지', href: '#', status: 'upcoming' },
  { name: '10번째 항해일지', href: '#', status: 'upcoming' },
  { name: '11번째 항해일지', href: '#', status: 'upcoming' },
  { name: '12번째 항해일지', href: '#', status: 'upcoming' },
  { name: '13번째 항해일지', href: '#', status: 'upcoming' },
  { name: '14번째 항해일지', href: '#', status: 'upcoming' },
  { name: '15번째 항해일지', href: '#', status: 'upcoming' },
  { name: '16번째 항해일지', href: '#', status: 'upcoming' },
  { name: '17번째 항해일지', href: '#', status: 'upcoming' },
  { name: '18번째 항해일지', href: '#', status: 'upcoming' },
  { name: '19번째 항해일지', href: '#', status: 'upcoming' },
  { name: '20번째 항해일지', href: '#', status: 'upcoming' },
  { name: '21번째 항해일지', href: '#', status: 'upcoming' },
  { name: '22번째 항해일지', href: '#', status: 'upcoming' },
  { name: '23번째 항해일지', href: '#', status: 'upcoming' },
  { name: '24번째 항해일지', href: '#', status: 'upcoming' },
]

export const SubSailingStart = () => {
  const history = useHistory();
  const [ level, setLevel ] = useState(0);
  const [ isStart, setIsStart] = useState(false);
  const Start = (level: number) => {
    setLevel(level);
    setIsStart(true);
    console.log(level, " ", isStart)
  }
  const returnPage = () => {
    history.push('/step')
  }
  return (
    <>
      <div className="flex pl-5 pt-4 items-center cursor-pointer" onClick={returnPage}>
        <ChevronLeftIcon className="w-9 h-9 " />
        <span>돌아가기</span>
      </div>
      {level=== 1 && <SubLogBook1_1 />}
      {level=== 3 && <SubLogBook3_1 />}
      {level=== 2 && <SubLogBook2 />}
      { !isStart &&
        <div className="py-12 px-4 sm:px-6 lg:px-8 mt-52">
          <nav className="flex justify-center" aria-label="Progress">
            <ol role="list" className="space-y-6">
              {steps.map((step, i) => (
                <li key={step.name} className="justify-center flex">
                  {step.status === 'complete' ? (
                    <a href={step.href} className="group">
                      <span className="flex items-center">
                        <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                          <CheckCircleIcon
                            className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </a>
                  ) : step.status === 'current' ? (
                    <div className="justify-items-center grid">
                      <div>
                        <a href={step.href} className="flex justify-items-center" aria-current="step">
                          <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                            <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                            <span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                          </span>
                          <span className="ml-3 text-sm font-medium text-indigo-600">{step.name}</span>
                          
                        </a>
                      </div>
                      <div className="px-20 sm:px-48 py-5 mt-5 bg-main text-white text-center cursor-pointer" onClick={() => (Start(i+1))}>항해일지 작성</div>
                    </div>
                  ) : (
                    <a href={step.href} className="group">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                          <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                        </div>
                        <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</p>
                      </div>
                    </a>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
      }
    </>
  )
}
