import { useState } from "react"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LevelStatus } from "../components/Levelstatus"
import Boat from '../images/boat.gif';


interface IForm {
  problem: string;
  solution: string;
  BM: string;
  review: number;
  comment:string;
}

export const SubLogBook3_1 = () => {
  const [step, setStep] = useState(1);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  const temporarySave = () => {
      alert('임시저장 되었습니다.');
      // commentPSMMutation({
      //   variables: {
      //     commentPSMInput: {
      //       problem,
      //       solution,
      //       BM
      //     }
      //   }
      // });
  }

  const previous = () => {
    setStep(step-1);
  }
  const onSubmit = () => {
    const { problem, solution, BM, comment } = getValues();
    if( step === 3 ) {
      if((problem && solution && BM)) {
        alert('제출되었습니다.')
        // commentPSMMutation({
        //   variables: {
        //     commentPSMInput: {
        //       problem,
        //       solution,
        //       BM
        //     }
        //   }
        // });
        
      } else {
        alert('모든 질문에 답변해주세요.')
      }
    } else {
      setStep(step +1);
    }
  }
  const inValid = () => {
    console.log(errors)
  }


  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap h-screen" >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        <div className="text-4xl GmarketM transition-opacity text-center mb-12" style={{width:" 500px"}}>3일차 항해일지</div>
        <div className="text-lg GmarketM transition-opacity text-center mb-20 text-red-400" style={{width:" 500px"}}>수강생에 한해서 강사님의 구체적인 피드백을 받을 수 있습니다.</div>
        <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
        {step ===1 && 
          <div className=" mt-10">
            <label htmlFor="problem" className=" text-lg font-medium text-gray-700 ">
              1. 문제가 뭐야? (Problem)
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 지금 폰을 켜서 앱을 하나 켜보세요</p>
            <p className="mt-2 text-sm text-gray-500">- 그 앱이 생각하는 문제는 무엇인가요?  </p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙(slack) : 개인용과 분리된 업무용 메신저가 필요하다. </p>
            <div className="mt-1">
              <textarea
              {...register("problem", {
                required: "이름을 입력해주세요",
              })}
                id="problem"
                name="problem"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                defaultValue={''}
              />
            </div>
          </div>
        }
        {step ===2 && 
          <div className="mt-20">
            <label htmlFor="solution" className=" text-lg font-medium text-gray-700">
              2. 어떻게 해결할꺼야? (Solution)
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 위의 앱은 어떤 방식으로 문제를 해결하나요?</p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙 : 회사에서만 사용하는 앱을 만들겠다.</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("solution", {
                  required: "이름을 입력해주세요",
                })}
                id="solution"
                name="solution"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                defaultValue={''}
              />
            </div>
          </div>
        }
        {step ===3 && 
          <div className="mt-20">
            <label htmlFor="BM" className=" text-lg font-medium text-gray-700">
              3. 돈은 누가 낼꺼야? (Money)
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 위의 앱은 누가 돈을 내게 될지 적어보세요.</p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙 : 사용하는 기업이 돈을 낼꺼야. 자 쉽죠? 한번 적어보세요 </p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("BM", {
                  required: "이름을 입력해주세요",
                })}
                id="BM"
                name="BM"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                defaultValue={''}
              />
            </div>
          </div>
        }
        <div className="flex justify-center mt-12">
          {/* <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            임시 저장
          </button> */}
          {step !== 1 &&
            <button
              type="button"
              onClick={previous}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              이전
            </button>
          }
          <button
            type="button"
            onClick={temporarySave}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {'임시저장'}
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {step === 3 ? '제출' : '다음'}
          </button>
        </div>
        </form>
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}