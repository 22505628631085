import React from 'react';
import { useHistory } from 'react-router-dom';
import { SubCreateReview } from '../subcomponents/subcreateReview';
import { SubHeader2 } from '../subcomponents/subheader2';

export const SubSubmiteReview = () => {
  const history = useHistory();

  return (
    <>
      <SubHeader2 page={'createReview'} />
      <SubCreateReview />
    </>
  )
}
