import { Header2 } from "../components/header2"


export const Agreement = () => {
  return (
    <>
      <Header2 page={''} />
      <div className="w-full grid justify-items-center">

        <p className="text-2xl mt-20">개인정보취급방침</p><br />
        가. 수집하는 개인정보 항목 및 수집방법<br />
        나. 개인정보의 수집 및 이용목적<br />
        다. 수집한 개인정보의 보유 및 이용기간<br />

        가. 수집하는 개인정보 항목 및 수집방법 마스트벤처스는 고객님의 <br />
        온라인상담(온라인상담, 수강료조회)을 위해 개인정보를 아래와 같이 수집하고 있습니다.<br />

        - 이름, 핸드폰, 직업, 나이, 이메일 등<br />

        기록 마스트벤처스는 다음과 같은 방법으로 개인정보를 수집합니다.<br />
        - 홈페이지 내 수강가이드(온라인상담, 수강료조회)<br />

        나. 개인정보의 수집 및 이용목적 마스트벤처스는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br />
        - 과정문의에 대한 전화 및 이메일상담<br />
        - 신규 서비스(강좌) 개발 및 특화, 이벤트 등 광고성 정보 전달<br />

        다. 수집한 개인정보의 보유 및 이용기간<br />
        원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당정보를 지체 없이 파기합니다.<br /><br />

        * 전화번호 : 070-5100-5577<br />
        * 팩스번호 : 070-5100-5578<br />
        * 주 소 : 서울특별시 구로구 구로3동 이앤씨벤처드림타워3차 305호<br />
        * 개인정보보호책임자 : 제준호<br />
      </div>
    </>
  )
}