import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { url } from 'inspector';
import { authTokenVar, isLoggedInVar } from '../apollo';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router';
import Logo from '../images/mast_korean_logo.png';
import { Link } from 'react-router-dom';

const navigation = [
  { name: 'We are MAST ventures', href: '/introduction' },
  { name: '항해소개', href: '/curriculum' },
  { name: '마스트 수업', href: '/program' },
  { name: '마스트콘텐츠', href: '/review' },
  { name: '사업성장 관리(테스트)', href: '/step' },
]

interface MainHeaderProps {
  ismain: boolean;
  page: string;
}


export const MainHeader:React.FC<MainHeaderProps> = ({ismain, page}) =>{
  const history = useHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [ navBar, setNavBar ] = useState(false);
  
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('directpage');
    alert('항해를 완료하였습니다.')
    isLoggedInVar(false)
  }

  const step = () => {
    history.push('/step')
  }
  
  const changeNavBar = () => {
    if(window.scrollY >= 30) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  } 

  const moveToStep = () => {
    if(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin' ) {
      history.push('/step')
    } else {
      alert('수강생에 한해 제공됩니다.');
      history.push('/program');
    }
  }

  window.addEventListener("scroll", changeNavBar);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <div className="bg-main block">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">아이디어 건조(make) 과정 1기 모집합니다.</span>
              <span className="hidden md:inline">아이디어 건조(make) 과정 1기 모집합니다.</span>
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
            >
            </button>
          </div>
        </div>
      </div>
      <div className={classNames(
        navBar ? 'bg-white w-screen sticky py-2 sm:py-0 sm:pt-6' : 'bg-transparent absolute w-screen pt-16',
        ' top-0 z-30' )}>
        <div className="sm:pb-6 overflow-hidden">
          <Popover>
            <nav
              className="max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="/">
                    <img
                      className="h-6 w-auto sm:h-7"
                      src={Logo}
                      alt="logo"
                    />
                  </a>
                  <div className="flex items-center md:hidden">
                    <Popover.Button className="bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className={classNames(navBar ? 'text-white': 'text-black', 'z-40 hidden space-x-10 md:flex md:ml-10')}>
                  {navigation.map((item) => (
                    <>
                    { window.location.pathname ==='/' ?
                        <a key={item.name} href={item.href} className="font-bold hover:text-gray-500 text-main text-lg">
                          {item.name}
                        </a> :
                      window.location.pathname ==='/program/idea' ?
                        <a key={item.name} href={item.href} className="font-bold hover:text-gray-200 text-main text-lg">
                          {item.name}
                        </a> :
                      item.href === page ?
                        <a key={item.name} href={item.href} className="hover:text-main font-bold text-lg text-main">
                          {item.name}
                        </a> : 
                        <a key={item.name} href={item.href} className="font-bold hover:text-blue-800 text-lg text-main">
                          {item.name}
                        </a>
                      }
                    </>
                    
                  ))}
                </div>
              </div>
              <div className="hidden md:flex">
                <>
                  {isLoggedIn ?
                  <>
                    <a 
                      onClick={moveToStep}
                      className="inline-flex items-center px-4 py-2 text-lg font-bold rounded-md text-main cursor-pointer hover:text-gray-200"
                    >
                      항해일지
                    </a> 
                    <a
                      onClick={logout}
                      className="inline-flex items-center px-4 py-2 text-lg font-bold rounded-md text-main cursor-pointer hover:text-gray-200"
                    >
                      항해완료
                    </a> 
                  </>
                  :
                  <a
                    href="/login"
                    className="inline-flex items-center px-4 py-2 text-lg font-bold rounded-md text-main cursor-pointer hover:text-gray-200"
                  >
                    로그인
                  </a>
                  }
                </>
              </div>
            </nav>
            
            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"

            >
              <Popover.Panel
                focus
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden overflow-auto z-30"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src={Logo}
                        alt=""
                      />
                    </div>
                    <div className="">
                      <Popover.Button className="bg-white rounded-md p-2 z-40 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">메뉴 닫기</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-20 space-y-1">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  {isLoggedIn ?
                  <a
                    onClick={logout}
                    className="block w-full px-5 py-3 text-center font-medium text-main bg-gray-50 hover:bg-gray-100"
                  >
                    항해완료
                  </a> :
                  <a
                    href="/login"
                    className="block w-full px-5 py-3 text-center font-medium text-main bg-gray-50 hover:bg-gray-100"
                  >
                    로그인
                  </a>
                  }
                  
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>
    </>
  )
}