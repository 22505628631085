import { useMutation } from '@apollo/client';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import gql from 'graphql-tag';
import { createRef, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createBlogMutation, createBlogMutationVariables } from '../__generated__/createBlogMutation';


export const CREATE_BLOG_MUTATION = gql`
  mutation createBlogMutation($title: String!, $content: String!, $thumbnail: String!) {
    createBlog(input: {
      title: $title,
      thumbnail: $thumbnail,
      content: $content,
    }) {
      ok,
      error
    } 
  }
`;

interface IForm {
  title: string;
  content: string;
  thumbnail: FileList;

}

export const MakeBlog = () => {
  const editorRef = createRef<Editor>();
  const [ htmls ,setHtml] = useState('');
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  const onPreview = async () => {

    const editorInstance = editorRef.current?.getInstance();
    console.log("1" , editorInstance);
    const getContent_md = editorInstance?.getHTML();
    console.log(getContent_md);
    if(getContent_md) {

      setHtml(getContent_md);
    }

    
    //mutation (upload) ==> thumbnail: url
    }

  const onSubmit = async () => {
    const { title, thumbnail } = getValues();

    const actualFile = thumbnail[0];
    const formBody = new FormData();
    formBody.append("file", actualFile);
    const { url } = await (await fetch( process.env.NODE_ENV ==='production' ? 'https://reallive-check-backend.herokuapp.com/uploads' :'http://localhost:4000/uploads', {
      method: "POST",
      body: formBody
    })).json();
    console.log("url ", url)
    console.log(title)
    const editorInstance = editorRef.current?.getInstance();
    console.log("1" , editorInstance);
    const getContent_md = editorInstance?.getHTML();
    console.log(getContent_md);
    if(getContent_md) {

      setHtml(getContent_md);
      createBlogMutation({
        variables: {
          title,
          content:getContent_md,
          thumbnail: url
        }
      });
    }

    
    //mutation (upload) ==> thumbnail: url
    }
    
    const inValid = () => {
      console.log(errors)
    }
    const onCompleted = (data: createBlogMutation) => {
      const {
        createBlog: { ok, error },
      } = data;
      if (ok) {
        alert('웹툰정보가 등록되었습니다.')
        window.location.reload();
      } else {
        alert(error)
      }
    };
    const [createBlogMutation, { loading, data: createBlogMutationResult }] 
    = useMutation<createBlogMutation, createBlogMutationVariables>(
      CREATE_BLOG_MUTATION, 
      { onCompleted});

  return (
    <div>
      <form className="mt-5" onSubmit={handleSubmit(onSubmit, inValid)}>
        <div className=" mx-auto">
        <div className="mt-5">블로그 제목</div>
          <input 
            {...register("title", {
              required: "제목을 입력해주세요. ",
              // validate: (author) => author.includes("gmail.com")
            })}
            type="title" 
            name="title" 
            required 
            className="w-full mx-auto border-2 border-gray-300 mt-7 py-2 px-2 rounded-md text-base text-gray-900"
            placeholder="제목을 입력해주세요." 
          />
        </div>
        <div className="mx-auto">
          <div className="mt-5">썸네일 업로드</div>
          <input 
            {...register("thumbnail", {
              required: "플랫폼 입력해주세요",
              // validate: (platform) => platform.includes("gmail.com")
            })}
            type="file" 
            name="thumbnail" 
            accept="image/*"
            required 
            className="w-full mx-auto border-2 border-gray-300  py-2 px-2 rounded-md text-base text-gray-900"
            placeholder="썸네일 업로드" 
          />
        </div>
        {/* <div className="mx-auto mb-20">
          <button className="w-full mx-auto bg-red-500 px-5 py-3 mt-5 text-white rounded-full " >업로드하기</button>
        </div> */}
      </form>
      
        <Editor
            previewStyle="vertical"
            height="650px"
            initialEditType="wysiwyg"
            initialValue="hello"
            ref={editorRef}
          />
          <button className="grid justify-center border bg-green-400 px-4 py-2 rounded-md text-white" onClick={onPreview}>블로그 미리보기</button>
          <button className="grid justify-center border bg-green-400 px-4 py-2 rounded-md text-white" onClick={onSubmit}>블로그 제출하기</button>
      {htmls && <div className="grid max-w-6xl justify-center mx-auto text-center" dangerouslySetInnerHTML={{__html: htmls}} /> }
    </div>
  )
}