import { useHistory } from "react-router"

export const SubMastPaymentFooter = () => {
  const history = useHistory();
  const apply = () => {
    if(localStorage.getItem('token')) {
      history.push('/program/idea/apply')
    } else {
      localStorage.setItem('directpage', '/program/idea/apply')
      history.push('/login')
    }
  }
  return (
    <>
      <div className="hidden lg:block w-7/12 fixed bottom-5 rounded-lg border-2 border-main border-opacity-50 bg-gray-100 shadow-xl" style={{left:"50%", transform: "translate(-50%,0)"}}>
        <div className="grid grid-cols-12 py-4 px-4 ">
          <div className="col-span-10 flex items-center font-bold  text-base sm:text-xl text-main GmarketM">
            아이디어 만들기 과정 <div className="text-gray-800 font-normal text-sm sm:text-sm ml-2 "> ( 11월 24일 신청마감 )</div>
            <div className="ml-7 text-xl font-bold text-red-500 "><span className="text-gray-500 text-sm line-through mr-2">550,000원</span>110,000 원 (-80%)</div>
          </div>
          {/* <div className="col-span-2 flex text-xl"><span className="text-red-500 mr-1 text-base line-through">330,000원</span>110,000원</div> */}
          <div className="px-3 mr-3 py-3 rounded-lg mx-auto bg-main text-white col-span-2 cursor-pointer" onClick={apply}>
            수강 신청하기
          </div>
        </div>
      </div>
      <div className="lg:hidden w-11/12 fixed bottom-5 rounded-lg border bg-gray-100 shadow-xl" style={{left:"50%", transform: "translate(-50%,0)"}}>
        <div className=" py-4 px-4 ">
          <div className="col-span-10 flex items-center font-bold  text-base sm:text-xl text-main GmarketM">
            아이디어 만들기 과정 
            <div className="text-gray-800 font-normal text-sm sm:text-sm ml-2"> ( 11월 24일 신청마감 )</div>
          </div>
          <div className=" text-lg font-bold text-red-500 "><span className="text-gray-500 text-sm line-through mr-2">550,000원</span>110,000 원 (-80%)</div>
          <div className="px-3 mr-3 py-3 rounded-lg mx-auto bg-main text-white text-center col-span-2 cursor-pointer" onClick={apply}>
            수강 신청하기
          </div>
        </div>
      </div>
    </>
  )
}