import { SubFooter } from "../subcomponents/subfooter"
import { SubHeader2 } from "../subcomponents/subheader2"


export const SubPrivacy = () => {
  return (
    <>
      <SubHeader2 page={'privacy'} />
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl mt-20">

        <p className="text-2xl mb-5"> 개인정보 처리방침</p>


        <p>주식회사 마스트벤처스이하 “회사”)는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』(이하 “정보통신망법”), 『개인정보 보호법』 등 관련 법령상의 개인정보 보호규정을 준수하며, 정보통신망법에 의거한 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다. 본 개인정보처리방침은 회사가 제공하는 웹/모바일웹/앱 서비스에 적용되며 다음과 같은 내용을 포함하고 있습니다.</p>

        <p>이용자의 권리 및 행사방법</p>
        <p>이용자의 의무</p>
        <p>자동 수집되는 개인정보 및 거부에 관한 사항</p>
        <p>개인정보 수집항목 및 이용 목적</p>
        <p>개인정보 제3자 제공</p>
        <p>개인정보 처리위탁</p>
        <p>개인정보 보유 및 이용기간</p>
        <p>개인정보 파기절차 및 방법</p>
        <p>개인정보의 보호조치에 관한 사항</p>
        <p>개인정보 보호책임자 연락처 및 이용자 고충 처리</p>
        <p>개인정보처리방침 고지의무</p>



        <p>1. 이용자의 권리 및 행사방법</p>

        <p>① 이용자는 언제든지 정보주체로서의 권리(등록되어 있는 본인의 개인정보를 열람, 정정, 동의 철회, 삭제)를 행사할 수 있으며, 아래 사항에 대한 열람, 제공, 정정, 동의 철회, 삭제(탈퇴), 처리 정지, 이의제기 등을 요구할 수 있습니다.</p>
        <p>    1) 회사가 보유하고 있는 이용자의 개인정보</p>
        <p>    2) 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 현황</p>
        <p>    3) 이용자가 개인정보 수집·이용·제공 등의 동의를 한 현황</p>
        <p>      <p></p>- 조회 및 수정 : 로그인 후 마이페이지 {'>'} 프로필 수정</p>
        <p>      <p></p>- 삭제 및 회원탈퇴 : 로그인 후 마이페이지 {'>'} 회원탈퇴</p>
        <p>      <p></p>- 동의 철회(이벤트 정보수신) : 로그인 후 마이페이지 {'>'} 설정 변경</p>
        <p>    4) 회사는 관련된 상담 및 문의처리를 위해 별도의 고객센터를 운영하고 있으며, 개인정보 보호책임자에게 이메일 또는 전화로 연락하시면 지체없이 조치하겠습니다.</p>

        <p>② 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우, 완료하기 전까지 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</p>


        <p>2. 이용자의 의무</p>

        <p>이용자는 자신의 개인정보를 보호할 의무가 있으며, 회사의 고의 또는 과실 등 귀책사유 없이 ID/비밀번호 공유, 로그인 상태에서 자리를 비우는 등 이용자의 부주의로 인하여 발생하는 문제에 대해서는 회사가 책임지지 않습니다.</p>

        <p>① 이용자는 개인정보를 최신의 상태로 유지해야 하며, 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.</p>

        <p>② 이용자의 ID/비밀번호는 원칙적으로 본인만 사용하여야 하며 제3자에게 이를 양도하거나 대여할 수 없습니다. 타인의 개인정보를 도용한 회원가입 또는 ID 등을 도용하여 구매한 경우, 회원자격 상실 및 관계 법령에 따라 처벌될 수 있습니다.</p>

        <p>③ 이용자의 회사의 서비스를 이용한 후 반드시 계정을 로그아웃하고 웹 브라우저 프로그램을 종료해야 합니다.</p>


        <p>3. 자동 수집되는 개인정보 및 거부에 관한 사항</p>

        <p>회사는 이용자 맞춤서비스 등을 제공하기 위하여 쿠키(Cookie)를 설치 및 운영 합니다. 쿠키의 사용 목적과 거부에 관한 사항은 다음과 같습니다.</p>

        <p>① 쿠키란 : 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로 이용자의 컴퓨터에 저장되어 운영됩니다.</p>

        <p>② 쿠키의 사용 목적 : 이용자의 접속 빈도나 방문 시간 등의 분석, 이용 형태 및 관심분야 파악, 자취 추적, 각종 이벤트 참여 정도, 방문 횟수 파악 등을 타겟 마케팅 등 개인별 맞춤 서비스를 제공하기 위해 쿠키를 사용 합니다.</p>

        <p>③ 쿠키의 설치·운영 및 거부</p>
        <p>    1) 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저별 옵션 선택을 통해 모든 쿠키를 허용 또는 거부 하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다. 쿠키 설치 허용여부를 지정하는 방법은 다음과 같습니다.</p>
        <p>      <p></p>- Internet Explorer : 도구 메뉴 {'>'} 인터넷 옵션 {'>'} 개인정보 탭 {'>'} 개인정보처리 수준 설정</p>
        <p>      <p></p>- Chrome : 설정 메뉴 {'>'} 고급 설정 표시 선택 {'>'} 개인정보-콘텐츠 설정 {'>'} 쿠키 수준 설정</p>
        <p>      <p></p>- Firefox : 옵션 메뉴 {'>'} 개인정보 {'>'} 방문기록 -사용자 정의 설정 {'>'} 쿠키 수준 설정</p>
        <p>      <p></p>- Safari : 환경설정 메뉴 {'>'} 개인정보 탭 {'>'} 쿠키 및 웹 사이트 데이터 수준 설정</p>
        <p>    2) 단, 쿠키의 저장을 거부하였을 경우에는 로그인이 필요한 서비스 등 일부 서비스 이용에 어려움이 있을 수 있습니다.</p>


        <p>4. 개인정보 수집항목 및 이용 목적</p>

        <p>① 회사는 서비스 제공을 위한 최소한의 범위 내에서 이용자의 동의 하에 개인정보를 수집하며, 이용자가 필요한 최소한의 개인정보 이외의 개인정보를 제공하지 아니한다는 이유로 해당 서비스 제공을 거부하지 않습니다. 또한 수집한 모든 개인정보는 고지한 목적 범위 내에서만 사용되며 회사에서 제공하는 서비스 유형에 따라 다음과 같이 개인정보를 수집 및 이용하고 있습니다.</p>


        <p>회원가입 및 서비스 이용</p>
        <p>수집방법</p>

        <p>수집항목</p>

        <p>수집 및 이용목적</p>

        <p>회원가입</p>

        <p>[필수] 이름, 이메일주소, 휴대폰번호, 비밀번호</p>

        <p>본인확인 및 가입의사 확인, 상담 및 문의 처리, 공지사항 전달 및 불만처리 등 원활한 의사소통 경로 확보</p>

        <p>SNS계정 가입</p>

        <p>[필수 - 카카오] 카카오계정 이메일, 이름</p>

        <p>[필수 - 네이버] 네이버계정 이메일, 이름 프로필사진</p>

        <p>[필수 - 페이스북] 이름, 프로필사진</p>

        <p>[필수 - 구글] 구글계정 이메일, 이름, 휴대폰번호, 프로필사진</p>

        <p>[필수 - 애플] 애플계정 이메일</p>

        <p>SNS계정을 이용한 회원가입</p>

        <p>회원정보 수정</p>

        <p>[선택] 닉네임, 프로필 사진</p>

        <p>이용자 식별 및 이용자간 관계 형성, 커뮤니티 활성화</p>

        <p>구매 시</p>

        <p>[필수] 수취인정보(이름, 휴대폰번호, 주소), 현금영수증 발행 정보(휴대폰번호)</p>

        <p>주문/결제 및 상품 배송(교환, 반품) 서비스, 현금영수증 발급</p>

        <p>크리에이터 지원</p>

        <p>(필수) 클래스메이트 회원가입 정보 + 이메일주소, 휴대폰번호</p>

        <p>본인확인 및 가입의사 확인, 상담 및 문의 처리, 공지사항 전달 및 불만처리 등 원활한 의사소통 경로 확보</p>

        <p>(선택) 닉네임, 프로필 사진, SNS 계정 및 URL</p>

        <p>이용자 식별 및 이용자간 관계 형성, 커뮤니티 활성화</p>



        <p>서비스 이용에 따른 자동 수집 및 생성 정보</p>
        <p>수집항목</p>

        <p>수집 및 이용목적</p>

        <p>- 쿠키, IP, 방문일시, 서비스 이용기록</p>

        <p>- 기기정보(device_id, OS종류 및 버전, 디바이스 종류, 광고식별자</p>

        <p>- 앱 이용시 추가 모바일 기기정보(UUID, 푸쉬토큰)</p>

        <p>서비스 이용 통계, 접속관리, 이용자별 사용환경 제공, 활동정보 파악, 이벤트 및 프로모션 통계 확인, 맞춤형 정보 제공</p>




        <p>② 회사는 모바일앱 서비스를 위하여 이용자의 스마트폰 내 정보 및 기능 중 아래 사항에 대해 접근 합니다. 필수 접근항목은 앱 설치 또는 최초 실행 시 안내 및 동의를 받으며, 선택 접근항목은 해당 서비스 이용 시 별도 동의를 받습니다(선택항목 거부로 인한 기본 서비스 이용 제한은 없음)</p>
        <p>구분</p>

        <p>접근항목</p>

        <p>접근목적</p>

        <p>필수 접근권한</p>

        <p>[IOS] UUID</p>

        <p>사용자 식별</p>

        <p>선택 접근권한</p>

        <p>[IOS] 카메라, 사진, 마이크</p>

        <p>[Android] 저장공간(사진/미디어/파일), 카메라</p>

        <p>댓글 작성 시 파일(사진, 동영상) 등록, 클래스 콘텐츠 저장</p>




        <p>③ 회사는 다음과 같은 방법으로 개인정보를 수집할 수 있으며 개인식별이 가능한 개인정보를 수집하는 경우에는 이용자로부터 동의를 받습니다. 이용자가 동의버튼을 클릭하거나 회원정보 수정 등을 통해 추가로 수집하는 개인정보를 기재한 후 저장할 경우 개인정보 수집에 동의한 것으로 봅니다.</p>
        <p>    1) 홈페이지, 모바일앱, 서면, 팩스, 전화, 고객센터 문의하기, 이벤트 응모</p>
        <p>    2) 생성정보 수집 툴을 통한 자동 수집</p>


        <p>5. 개인정보 제3자 제공</p>

        <p>① 회사는 『4. 개인정보 수집 및 이용목적』에서 고지한 범위 내에서만 개인정보를 이용하며, 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
        <p>    1) 이용자가 거래 이행을 위해 제3자 제공에 동의하는 경우, 서비스 제공 및 배송, 본인확인 등을 위하여 필요한 최소한의 개인정보만을 서비스 제공 업체에게 제공할 수 있습니다.</p>
        <p>제공받는 자</p>

        <p>제공목적</p>

        <p>제공항목</p>

        <p>보유 및 이용기간</p>

        <p>키트 및 스토어 상품 배송업체</p>

        <p>주문상품의 배송, 고객상담 및 불만처리</p>

        <p>수취인 정보(이름, 휴대폰번호, 주소)</p>

        <p>구매확정 후 1개월</p>


        <p>    2) 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</p>
        <p>    3) 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주, 협력사, 연구단체 등에 제공하는 경우</p>

        <p>② 이용자는 개인정보 제3자 제공에 대해 동의하지 않을 수 있고 언제든지 제3자 제공 동의를 철회할 수 있습니다. 다만, 제3자 제공에 기반한 관련된 일부 서비스의 이용이 제한될 수 있습니다(회원가입 서비스는 이용하실 수 있습니다)</p>


        <p>6. 개인정보 처리위탁</p>

        <p>회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며 관계법령에 따라 위탁 처리되는 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 취하고 있습니다. 또한 위탁 처리하는 개인정보는 서비스 제공에 필요한 최소한의 범위에 국한됩니다.</p>
        <p>국내 처리위탁</p>
        <p>수탁업체</p>

        <p>위탁업무 내용</p>

        <p>토스페이먼츠(주), NICE 페이먼츠(주), (주)비바리퍼블리카, 네이버파이낸셜(주)</p>

        <p>주문상품의 결제 및 환불</p>

        <p>10X10, 써머스플랫폼</p>

        <p>주문상품의 보관 및 배송, 배송추적</p>

        <p>(주)트랜스코스모스코리아</p>

        <p>고객상담</p>

        <p>(주)채널코퍼레이션</p>

        <p>채널톡 시스템 운영</p>

        <p>네이버 비즈니스 플랫폼(주), (주)다우기술, 11번가(주)</p>

        <p>SMS발송, 카카오 알림톡 발송, 모바일 쿠폰발송</p>

        <p>Amazon Web Services Inc.</p>

        <p>서비스 제공을 위한 인프라 관리</p>



        <p>국외 처리위탁</p>
        <p>이전 받는 자</p>

        <p>이전되는 국가</p>

        <p>이전 일시 및 방법</p>

        <p>이전되는 개인정보 항목</p>

        <p>이용목적 및 처리기간</p>

        <p>Braze.Inc</p>

        <p>미국</p>

        <p>업무상 개인정보 필요 시 네트워크를 통하여 전송</p>

        <p>이름, 이메일, 휴대폰번호</p>

        <p>앱푸시, 이메일 발송 및 마케팅 업무 /</p>

        <p>업무완료 시 지체없이 파기</p>




        <p>7. 개인정보 보유 및 이용기간</p>

        <p>① 회사는 이용자의 개인정보를 고지 및 동의받은 사항에 따라 수집·이용 목적이 달성되기 전 또는 이용자의 탈퇴 요청이 있기 전까지 해당 정보를 보유합니다. 다만, 아래의 사유로 인하여 보관이 필요한 경우 외부와 차단된 별도의 DB 또는 테이블에 분리 보관 됩니다.</p>
        <p>    1) 온라인/모바일 서비스 제공을 위해 수집한 회원가입 정보 : 회원탈퇴 시 까지</p>
        <p>    2) 법령에 의한 개인정보 보유</p>
        <p>관련 법률</p>

        <p>보유항목</p>

        <p>보유기간</p>

        <p>통신비밀보호법</p>

        <p>인터넷 로그기록자료, 접속지 추적자료</p>

        <p>3개월</p>

        <p>전자상거래 등에서의 소비자보호에 관한 법률</p>

        <p>표시·광고에 관한 기록</p>

        <p>6개월</p>

        <p>계약 또는 청약철회에 관한 기록</p>

        <p>5년</p>

        <p>대금결제 및 재화등의 공급에 관한 기록</p>

        <p>5년</p>

        <p>소비자의 불만 또는 분쟁처리에 관한 기록</p>

        <p>3년</p>

        <p>부가가치세법</p>

        <p>부가가치세의 과세표준과 세액의 신고자료 등</p>

        <p>5년</p>



        <p>② 개인정보 유효기간제(휴면계정 정책)</p>
        <p>    1) 1년 이상 서비스 이용기록이 없는 이용자의 회원가입정보는 정보통신망법 제29조에 따라 일반 이용자의 회원가입정보와 분리하여 별도로 저장 및 관리 됩니다.</p>
        <p>    2) 회사는 휴면계정으로 전환되는 시점 최소 30일 이전, 해당 내용에 대해 이메일 등을 통해 이용자에게 사전 통지합니다.</p>
        <p>    3) 분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용·제공하지 않습니다.</p>
        <p>    4) 주문 및 결제, CS정보는 관련 법령에 의한 개인정보 보유기간 동안 보관 후 삭제됩니다.</p>


        <p>8. 개인정보 파기절차 및 방법</p>

        <p>① 파기절차 및 기한 : 이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체없이 파기합니다. 다만, 『7. 개인정보 보유 및 이용기간』에서 명시한 다른 법령에 의해 보관해야 하는 경우 별도의 DB에 옮겨져 내부 규정 및 관련 법령을 준수하여 일정기간 동안 안전하게 보관된 후 지체없이 파기됩니다. 이때 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 이용하지 않습니다.</p>

        <p>② 파기방법</p>
        <p>    1) 전자적 파일 형태의 정보는 복구 및 재생할 수 없는 기술적 방법을 사용하여 완전하게 삭제합니다.</p>
        <p>    2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>


        <p>9. 개인정보의 보호조치에 관한 사항</p>

        <p>회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 『정보통신망법』, 『개인정보보호법』 등 정보통신서비스 제공자가 준수하여야 할 관련 법령에 따라 기술적·관리적 보호대책을 강구하고 있습니다.</p>


        <p>10. 개인정보 보호책임자 및 이용자 고충처리</p>

        <p>① 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 민원, 불만처리 등에 관한 사항을 개인정보 보호책임자 및 고객센터로 문의하실 수 있고, 회사는 이용자의 문의에 신속하고 성실하게 답변하겠습니다.</p>
        <p>개인정보 보호책임자</p>

        <p>고객센터</p>

        <p>이름 : 김기현 대표</p>

        <p>연락처 : mastventureskorea@gmail.com</p>

        <p>연락처 : mastventureskorea@gmail.com</p>




        <p>② 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
        <p>    1) 개인정보침해신고센터 : http://privacy.kisa.or.kr / 국번없이 118</p>
        <p>    2) 대검찰청 사이버범죄수사단 : http://www.spo.go.kr / 국번없이 1301</p>
        <p>    3) 경찰청 사이버안전국 : http://cyberbureau.police.go.kr / 국번없이 182</p>



        <p>11. 개인정보 처리방침 고지 의무</p>

        <p>이 개인정보 처리방침은 시행일로부터 적용되며, 관련 법령 및 회사 정책변경 등에 따라 변경 내용의 추가, 삭제 및 정정사항이 있는 경우에는 홈페이지 또는 이메일을 통해 사전 공지하겠습니다.</p>

        <p>      <p></p>- 시행일자 : 2021년 8월 2일</p>
        <p>      <p></p>- 개인정보 처리방침 버전 번호 : ver 1.2</p>
        <p>      <p></p>- 이전 개인정보 처리방침 보기 : ver 1.1</p>
      </div>
      <SubFooter />
    </>
  )
}