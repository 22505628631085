import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LevelStatus } from "./Levelstatus"
import Boat from '../images/boat.gif';
import { GET_PSM_QUERY, COMMENT_PSM_MUTATION } from "./logbook2";
import { getBM } from "../__generated__/getBM";
import { commentPCMutation, commentPCMutationVariables } from "../__generated__/commentPCMutation";
import { getPC } from "../__generated__/getPC";


interface IForm {
  understand_4: string;
  question_4: string;
  question4_1: string;
  question4_2: string;
  question4_3: string;
  question4_4: string;
}

export const COMMENT_PC_MUTATION = gql`
  mutation commentPCMutation($commentPCInput: commentPCInput!) {
    commentPC(input: $commentPCInput) {
      ok,
      error
    }
  }
`;

export const GET_PC_QUERY = gql`
  query getPC {
    getPC {
      ok
      error
      bms {
        understand_4,
        question_4,
        question4_1,
        question4_2,
        question4_3,
        question4_4,
      }
    }
  }
`;

export const LogBook5 = () => {
  const [step, setStep] = useState(1);
  const { data } = useQuery<getPC>(GET_PC_QUERY);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  const temporarySave = () => {
      alert('임시저장 되었습니다.')
      // commentPSMMutation({
      //   variables: {
      //     commentPSMInput: {
      //       problem,
      //       solution,
      //       BM
      //     }
      //   }
      // });
  }
  const previous = () => {
    setStep(step-1);
  }
  const onCompleted = (data: commentPCMutation) => {
    const {
      commentPC: { ok, error },
    } = data;

    if (ok) {
      alert('PC 작성완료!')
      window.location.reload();
    } else {
      alert(error)
    }
  };
  const [ commentPCMutation, { data: commentPCMutationResult, loading } ] = useMutation<commentPCMutation, commentPCMutationVariables>(COMMENT_PC_MUTATION, { onCompleted});
  const onSubmit = () => {
    const { understand_4, question_4, question4_1, question4_2, question4_3, question4_4 } = getValues();
    if( step === 6 ) {
      if((understand_4 && question_4 && question4_1 && question4_2 && question4_3 && question4_4  )) {
        alert('제출되었습니다.')
        commentPCMutation({
          variables: {
            commentPCInput: {
              understand_4,
              question_4,
              question4_1,
              question4_2,
              question4_3,
              question4_4
              
            }
          }
        });
        
      } else {
        alert('모든 질문에 답변해주세요.')
      }
    } else {
      setStep(step +1);
    }
  }
  const inValid = () => {
    console.log(errors)
  }


  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap " >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        {/* <div className="text-4xl GmarketM transition-opacity text-center mb-12" style={{width:" 500px"}}>3일차 항해일지</div> */}
        {/* <div className="text-lg GmarketM transition-opacity text-center mb-20 text-red-400" style={{width:" 500px"}}>수강생에 한해서 강사님의 구체적인 피드백을 받을 수 있습니다.</div> */}
        <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
        {step ===1 && 
          <div className=" mt-10">
            <label htmlFor="understand_4" className=" text-lg font-medium text-gray-700 ">
              1.  PC 방식이 이해 되셨나요? (1-10)
            </label>
            <div className="mt-1">
              <textarea
              {...register("understand_4", {
                required: "이름을 입력해주세요",
              })}
                id="understand_4"
                name="understand_4"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPC?.bms ? data.getPC?.bms[0]?.understand_4 : ''}
              />
            </div>
          </div>
        }
        {step ===2 && 
          <div className="mt-20">
            <label htmlFor="question_4" className=" text-lg font-medium text-gray-700">
              2. 이해가 되지 않는다면 어떤 점이 이해되지 않았나요? (간단히 기록해주세요)
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question_4", {
                  required: "이름을 입력해주세요",
                })}
                id="question_4"
                name="question_4"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPC?.bms ? data.getPC?.bms[0]?.question_4 : ''}
              />
            </div>
          </div>
        }
        {step ===3 && 
          <div className="mt-20">
            <label htmlFor="question4_1" className=" text-lg font-medium text-gray-700">
              3. 내가 PC 기업(모기업과 분사된 모습)을 적어봅시다.
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 카카오뱅크 & 카카오페이 </p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question4_1", {
                  required: "이름을 입력해주세요",
                })}
                id="question4_1"
                name="question4_1"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPC?.bms ? data.getPC?.bms[0]?.question4_1 : ''}
              />
            </div>
          </div>
        }
        {step === 4 &&  
          <div className="mt-20">
            <label htmlFor="question4_2" className=" text-lg font-medium text-gray-700">
              4. 위 기업은 어떻게 분사했나요? 
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 뱅크에서는 은행업무만 페이에서 결제만 할 수 있도록 만들었다.</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question4_2", {
                  required: "이름을 입력해주세요",
                })}
                id="question4_2"
                name="question4_2"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPC?.bms ? data.getPC?.bms[0]?.question4_2 : ''}
              />
            </div>
          </div>
        }
        {step === 5 && 
          <div className="mt-20">
            <label htmlFor="question4_3" className=" text-lg font-medium text-gray-700">
              5. 분사하면 좋은 점은 무엇일까요?
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 앱이 가벼워졌고, 다른 사업으로 확장이 편하게 되었다 </p>
            <p className="mt-2 text-sm text-gray-500">- (뱅크는 투자와 대출, 페이는 신용관리와 카드) </p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question4_3", {
                  required: "이름을 입력해주세요",
                })}
                id="question4_3"
                name="question4_3"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPC?.bms ? data.getPC?.bms[0]?.question4_3 : ''}
              />
            </div>
          </div>
        }
        {step === 6 && 
          <div className="mt-20">
            <label htmlFor="question4_4" className=" text-lg font-medium text-gray-700">
              6. 내가 있는 현재 모습에서 분사 할 수 있는 것은 무엇이 있을까요?
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 광고대행사 - 콘텐츠 대행 & 퍼포먼스 대행 / 글쓰기 & 이미지제작</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question4_4", {
                  required: "이름을 입력해주세요",
                })}
                id="question4_4"
                name="question4_4"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPC?.bms ? data.getPC?.bms[0]?.question4_4 : ''}
              />
            </div>
          </div>
        }
        <div className="flex justify-center mt-12">
          {/* <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            임시 저장
          </button> */}
          {step !== 1 &&
            <button
              type="button"
              onClick={previous}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
            >
              이전
            </button>
          }
          <button
            type="button"
            onClick={temporarySave}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {'임시저장'}
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {step === 6 ? '제출' : '다음'}
          </button>
        </div>
        </form>
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}