import React from "react";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Reference } from '../pages/reference';
import { ProgramDetail } from "../pages/programDetail";
import { Login2 } from "../pages/login2";
import { Main2 } from "../pages/main2";
import { Step } from "../pages/step";
import { Admin } from "../pages/admin";
import { Introduction } from "../pages/introduction";
import { Review } from "../pages/review";
import { Curriculum } from "../pages/curriculum";
import { Program } from "../pages/program";
import { SignUp2 } from "../pages/signup2";
import { Agreement } from "../pages/agreement";
import { Terms } from "../pages/terms";
import { Privacy } from "../pages/privacy";
import { Refund } from "../pages/refund";
import { SubmiteReview } from "../pages/SubmiteReview";
import { Payment } from "../pages/payment";
import { Step2 } from "../pages/step2";
import { Step3 } from "../pages/step3";
import { SailingStart } from "../pages/sailingStart";
import { SubMain } from "../subpages/subMain";
import { SubIntroduction } from "../subpages/subintroduction";
import { SubCurriculum } from "../subpages/subcurriculum";
import { SubProgramDetail } from "../subpages/subprogramDetail";
import { SubAgreement } from "../subpages/subagreement";
import { SubTerms } from "../subpages/subterms";
import { SubPrivacy } from "../subpages/subprivacy";
import { SubRefund } from "../subpages/subrefund";
import { SubSignUp2 } from "../subpages/subsignup2";
import { SubAdmin } from "../subpages/subadmin";
import { SubSubmiteReview } from "../subpages/subSubmiteReview";
import { SubSailingStart } from "../subpages/subsailingStart";
import { SubStep3 } from "../subpages/substep3";
import { SubStep2 } from "../subpages/substep2";
import { SubProgram } from "../subpages/subprogram";
import { SubReview } from "../subpages/subreview";
import { SubLogin2 } from "../subpages/sublogin2";
import { SailingTest } from "../pages/sailingTest";
import { SubSailingTest } from "../subpages/subsailingTest";
import { LandingPage } from "../pages/LandingPage";
import { EduMain } from "../pages/Edu_main";
import { MakeBlog } from "../pages/makeBlog";

export const LoggedOutRouter = () => {

  return (
    <Router>
      <Switch>
        <Route path="/addblog" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <MakeBlog />;
          return <MakeBlog />;
        }} />
        <Route path="/agreement" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubAgreement />;
          return <Agreement />;
        }} />
        {/* <Route path="/agreement">
          <Agreement />
        </Route> */}
        <Route path="/docs/terms" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubTerms />;
          return <Terms />;
        }} />
        {/* <Route path="/docs/terms">
          <Terms />
        </Route> */}
        <Route path="/docs/privacy" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubPrivacy />;
          return <Privacy />;
        }} />
        {/* <Route path="/docs/privacy">
          <Privacy />
        </Route> */}
        <Route path="/docs/refund" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubRefund />;
          return <Refund />;
        }} />
        {/* <Route path="/docs/refund">
          <Refund />
        </Route> */}
        <Route path="/signup" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubSignUp2 />;
          return <SignUp2 />;
        }} />
        {/* <Route path="/signup">
          <SignUp2 />
        </Route> */}
        <Route path="/admin" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubAdmin />;
          return <Admin />;
        }} />
        {/* <Route path="/admin">
          <Admin />
        </Route> */}
        <Route path="/submitReview" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubSubmiteReview />;
          return <SubmiteReview />;
        }} />
        {/* <Route path="/submitReview">
          <SubmiteReview />
        </Route> */}
        <Route path="/step/sailingTest" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubSailingTest />;
          return <SailingTest />;
        }} />
        <Route path="/step/sailingStart" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubSailingStart />;
          return <SailingStart />;
        }} />
        {/* <Route path="/step3/sailingStart">
          <SailingStart />
        </Route> */}
        <Route path="/step" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubStep3 />;
          return <Step3 />;
        }} />
        <Route path="/buildUp" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <LandingPage />;
          return <LandingPage />;
        }} />
        {/* <Route path="/step3">
          <Step3 />
        </Route> */}
        {/* <Route path="/step" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubStep2 />;
          return <Step2 />;
        }} /> */}
        {/* <Route path="/step">
          <Step2 />
        </Route> */}
        {/* <Route path="/step">
          <Step />
        </Route> */}
        <Route path="/introduction" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubIntroduction />;
          return <Introduction />;
        }} />
        {/* <Route path="/introduction">
          <Introduction />
        </Route> */}
        <Route path="/curriculum" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubCurriculum />;
          return <Curriculum />;
        }} />
        {/* <Route path="/curriculum">
          <Curriculum />
        </Route> */}
        <Route path="/program/idea/apply">
          <Payment />
        </Route>
        <Route path="/program/idea" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubProgramDetail />;
          return <ProgramDetail />;
        }} />
        {/* <Route path="/program/idea">
          <ProgramDetail />
        </Route> */}
        <Route path="/program" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubProgram />;
          return <Program />;
        }} />
        {/* <Route path="/program">
          <Program />
        </Route> */}
        <Route path="/review" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubReview />;
          return <Review />;
        }} />
        {/* <Route path="/review">
          <Review />
        </Route> */}
        <Route path="/login" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubLogin2 />;
          return <Login2 />;
        }} />
        {/* <Route path="/login">
          <Login2 />
        </Route> */}

        {/* <Route path="/reference">
          <Reference />
        </Route> */}
        {/* 추천서 */}
        {/* <Route path="/sample">
          <Sample />
        </Route>
        <Route path="/service">
          <Service />
        </Route> */}
        {/* <Route path="/business">
          <Business />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route> */}
        <Route path="/" render={props => {
          const [subdomain] = window.location.hostname.split('.');
          if (subdomain === 'beta') return <SubMain />;
          return <EduMain />;
        }} />
        {/* <Route path="/">
          <Main2 />
        </Route> */}
      </Switch>
    </Router>
  );
};