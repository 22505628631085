import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Idea from '../images/idea.jpg';


interface ProgramCardProps {
  reviewPosts: any
}


export const SubLectureCard:React.FC<ProgramCardProps> = ({reviewPosts}) => {
  const history = useHistory();
  const moveToDetail = () => {
    history.push('/program/idea')
  } 
  return (
    <Link to={'/program/idea'}>
      <div key={reviewPosts.id} style={{backgroundImage: `url(${Idea})`, backgroundSize: '100% 41%', backgroundRepeat:"no-repeat", height:"490px"}} 
        className="z-0 transform hover:scale-105 p-5 border-main bg-white cursor-pointer bg-opacity-50 shadow-lg h-96 border-2 rounded-2xl border-transparent border-opacity-50 hover:border-opacity-100"
      >
        <div className="w-full mt-3 text-white GmarketB text-2xl">1. 아이디어 만들기 과정</div>
        {/* <div className="w-full text-white GmarketB text-2xl">(아이디어 만들기)</div> */}
        <div className="w-10/12 mt-6 text-white GmarketM text-lg max-w-lg font-bold">바로 실행 가능한 창업 아이템을 4주간 배우고, 실행합니다.</div>
        <div className="mt-20 text-black GmarketB text-2xl">"쉽고, 재밌고, 간단합니다."</div>
        <div className="mt-5 text-black GmarketL text-sm">- 이론은 쉽고, 빠르게.</div>
        <div className="text-black GmarketL text-sm">- 실천도 바로바로 수업시간에.</div>
        <div className="text-black GmarketL text-sm">- 고객을 정의하고</div>
        <div className="text-black GmarketL text-sm">- 즉각 실행 가능한 아이템을 만듭니다.</div>
        <div className="text-black GmarketL text-sm">- 단 4주 완성</div>
        <div className="text-black text-center  text-sm"><div className="mt-2 text-xl font-bold text-red-500 "><span className="text-gray-500 text-sm line-through mr-2">550,000원</span>110,000 원 (-80%)</div></div>
        <div className="grid justify-items-center z-30">
          <Link to={'/program/idea/apply'}><button className="mt-4 text-white GmarketM text-sm px-6 py-2 shadow-lg rounded-3xl border justify-center bg-main">수강신청 하러가기</button></Link>
        </div>
      </div>
    </Link>
  )
}