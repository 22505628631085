import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { commentPSMMutation, commentPSMMutationVariables } from '../__generated__/commentPSMMutation';
import { getPSM } from '../__generated__/getPSM';
import StarRatings from 'react-star-ratings';
import SailingMap from '../images/sailingmap.png';

interface LogBookParams {
  log: any[];
}

// export const COMMENT_PSM_MUTATION = gql`
//   mutation commentPSMMutation($commentPSMInput: commentPSMInput!) {
//     commentPSM(input: $commentPSMInput) {
//       ok,
//       error
//     }
//   }
// `;

// export const GET_PSM_QUERY = gql`
//   query getPSM {
//     getPSM {
//       ok
//       error
//       bms {
//         problem
//         solution
//         BM
//       }
//     }
//   }
// `;

interface IForm {
  idea: string;
}

export const SubLogBook2 = () => {
  const history = useHistory();
  const [rating, setRating]= useState(10);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();
  const onSubmit = () => {
    const { idea } = getValues();
  }
  const  changeRating = (newRating: number) => {
    setRating(newRating)
  }
  const inValid = () => {
    console.log(errors)
  }
  return (
    <div className="my-20 p-5" style={{backgroundColor:"#f0eeeb", width:"600px"}}>
      <div className="flex justify-between items-end">
        <div className="text-4xl GmarketM">2일차 항해일지</div>
        <div className="GmarketL">Level 1. 선박 설계 준비</div>
      </div>
      <div className="bg-gray-400 mt-3" style={{height:"0.5px"}}></div>
      <div style={{height:"300px"}} className="my-5" >
        <img src={SailingMap} />
      </div>
      <div className="bg-gray-400 mt-12" style={{height:"0.5px"}}></div>
      <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
        <div className=" mt-10">
          <label htmlFor="idea" className=" text-lg font-medium text-gray-700 ">
            아이디어 작성
          </label>
          <p className="mt-2 text-sm text-gray-500">- 아이디어가 있는 분만 기록해보세요.</p>
          <p className="mt-2 text-sm text-gray-500">- 가지고 있는 창업 아이디어를 적어보세요. 자유양식입니다.</p>
          <p className="mt-2 text-sm text-gray-500">- 마스트벤처스 승조원 ( 기현, 태순, 원엽, 준호등 ) 외엔 아무도 보지 않을 겁니다. </p>
          <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 이제 부터 시작입니다. 걱정하지마세요.</p>
          <div className="mt-1">
            <textarea
            {...register("idea", {
              required: "이름을 입력해주세요",
            })}
              id="idea"
              name="idea"
              rows={3}
              className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
              defaultValue={''}
            />
            {/* <p className="mt-2 text-sm text-gray-500">문제인식에 대해 자세하게 작성해주세요</p>
            <p className="mt-2 text-sm text-gray-500">문제인식에 대해 자세하게 작성해주세요</p>
            <p className="mt-2 text-sm text-gray-500">문제인식에 대해 자세하게 작성해주세요</p> */}
          </div>
        </div>
        <div className="flex justify-center mt-12">
          {/* <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            임시 저장
          </button> */}
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            저장
          </button>
        </div>
      </form>
    </div>
  )
}