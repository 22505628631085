import { useQuery } from "@apollo/client";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import { LevelStatus } from "../components/Levelstatus"
import { GET_PSM_QUERY } from "../components/logbook2";
import { MastPaymentFooter } from "../components/MastPaymentFooter";
import Boat from '../images/boat.gif';
import { getPSM } from "../__generated__/getPSM";

export const Step3 = () => {
  const history = useHistory();
  const { loading, data: levelData } = useQuery<getPSM>(GET_PSM_QUERY);
  console.log(levelData)
  const [level, setLevel] = useState(1);
  useEffect(()=> {
    if(!(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin')) {
      setLevel(3);
    }
  }, []);
  const returnPage = () => {
    history.push('/')
  }
  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <>
      <div className="flex pl-5 pt-4 items-center cursor-pointer" onClick={returnPage}>
        <ChevronLeftIcon className="w-9 h-9 " />
        <span>돌아가기</span>
      </div>
      <div className="flex content-center flex-wrap h-screen" >
        <div className="flex-1"></div> 
        <div className="flex-shrink-0">
          {/* <div className="text-center text-main text-xl">내 항해 단계 : {levelData?.getPSM?.bms?.[0]?.level}</div> */}
          {!(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin') ?
            <div className="bg-main transform hover:scale-105 text-center mt-10 px-36 py-4 text-lg text-white cursor-pointer flex items-center" ><LockClosedIcon className="w-5 h-5 mr-2" />항해 경로</div> : 
            <div className="bg-main transform hover:scale-105 text-center mt-10 px-36 py-4 text-lg text-white cursor-pointer " >항해 경로</div>
          
          }
          {!(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin') ?
            <div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer flex items-center" ><LockClosedIcon className="w-5 h-5 mr-2" />항해 이력</div> : 
            <div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer" >항해 이력</div>
          }
          {
            (localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin') ?
              <Link to={'/step/sailingStart'}><div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer">항해 시작</div></Link> :
              <>
                <Link to={'/step/sailingTest'}><div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer">테스트</div></Link>
                <MastPaymentFooter />
              </>
          }
        </div> 
        <div className="flex-1"></div> 
      </div>
    </>

  )
}