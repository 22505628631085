import React from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { authTokenVar, isLoggedInVar } from '../apollo';
import Logo from '../images/mast_logo.png';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

interface NavProps {
  page: string;
}



export const Header2: React.FC<NavProps> = ({page}) =>{
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('directpage');
    isLoggedInVar(false)
    alert('항해를 완료하였습니다.')
  }

  const moveToStep = () => {
    if(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin' ) {
      history.push('/step')
    }
  }
  

  const isLoggedIn = useReactiveVar(isLoggedInVar);
  return (
    <div className="sticky w-screen top-0 z-30">
      <div className="bg-main">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">아이디어 건조(make) 과정 1기 모집합니다.</span>
              <span className="hidden md:inline">아이디어 건조(make) 과정 1기 모집합니다.</span>
            </p>
          </div>
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
            >
            </button>
          </div>
        </div>
      </div>
      <Disclosure as="nav" className="bg-white shadow py-3 ">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to={'/'}>
                      <img
                        className="h-8 w-auto sm:h-10"
                        src={Logo}
                        alt="마스트벤처스"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <a
                      href="/introduction"
                      className={classNames(page ==='introduction' ? 'border-main text-main' :  'border-transparent text-gray-500'  ,' hover:border-gray-300 hover:text-main inline-flex items-center px-1 pt-1 text-lg font-medium')}
                    >
                      We are MAST ventures
                    </a>
                    <a
                      href="/curriculum"
                      className={classNames(page ==='curriculum' ? 'border-main text-main' :  'border-transparent text-gray-500'  ,' hover:border-gray-300 hover:text-main inline-flex items-center px-1 pt-1 text-lg font-medium')}
                    >
                      항해소개
                    </a>
                    <a
                      href="/program"
                      className={classNames(page ==='program' ? 'border-main text-main' :  'border-transparent text-gray-500'  ,' hover:border-gray-300 hover:text-main inline-flex items-center px-1 pt-1 text-lg font-medium')}
                    >
                      마스트 수업
                    </a>
                    <a
                      href="/review"
                      className={classNames(page ==='review' ? 'border-main text-main' :  'border-transparent text-gray-500'  ,' hover:border-gray-300 hover:text-main inline-flex items-center px-1 pt-1 text-lg font-medium')}
                    >
                      마스트콘텐츠
                    </a>
                    <a
                      href="/step"
                      className={classNames(page ==='step' ? 'border-main text-main' :  'border-transparent text-gray-500'  ,' hover:border-gray-300 hover:text-main inline-flex items-center px-1 pt-1 text-lg font-medium')}
                    >
                      사업성장 관리(테스트)
                    </a>  
                    
                  </div>
                </div>
                {isLoggedIn ? 
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={moveToStep}
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                항해일지
                              </a>
                            )}
                          </Menu.Item>
                          {localStorage.getItem('role') === 'Admin' &&
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/admin"
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                  관리자 페이지
                                </a>
                              )}
                            </Menu.Item>
                          }
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                설정
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={logout}
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                항해완료
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div> :
                  <div className="flex">
                    <button><Link to={'/login'}>로그인</Link></button>
                    <button className="ml-6"><Link to={'/signup'}>회원가입</Link></button>
                  </div>

                }
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">

              <div className="pt-2 pb-3 space-y-1">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                <a
                  href="/introduction"
                  className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  We are MAST ventures
                </a>
                <a
                  href="/curriculum"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  항해소개
                </a>
                <a
                  href="/program"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  마스트 수업
                </a>
                <a
                  href="/review"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  마스트콘텐츠
                </a>
                <a
                  href="/step"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  사업 성장관리(테스트)
                </a>
                {/* <a
                  href="/step"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  스타트업 소개
                </a> */}
              </div>
              {isLoggedIn ?
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <span className="inline-block h-10 w-10 mt-1 rounded-full overflow-hidden bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{localStorage.getItem('name')}</div>
                      <div className="text-sm font-medium text-gray-500">{localStorage.getItem('email')}</div>
                    </div>
                    <button className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 z-40">
                    <a
                      onClick={moveToStep}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      항해일지
                    </a>
                    {localStorage.getItem('role') === 'Admin' &&
                      <a
                        href="/admin"
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      >
                        관리자 페이지
                      </a>
                    }
                    <a
                      href="#"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      설정
                    </a>
                    <a
                      onClick={logout}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      항해완료
                    </a>
                  </div>
                </div> :
                <>
                  <button><Link to={'/login'}>로그인</Link></button>
                  <button><Link to={'/signup'}>회원가입</Link></button>
                </>
              
              }
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
