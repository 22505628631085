
import { Fragment, useEffect } from 'react'
import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  MenuIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'
import Kim from '../images/kim.png'
import Shin from '../images/sintaesoon.png';
import Lee from '../images/Leewonyeop.png';
import Je from '../images/junhoje.png';
import { Footer } from '../components/footer';
import { MainHeader } from '../components/mainHeader';
import NightSea from '../images/nightsea.jpg'
import { Header2 } from '../components/header2';
import { VideoCard } from '../components/videoCard';
import { MastPaymentFooter } from '../components/MastPaymentFooter';
import { useHistory } from 'react-router';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
];



export const Introduction = () => {
  const history = useHistory();
  useEffect(() => {
    history.push('https://ad.mastventures.co.kr')
  },[] );
  return (
    <>
      <Header2  page={'introduction'}/>
      {/* <MainHeader ismain={false} page={'/introduction'}  /> */}
      <div className="md:grid md:grid-cols-5 sm:max-w-7xl mx-auto ">
        <div className="md:col-span-3">
          <div className="w-11/12 sm:max-w-7xl mx-auto text-black sm:pl-3 pt-10 sm:pt-40 pb-12 text-2xl sm:text-5xl GmarketB">We are MAST Ventures!</div>
          <div className="w-11/12 sm:max-w-7xl mx-auto text-black sm:pl-5  text-base  sm:text-xl GmarketL">
            <p>안녕하세요. 마스트벤처스입니다. </p>
            <p><span className="text-main GmarketB">MA</span>ke + <span className="text-main GmarketB">ST</span>artup 앞의 두글자를 따서 <span className="text-main GmarketB">MAST</span>라고 이름 지었습니다.. </p>
            <p>Mast는 배의 돛대 라는 뜻이 있습니다.</p>
            <p>제가 생각하는 창업은 항해와 같습니다. 대항해 시대의 항해와 같다고 생각합니다. <br />
               무엇이 있을지 알수도 없지만, 멀리 멀리 가보고 싶다고 꿈을 꾼 사람들이 <br />
               크기도 제각각인 배를 만들어, 드넓은 바다를 향해 힘찬 항해를 시작한.<br /><br />
               먼 바다를 나가기 위해서 많은 것들이 필요합니다.<br />
               배 (아이디어), 배를 운행할 선원들(사람), 먹고 마실 많은 물과 음식등(자본) <br />
               어느 것 하나 없다면 배는 바다를 나갈수 없습니다. <br /><br />
               바다는 많은 위험들이 있습니다.<br />
               파도, 폭풍, 부유물, 뱃멀미, 천둥번개, 비, 바람, 물과음식 부족, 해적, 알수없는 병<br />
               해양생물등 많은 위험들이 있습니다.<br />
            </p><br />
          </div>
        </div>
        <div className="md:col-span-2 sm:pt-40 hidden md:grid">
          <VideoCard videoId="obNXXizoLug" />        
        </div>
        <div className="col-span-5 w-11/12 sm:max-w-7xl mx-auto text-black text-base  sm:text-xl GmarketL">
        이런 위험속에서도 수 많은 배가 대양을 향해 나아갔고, 그들은 신대륙과 새로움을 가지게 되었습니다.<br />
               그리고 많은 선원들이 조타사와 길잡이배를 끌며 다른 탐험가를 도왔습니다.<br /><br />
               마스트벤처스는  힘들기만 한 창업을 맨땅에 해딩하지 않도록 돕고자 합니다.<br />
               건강하게 방향을 조정하고 시행착오를 줄이며, 의견을 토론하고, 연혁을 만들고 많은 직업과 직장을 <br />
               만들어 낼 수 있는 위대한 창업가를 만들고자 합니다.
        </div>
      </div>
      {/* <div className="text-blue-900 text-4xl mt-20 lg:grid lg:justify-items-center lg:max-w-7xl">강사소개</div> */}
      <div className="mt-24">
        <div className="md:mx-auto md:grid md:justify-items-center md:max-w-7xl lg:text-lg">
          <div>
            
            <div className="text-center w-11/12 text-lg md:text-3xl GmarketM my-36 md:max-w-5xl mx-auto">
              <div className="mb-10">행복하게 돈버는 사업가를 찾기 힘든 이유</div>
              <div className="text-sm sm:text-xl bg-gray-300 py-4 rounded-lg">1. 자유롭고, 돈 많이 벌 수 있다는 <span className="text-red-600">창업에 대한 환상은 달콤하게 포장되어 있고</span> </div>
              <div className="text-sm sm:text-xl bg-gray-300 py-4 rounded-lg mt-5">2. 그 목표를 향해 가는 <span className="text-red-600">현실적인 가이드와 실질적인 피드백은 턱없이 부족</span>하기 때문입니다. </div> 
              <div className="text-sm sm:text-xl bg-gray-300 py-4 rounded-lg mt-5">3. <span className="text-red-600">수십년전 경영이론에 기반해 주입식으로 이뤄지는 창업 교육</span>은 창업자의 현실감각을 저해합니다. </div> 
              <div className="text-sm px-1 sm:text-xl bg-gray-300 py-4 rounded-lg mt-5">4. 어제의 정답이 오늘의 오답이 되는 빠른 변화의 세상에서 <span className="text-red-600">문제를 정확히 진단하고, 가설을 뾰족하게 세우고, 리즈너블한 해결책을 도출하는 능력을 갖춰가지 못하면</span> 창업 후 벌어지는 예상못한 장애물들을 뛰어넘기 어렵습니다. </div> 
            </div>
            
            <div className="text-center w-11/12 text-lg md:text-3xl GmarketM my-52 md:max-w-5xl mx-auto ">
              <div className="mb-10  text-xl md:text-2xl">이런 문제를 해결하기 위해,</div>
              <div className="mb-10 ">마스트벤처스의 모든 수업은 세가지에 집중합니다.</div>
              <div className="grid md:grid-cols-3 md:gap-5">
                <div className="hidden md:inline-block border-2 shadow-2xl rounded-lg border-main px-5 py-5 text-lg  md:text-lg" style={{height:"200px"}}>
                  <p className="leading-10">1. 창업에 필요한 이론은 이해하기 쉽게 <span className="text-red-600 md:text-2xl">사례를 바탕으로 교육</span>하교, <span className="text-red-600 md:text-2xl">스스로 실행할 수 있는 과정</span>을 만듭니다. </p>
                </div>
                <div className="hidden md:inline-block border-2 shadow-2xl rounded-lg border-main px-5 py-9 text-lg  md:text-lg " style={{height:"200px"}}>
                  <p className="leading-10">2. 실행하고자 하는 아이디어를 <span className="text-red-600 md:text-2xl">스스로 정리</span>하고 <span className="text-red-600 md:text-2xl">문서화<br /></span>합니다.</p>
                </div>
                <div className="hidden md:inline-block border-2 shadow-2xl rounded-lg border-main px-5 py-9 text-lg  md:text-lg " style={{height:"200px"}}>
                  <p className="leading-10">3. <span className="text-red-600 md:text-2xl">서비스를 제작</span>하고,<br /><span className="text-red-600 md:text-2xl">고객을 만나보고,</span>다양한 사람들 앞에서 <span className="text-red-600 md:text-2xl">발표</span>합니다.</p>
                </div>

                <div className="md:hidden border-2 shadow-2xl rounded-lg border-main px-2 py-5 text-lg  md:text-2xl mb-5" style={{height:"100px"}}>
                  <p>1. 창업에 필요한 <span className="text-red-600 ">최소한의 이론</span>오랜 <span className="text-red-600 ">창업 경험과 코칭 사례</span>를 바탕으로 한 <span className="">강의</span> </p>
                </div>
                <div className="md:hidden border-2 shadow-2xl rounded-lg border-main py-5 px-2 mb-5" style={{height:"100px"}}>
                  <p >2. 반복적인 수강생의 <span className="text-red-600">발표와 피드백</span>을 통한 <span className="text-black md:text-xl">아이템 검증과 비즈니스 모델 고도화</span></p>
                </div>
                <div className="md:hidden border-2 shadow-2xl rounded-lg border-main py-5 px-2 " style={{height:"100px"}}>
                  <p>3. 서비스 개발과 빌드업 과정에서 얻은 <span className="text-red-600">기술적 노하우 및 인간관계 노하우</span></p>
                </div>
              </div>
            </div>
            <div className="text-center text-lg md:text-3xl GmarketM my-52 md:max-w-5xl mx-auto">
              <div className="mb-10 text-xl md:text-2xl font-extrabold">이 모든 것을 위해 수강생(미래창업가) 입장에서 <span className="text-red-600 ">가장 잘 전달할 수 있는 파트너</span>가 뭉쳤습니다.</div>
              <div className="text-lg md:text-2xl mt-10">
                <div className="mb-2">  </div>
                <div className="mb-2">이제 마스트벤처스를 통해 <span className="text-red-600 ">미지의 세계를 발견하는 항해</span>를 함계 시작합시다.</div>
                <div className="mb-2">당신만 포기하지 않는다면, 우리는 포기하지 않을 것입니다. </div>
                <div>우리는 반드시 찾을 겁니다. 새로운 창업가와 새로운 방법을 그리고 새로운 서비스를 </div>
                <div className="mt-5">당신을 통해 인류는 또 한번 <span className="text-red-600 ">새로운 발전</span>을 만들어 낼 것입니다.</div>
              </div>
              <div></div>
            </div>            
          </div>
        </div>
        <div className=" h-auto hidden lg:block" style={{backgroundColor:"#012e56"}}>
          <div className="lg:mx-auto lg:max-w-7xl">
            <div className="text-4xl text-white font-bold pt-20" style={{backgroundColor:"#012e56"}}>항해사 소개</div>
            <div className="md:grid md:grid-cols-4 gap-5 mt-20">
              <div>
                <div style={{backgroundImage: `url(${Kim})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"20vw", backgroundColor:"#e8e6e1", backgroundPosition: "center 40%"}} 
                  className="relative group "
                ></div>
                <div className="text-center text-white">
                  <div className="mt-5 text-2xl lg:text-2xl mb-7 font-bold" style={{color:"#e8e6e1"}}>갑판장: 김기현 
                      <span className="ml-3 text-sm lg:text-base"> (주) 인스텝스 대표</span>
                  </div>
                  {/* 
                  <p className="mt-3 text-lg  lg:text-lg font-medium text-black">
                    안녕하세요 김기현 입니다 <br />
                    저는 매년 많은 창업팀의 사업계획서를 보고 있습니다.<br />
                    그리고 그 기업들이 어떤 시간을 보내고 있는지 확인하고 있습니다.<br />
                    몇년이 지나면 이름도 빛도 없이 사라지는 많은 기업을 봤습니다. <br />
                    누군가가 미리 알려줬다면… 누군가가  더 의견을 내줬다면 조금만 선택을 쉽게 할 수 있도록 도왔다면...<br />
                    조금만…조금만…. 하면서 도움을 줄 새도 없이 사라지는 기업을 많이 보았습니다. <br />
                    아이템도 중요하지만 실행이 더 중요하고, 실행도 중요하지만 어떤 사람이 어떤 아이템으로 실행할 것인가가 <br />
                    더 중요하다는 스타트업 현장에서 살고 있고, 스타트업 현장을 보고 있습니다.<br />
                    몇년이나 노력했다가 무너지는 사업이 더이상 생기지 않도록 <br />
                    시작할때도 좋은 시작할 수 있도록 하나씩 함께 만들어가고 싶습니다.
                  </p> */}
                </div>
              </div>
              <div>
                <div style={{backgroundImage: `url(${Shin})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"20vw", backgroundColor:"#e8e6e1", backgroundPosition: "center -30%"}} 
                  className="relative group "
                ></div>
                <div className="text-center text-white">
                  <div className="mt-5 text-2xl lg:text-2xl mb-7 font-bold" style={{color:"#e8e6e1"}}>조타장: 신태순 
                      <span className="ml-1 text-sm lg:text-sm"> (주)버터플라이인베스트먼트</span>
                  </div>

                  {/* <p className="mt-3 text-lg  lg:text-lg font-medium text-black">
                    안녕하세요 김기현 입니다 <br />
                    저는 매년 많은 창업팀의 사업계획서를 보고 있습니다.<br />
                    그리고 그 기업들이 어떤 시간을 보내고 있는지 확인하고 있습니다.<br />
                    몇년이 지나면 이름도 빛도 없이 사라지는 많은 기업을 봤습니다. <br />
                    누군가가 미리 알려줬다면… 누군가가  더 의견을 내줬다면 조금만 선택을 쉽게 할 수 있도록 도왔다면...<br />
                    조금만…조금만…. 하면서 도움을 줄 새도 없이 사라지는 기업을 많이 보았습니다. <br />
                    아이템도 중요하지만 실행이 더 중요하고, 실행도 중요하지만 어떤 사람이 어떤 아이템으로 실행할 것인가가 <br />
                    더 중요하다는 스타트업 현장에서 살고 있고, 스타트업 현장을 보고 있습니다.<br />
                    몇년이나 노력했다가 무너지는 사업이 더이상 생기지 않도록 <br />
                    시작할때도 좋은 시작할 수 있도록 하나씩 함께 만들어가고 싶습니다.
                  </p> */}
                </div>
              </div>
              <div>
                <div style={{backgroundImage: `url(${Lee})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"20vw", backgroundColor:"#e8e6e1", backgroundPosition: "center -30%"}} 
                  className="relative group "
                ></div>
                <div className="text-center text-white">
                  <div className="mt-5 text-2xl lg:text-2xl mb-7 font-bold" style={{color:"#e8e6e1"}}>병기: 이원엽 
                      <span className="ml-1 text-sm lg:text-sm"></span>
                  </div>

                  {/* <p className="mt-3 text-lg  lg:text-lg font-medium text-black">
                    안녕하세요 김기현 입니다 <br />
                    저는 매년 많은 창업팀의 사업계획서를 보고 있습니다.<br />
                    그리고 그 기업들이 어떤 시간을 보내고 있는지 확인하고 있습니다.<br />
                    몇년이 지나면 이름도 빛도 없이 사라지는 많은 기업을 봤습니다. <br />
                    누군가가 미리 알려줬다면… 누군가가  더 의견을 내줬다면 조금만 선택을 쉽게 할 수 있도록 도왔다면...<br />
                    조금만…조금만…. 하면서 도움을 줄 새도 없이 사라지는 기업을 많이 보았습니다. <br />
                    아이템도 중요하지만 실행이 더 중요하고, 실행도 중요하지만 어떤 사람이 어떤 아이템으로 실행할 것인가가 <br />
                    더 중요하다는 스타트업 현장에서 살고 있고, 스타트업 현장을 보고 있습니다.<br />
                    몇년이나 노력했다가 무너지는 사업이 더이상 생기지 않도록 <br />
                    시작할때도 좋은 시작할 수 있도록 하나씩 함께 만들어가고 싶습니다.
                  </p> */}
                </div>
              </div>
              <div>
                <div style={{backgroundImage: `url(${Je})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"20vw", backgroundColor:"#e8e6e1", backgroundPosition: "center -30%"}} 
                  className="relative group "
                ></div>
                <div className="text-center text-white">
                  <div className="mt-5 text-2xl lg:text-2xl mb-7 font-bold" style={{color:"#e8e6e1"}}>정보: 제준호 
                      <span className="ml-1 text-sm lg:text-sm"> </span>
                  </div>

                  {/* <p className="mt-3 text-lg  lg:text-lg font-medium text-black">
                    안녕하세요 김기현 입니다 <br />
                    저는 매년 많은 창업팀의 사업계획서를 보고 있습니다.<br />
                    그리고 그 기업들이 어떤 시간을 보내고 있는지 확인하고 있습니다.<br />
                    몇년이 지나면 이름도 빛도 없이 사라지는 많은 기업을 봤습니다. <br />
                    누군가가 미리 알려줬다면… 누군가가  더 의견을 내줬다면 조금만 선택을 쉽게 할 수 있도록 도왔다면...<br />
                    조금만…조금만…. 하면서 도움을 줄 새도 없이 사라지는 기업을 많이 보았습니다. <br />
                    아이템도 중요하지만 실행이 더 중요하고, 실행도 중요하지만 어떤 사람이 어떤 아이템으로 실행할 것인가가 <br />
                    더 중요하다는 스타트업 현장에서 살고 있고, 스타트업 현장을 보고 있습니다.<br />
                    몇년이나 노력했다가 무너지는 사업이 더이상 생기지 않도록 <br />
                    시작할때도 좋은 시작할 수 있도록 하나씩 함께 만들어가고 싶습니다.
                  </p> */}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="pb-16 md:pb-0 md:z-10 md:relative">
          <div className="md:mx-auto md:max-w-7xl md:px-8 md:grid md:grid-cols-7 md:gap-8">
            <div className="relative md:col-span-2">
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:p-0 md:h-full">
                <div className="overflow-hidden sm:aspect-w-16 sm:aspect-h-7 md:aspect-none md:h-full">
                  <img
                    className="object-fit md:object-cover w-full h-90  md:h-90 md:w-full md:mt-5"
                    src={Kim}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className=" md:m-0 md:col-span-5 md:pl-8"> 
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 md:px-0 md:max-w-none md:mt-16">
                <blockquote>
                  <div>
                    <div className="mt-7 md:mt-12 text-xl text-center sm:text-left md:text-4xl text-blue-900 mb-7 font-bold">김기현 갑판장
                      <span className="ml-3 text-sm md:text-2xl">(실제 해군출신)</span>
                    </div>
                    <svg
                      className="h-6 w-6 text-black opacity-100 mt-3"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="mt-3 text-base  md:text-md font-medium text-black">
                    안녕하세요. 갑판장 김기현입니다.<br />
                    저는 매년 많은 창업팀을 만들고, 수많은 창업팀을 만나고 있습니다.<br />
                    시간이 지나고 잘지내는지 연락드리면 많은 회사들이 이름도, 빛도 없이 사라지는 현실을 보았습니다.<br />
                    많은 팀이 창업을 하지만 살아남는 것은 왜 힘들까? <br /><br />

                    또 하나 매년 창업을 하고 싶어하는 많은 분들을 만납니다. <br />
                    하지만 실제로 창업으로 이어지지 않습니다. 아이디어가 없거나, 무엇을 해야 할지 모르거나 <br />
                    어떻게 시작해야 할지 모르기에 창업으로 이어지지 않는 것을 보았습니다.<br /><br />

                    그래서 시작하기로 했습니다.<br />
                    창업을 할수 있도록, 창업을 오랫동안 행복하게 할수 있도록 같이 고민하고, 같이 실행해야겠다! 생각했습니다.<br />
                    옆에서 현실적인 얘길하고, 현실적인 실행안을 줘야겠다.<br />
                    옆에서 실행하는 것을 같이 봐줘야겠다! <br /><br />

                    몇 년이 지나도 스스로의 노력이 후회가 남지 않도록<br />
                    무너지는 것이 아니라 성장할 수 있도록 <br />
                    시작할때의 기쁨이 10년뒤도 기쁠수 있도록 함께 하겠습니다! 
                    </p>
                  </div>
                  
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-44">
        <div className="pb-16 md:pb-0 md:z-10 md:relative">
          <div className="md:mx-auto md:max-w-7xl md:px-8 md:grid md:grid-cols-7 md:gap-8">
            <div className=" md:m-0 md:col-span-5 md:pl-8">
              <div className="relative md:hidden md:col-span-2">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:p-0 md:h-full">
                  <div className="aspect-w-10 aspect-h-6 overflow-hidden sm:aspect-w-16 sm:aspect-h-7 md:aspect-none md:h-full">
                    <img
                      className="object-cover md:h-96 md:w-96"
                      src={Shin}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 md:px-0 md:max-w-none">
                <blockquote>
                  <div>
                    <div className="mt-2 md:mt-2 text-2xl md:text-4xl text-blue-900 mb-7 font-bold">신태순
                      <span className="ml-3 text-sm md:text-2xl">조타장</span>
                    </div>
                    <svg
                      className="h-6 w-6 text-black opacity-100 mt-3"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="mt-3 text-md md:text-md font-medium text-black">
                    <p>인생에 한번은 창업을 해야 생존할 수 있는 시대가 온 것 같습니다.</p>

                    그래서 창업과 관련된 강의도 많아지고 여기저기 아이디어도 넘쳐나는 것을 목격하게 됩니다. <br />

                    그와중에 재밌게 사업을 잘하는 분도 계시지만, 돈을 전혀 벌지 못하거나 돈을 벌더라도 치열한 경쟁에 치여서 행복하지 않는 경우도 자주 보게 됩니다. <br />

                    저는 이왕하는 창업을 즐겁게 오래 할 수 없을까 고민해오고 실험했던 사람입니다. <br />
                    
                    그래서 경쟁하지 않으면서, 창업자의 성장을 기반으로 하는 가이드를 하면서 창업자분들을 만나왔습니다.  <br />

                    이제 치열한 경쟁에서 싸우고 이기는 전략만으로는  경쟁력을 갖추기 어렵습니다.<br />

                    좋은 아이템, 좋은 팀, 좋은 투자가 즐거운 결과물로 맺어지고 경쟁에서 초월하기 위해서는 창업자의 관점 변화와 기업가 정신에 대한 이해가 필요합니다. <br />
                    이와 관련된 마인드셋과 거기서 파생된 선진화된  마케팅 전략에 대해 공유하고 싶습니다. <br />
                    </p>
                  </div>
                  
                </blockquote>
              </div>
            </div>
            <div className="hidden md:inline-block md:relative md:col-span-2">
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:p-0 md:h-full">
                <div className="aspect-w-10 aspect-h-6 overflow-hidden sm:aspect-w-16 sm:aspect-h-7 md:aspect-none md:h-full">
                  <img
                    className="object-cover md:h-96 md:w-96"
                    src={Shin}
                    alt=""
                  />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="mt-44">
        <div className="pb-16 md:pb-0 md:z-10 md:relative">
          <div className="md:mx-auto md:max-w-7xl md:px-8 md:grid md:grid-cols-7 md:gap-8">
            <div className="relative md:col-span-2">
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:p-0 md:h-full">
                <div className="aspect-w-10 aspect-h-6 overflow-hidden sm:aspect-w-16 sm:aspect-h-7 md:aspect-none md:h-full">
                  <img
                    className="object-fit md:h-96 md:w-80"
                    src={Lee}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className=" md:m-0 md:col-span-5 md:pl-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 md:px-0 md:max-w-none">
                <blockquote>
                  <div>
                    <div className=" mt-5 text-2xl md:text-4xl text-blue-900 mb-3 font-bold">이원엽
                      <span className="ml-3 text-2xl">병기</span>
                    </div>
                    <svg
                      className="h-6 w-6 text-black opacity-100 mt-5 md:mt-10"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="text-md font-medium text-black">
                      우리는 누구나 시행착오라는 것을 하죠. 저의 언어로는 삽질을 통해 배운다고 말씀드리고 싶어요. <br />
                      하지만 한 삽을 뜰 때마다 모래성이 무너지지 않고 더욱 단단해진다면 어떨까요?<br /><br />

                      ‘도전을 멈출 때가 실패하는 것이다.’라는 마인드로 창업을 했던 경험을 살려, 수많은 실패를 토대로 얻은 값진 경험을 나누고자 합니다.<br /><br />

                      ICT 베이스의 창업을 준비하는 분들에게 기획, 디자인, 개발, 마케팅, 경영 등 벽으로만 느껴졌던 각 분야의 어려움을 생생한 실무 중심의 강의와 피드백을 통해 함께 헤쳐 나가겠습니다.<br /><br />

                      수 많은 삽질로 단련된 실전 근육으로 모래성을 견고하게 쌓도록 도와드리겠습니다. 
                      마스트벤처스와 함께 여러분만의 선박을 건조하고 파괴력있는 병기를 만들어 멋진 항해를 해보아요!
                    </p>
                  </div>
                  
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-44 mb-44">
        <div className="pb-16 md:pb-0 md:z-10 md:relative">
          <div className="md:mx-auto md:max-w-7xl md:px-8 md:grid md:grid-cols-7 md:gap-8">
            <div className=" md:m-0 md:col-span-5 md:pl-8">
              <div className="relative md:hidden md:col-span-2">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:p-0 md:h-full">
                  <div className="aspect-w-10 aspect-h-6 overflow-hidden sm:aspect-w-16 sm:aspect-h-7 md:aspect-none md:h-full">
                    <img
                      className="object-cover md:h-90 md:w-full"
                      src={Je}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 md:px-0 md:max-w-none">
                <blockquote>
                  <div>
                    <div className="mt-5 md:mt-10 text-2xl md:text-4xl text-blue-900 mb-7 font-bold">제준호 
                      <span className="w-full text-xl ml-3 md:text-2xl">정보</span>
                    </div>
                    <svg
                      className="h-6 w-6 text-black opacity-100 mt-3"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="mt-3 text-md font-medium text-black">
                    안녕하세요. 저는 컴퓨터공학과를 전공해서 줄곧 개발의 길만 걸어왔습니다. <br />
                    그래서 그런지 창업이 저와는 무관한 거리가 먼 일이고, 또 창업은 똑똑한 사람만이 할 수 있다라고 생각해왔습니다.<br /><br />

                    그렇게 직장인으로의 삶 4년차, 직장인의 월급으로는 도저히 미래가 안보인다는 것을 깨닫고 제가 감히 무모하게 창업이라는 것을 공부하기 시작했습니다.
                     관련 책을 수십,수백권을 읽고 현실에 적용시켜가며 실패의 연속을 맛보았고, 지금도 계속 진행 중입니다.<br /><br />

                    저와 같이 창업은 나와 거리가 멀다라고 느끼거나 두려움을 가지신 분들에게 결코 창업은 남의 일이 아니라는 것을 알려드리고 올바른 방향으로 갈 수 있도록 도와드리고 싶습니다.<br />
                    </p>
                  </div>
                  
                </blockquote>
              </div>
            </div>
            <div className="hidden md:inline-block relative md:col-span-2">
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:p-0 md:h-full">
                <div className="aspect-w-10 aspect-h-6 overflow-hidden sm:aspect-w-16 sm:aspect-h-7 md:aspect-none md:h-full">
                  <img
                    className="object-cover md:h-90 md:w-full"
                    src={Je}
                    alt=""
                  />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <MastPaymentFooter />
      <Footer />
    </>
  )
}
