import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LevelStatus } from "./Levelstatus"
import Boat from '../images/boat.gif';
import { commentPSMMutation, commentPSMMutationVariables } from "../__generated__/commentPSMMutation";
import { getPSM } from "../__generated__/getPSM";
import { GET_PSM_QUERY, COMMENT_PSM_MUTATION } from "./logbook2";
import { commentIntroMutation, commentIntroMutationVariables } from "../__generated__/commentIntroMutation";
import { getIntro } from "../__generated__/getIntro";


interface IForm {
  idea: string;
  introduction: string
}

export const COMMENT_INTRO_MUTATION = gql`
  mutation commentIntroMutation($commentIntroInput: commentIntroInput!) {
    commentIntro(input: $commentIntroInput) {
      ok,
      error
    }
  }
`;

export const GET_INTRO_QUERY = gql`
  query getIntro {
    getIntro {
      ok
      error
      bms {
        introduction,
        idea
      }
    }
  }
`;

export const LogBook1 = () => {
  const [step, setStep] = useState(1);
  const { data } = useQuery<getIntro>(GET_INTRO_QUERY);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  const temporarySave = () => {
      alert('임시저장 되었습니다.')
      // commentPSMMutation({
      //   variables: {
      //     commentPSMInput: {
      //       problem,
      //       solution,
      //       BM
      //     }
      //   }
      // });
  }
  const previous = () => {
    setStep(step-1);
  }
  const onCompleted = (data: commentIntroMutation) => {
    const {
      commentIntro: { ok, error },
    } = data;

    if (ok) {
      alert('자기소개  작성완료!')
      window.location.reload();
    } else {
      alert(error)
    }
  };
  const [ commentIntroMutation, { data: commentIntroMutationResult, loading } ] = useMutation<commentIntroMutation, commentIntroMutationVariables>(COMMENT_INTRO_MUTATION, { onCompleted});
  const onSubmit = () => {
    const { idea, introduction } = getValues();
    if( step === 2 ) {
      if((introduction && idea)) {
        alert('제출되었습니다.')
        commentIntroMutation({
          variables: {
            commentIntroInput: {
              introduction,
              idea
            }
          }
        });
        
      } else {
        alert('모든 질문에 답변해주세요.')
      }
    } else {
      setStep(step +1);
    }
  }
  const inValid = () => {
    console.log(errors)
  }


  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap " >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        {/* <div className="text-4xl GmarketM transition-opacity text-center mb-12" style={{width:" 500px"}}>3일차 항해일지</div> */}
        {/* <div className="text-lg GmarketM transition-opacity text-center mb-20 text-red-400" style={{width:" 500px"}}>수강생에 한해서 강사님의 구체적인 피드백을 받을 수 있습니다.</div> */}
        <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
        {step === 1 && 
          <div className=" mt-10">
            <label htmlFor="introduction" className=" text-lg font-medium text-gray-700 ">
              1. 첫 번째 항해일지는 발표를 위한 자기소개 작성입니다.
            </label>
            <p className="mt-2 text-sm text-gray-500">- 자유양식입니다. 자기소개를 간단히 기록해 보세요.</p>
            <p className="mt-2 text-sm text-gray-500">- 발표 시간은 3분입니다. 3분을 다 사용하셔도 되고, 인사만 하셔도 됩니다. </p>
            <p className="mt-2 text-sm text-gray-500">- 첫째 날 저녁 발표하게 됩니다. 두려워하지 마세요. 모두가 같은 상황 입니다.</p>
            <div className="mt-1">
              <textarea
              {...register("introduction", {
                required: "이름을 입력해주세요",
              })}
                id="introduction"
                name="introduction"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getIntro?.bms ? data.getIntro?.bms[0]?.introduction : ''}
              />
            </div>
          </div>
        }
        {step === 2 && 
          <div className=" mt-10">
            <label htmlFor="idea" className=" text-lg font-medium text-gray-700 ">
              1. 아이디어가 있는 분만 기록해보세요.
            </label>
            <p className="mt-2 text-sm text-gray-500">- 가지고 있는 창업 아이디어를 적어보세요. 자유양식입니다.</p>
            <p className="mt-2 text-sm text-gray-500">- 강사외엔 아무도 보지 않을 겁니다. </p>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 이제부터 시작입니다. 걱정마세요.</p>
            <div className="mt-1">
              <textarea
              {...register("idea", {
                required: "이름을 입력해주세요",
              })}
                id="idea"
                name="idea"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getIntro?.bms ? data.getIntro?.bms[0]?.idea : ''}
              />
            </div>
          </div>
        }
        {/* {step ===2 && 
          <div className="mt-20">
            <label htmlFor="solution" className=" text-lg font-medium text-gray-700">
              2. 어떻게 해결할꺼야? (Solution)
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 위의 앱은 어떤 방식으로 문제를 해결하나요?</p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙 : 회사에서만 사용하는 앱을 만들겠다.</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("solution", {
                  required: "이름을 입력해주세요",
                })}
                id="solution"
                name="solution"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPSM?.bms ? data.getPSM?.bms[0]?.solution : ''}
              />
            </div>
          </div>
        }
        {step ===3 && 
          <div className="mt-20">
            <label htmlFor="BM" className=" text-lg font-medium text-gray-700">
              3. 돈은 누가 낼꺼야? (Money)
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 위의 앱은 누가 돈을 내게 될지 적어보세요.</p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙 : 사용하는 기업이 돈을 낼꺼야. 자 쉽죠? 한번 적어보세요 </p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("BM", {
                  required: "이름을 입력해주세요",
                })}
                id="BM"
                name="BM"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPSM?.bms ? data.getPSM?.bms[0]?.BM : ''}
              />
            </div>
          </div>
        } */}
        <div className="flex justify-center mt-12">
          {/* <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            임시 저장
          </button> */}
          {step !== 1 &&
            <button
              type="button"
              onClick={previous}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
            >
              이전
            </button>
          }
          {/* <button
            type="button"
            onClick={temporarySave}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {'임시저장'}
          </button> */}
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {step === 2 ? '제출' : '다음'}
          </button>
        </div>
        </form>
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}