import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "1.스타트업은 무엇을 뜻하나요?",
    answer:
      `벤처기업협회에서는 
      벤처기업(스타트업)을 ‘개인 또는 소수의 창업인이 
      위험성은 크지만 성공할 경우 높은 기대수익이 예상되는 신기술과 아이디어를 
      독자적인 기반 위에서 사업화하려는 신생 중소기업’으로 정의합니다. 
      미국에서는 주로 신생 분야에 도전하여 파괴적 혁신을 만들면서 
      탁월한 성과를 이뤄내기 위한 회사 및 팀으로 여겨지고 있습니다. `,
  },
  {
    question: "2. 창업 아이디어가 없는데 교육을 받아도 될까요?",
    answer:
      `창업아이디어를 어떻게 도출할 것인지에 대한 교육이 포함되어 있습니다. 
      그렇기 때문에 창업 아이디어가 없는 분도 교육에 참여할 수 있습니다. 
      창업아이디어가 있는 분도 이 도출과정에 자신의 아이디어를 대입시켜보면서
      좀 더 아이디어를 고도화 시킬 수 있고, 혹은 지금 보다
      더 잘 맞는 아이템을 발견할 수 있습니다.`,
  },
  {
    question: "3. 창업을 한번도 해본적이 없어서 두려움이 많이 생기는데 괜찮을까요?",
    answer:
      `창업을 경험하지 못한 사람에게 창업은 참 험난한 미지의 세계입니다. 
      소중한 내 시간을 투자해서 위험해보이는 창업에 투자를 해야할지 
      두려움이 생기는 것은 자연스러운 현상입니다. 
      저희는 창업을 하고 계속 다음 단계로 올라가는 과정까지 커버하지만
      초기 창업의 막막함을 줄이는 단계부터 시작합니다. 
      자신의 생각으로 사람들을 설득해보고, 피드백을 받으면서
      문제해결력을 키워가다보면 어느새 창업에 대한 두려움이 걷혀가는
      자신의 모습을 보게 될 것입니다.`,
  },
  {
    question: "4. 어떤 것을 배우나요?",
    answer:
      `기초과정과 정규과정으로 구분해서 교육을 하고 있습니다.
      기초과정은 창업아이디어가 없다고 해도 스스로 아이디어를 도출해보고 
      그 아이디어를 구체화시키는데 필요한 모든 활동들이 포함되어 있습니다. 
      세상에 좋은 아이디어들은 넘쳐나고
      세상에 공개된 좋은 아이디어들도 정말 많습니다. 
      하지만 그 아이디어들이 모두 사업화되거나 사업화된다고
      돈을 벌거나 하지는 않습니다. 
      그렇게 내 아이디어가 사장되는 확률을 최소화하기 위해서 필요한
      검증과 토론의 과정이 진행됩니다. 
      정규과정은 도출한 창업아이디어를 바탕으로 실제
      현실에 구현해보는 작업에 필요한 과정입니다. 
      창업을 시작해서 법인을 설립하고, 팀을 꾸리는 것부터
      국가지원과 투자를 통해서 자금을 마련하는데 필요한
      실무 이야기를 배우게 되고, 체득하는데 필요한
      활동들을 하게 됩니다. 
      이론적인 내용도 다루지만, 철저히 실천에 필요한 만큼 다룹니다. 
      배울 수 있는 이론들은 넘쳐납니다. 중요한 것은 그것을 어떻게 
      자신의 것으로 만들고 실제 현실에서 구현해보고 깨져보고
      발전시키느냐 입니다. 
      더 본질적인 부분에 집중하지 않으면 
      사업에서 경쟁력을 갖추는 것은 요원해집니다. 
      그렇기 때문에 
      그럴듯한 말들로 서류를 꾸며서 
      단순히 정부지원금을 받는데 치중하지 않습니다. 
      소비자를 만족시키고, 그런 결과물을 바탕으로 
      정부에서 지원을 해줄 수 밖에 없도록
      만드는데 집중합니다. 
      돈과 시장을 쫓아다니며 계속 흔들리는 사업가는 불행합니다. 
      자기 중심을 잡고, 돈과 시장이 쫓아오게 만드는 사업가로 성장하게 만드는게 목적입니다. `,
  },
  {
    question: "5. 사정에 의해서 진도를 따라가지 못하면 어떻게 돼나요?",
    answer:
      `어떤 일이든 우선순위가 앞에 있지 않으면 \n
      제대로 일이 진행되지는 않습니다. 
      이왕 참여한다면 이 과정에 우선 순위를 높게 책정하고
      참여해주시길 당부드립니다. 
      그럼에도 불구하고, 피치 못할 상황이라는게 존재합니다. 
      그런 때라 하더라도 자신이 참여할 수 있을 만큼은
      최선을 다해서 참여해주세요. 
      그리고 그런 상황을 솔직하게 공유해주세요. 
      문제 상황이 발생하고, 그것을 현명하게 해결해가면서 
      성정하는게 스타트업입니다. 
      교육 과정중에 자신이 겪게 되는 크고 작은 사건 사고들 
      그 모든 것들을 과거와 다르게 좀 더 적극적으로 해결해보는
      실험으로 생각해보세요. 
      함께 하는 파트너와 동료분들이 문제 해결에 도움되는
      작업을 같이 하게 됩니다. 
      매주 질문하고, 고민하고, 답을 하다보면 자연스럽게 
      문제가 해결되는 경험을 하게 됩니다. 
      그게 앞으로 창업 후 겪게 될 문제 상황에서 
      빠르게 문제를 돌파하는 동력이 됩니다. `,
  },
  {
    question: "6. 수업은 어떻게 진행 되나요?",
    answer:
      `매주 화요일 낮 시간에는 이론 수업을 통해 정보를 습득하고, 과거의 한계를 벗어나는 관점을 체득합니다. 
      그리고 이렇게 습득한 내용을 바탕으로 매주 정해진 시간에 동료와 함께 토론하고, 발표하면서 
      배운 내용을 구체화해보고, 자신의 상황에 맞게 고도화 시키게 됩니다. 
      수동적으로 듣는 시간과 능동적으로 발표하고 피드백하는 시간이 균형있게 이뤄집니다. 
      과거에 내가 수동적이었든, 능동적이었든 상관없습니다. 
      지금부터 창업하고 필요한 능력을 체득하기 위해 새롭게 배우는 것이기 때문에
      과거에 자신이 어떤 사람이었는지는 지금 별로 중요하지 않습니다. 
      할 수 있는 만큼으로 시작하고, 시작만 한다면 결국 이전보다 훨씬 잘하게 됩니다. `,
  },
  {
    question: "7. 사회경험이 없어서 아무것도 모릅니다. 그래도 들어도 되나요?",
    answer:
      `사회 경험이 많은 것은 수업을 듣거나 창업을 하는데 
      분명 도움이 됩니다. 
      하지만 반대로 과거 경험이 많을수록 선입견을 가지고
      수업을 듣고, 창업을 하게 될 여지도 존재합니다. 
      그래서 결론은 아무런 상관이 없다는 것입니다. 
      과거에 자신이 어떤 사람이었는지는 중요하지 않습니다. 
      지금 새로운 것은 얼마나 받아들일 마음가짐을 가지느냐가 
      중요합니다. 
      마스트벤쳐스의 파트너와 커리큘럼을 보고 
      자신에게 필요한 부분이라고 느껴지셨다면
      그리고 수십년의 시행착오를 대신 겪은 사람들의
      이야기가 가치있다고 느껴졌다면 받아들이겠다는
      마음으로 참여하면 됩니다. 
      이 과정을 듣는다고 시행착오를 겪지 않을거라는 거짓말을 
      하고 싶지는 않습니다. 
      다만 시행착오를 누구보다 현명하게 극복하고, 가치있게 
      활용해서 성장하는데 사용할 수 있게 된다고는 말씀드릴 수 있습니다. `,
  },
  {
    question: "7. 사업성장 관리가 무엇인가요?",
    answer:
      `수업이 마치고 나면 게더타운에 모여 함께 고민을 시작합니다. 배운 내용을 정리하기도 하고,
       내 생각을 적어보면서 모르는 건 즉각! 헬퍼들에게 요청해서 답을 찾아갑니다.
       자기 스스로 창업 아이템을 찾고, 정리하는 공간입니다.`,
  },
  {
    question: "8. 준비물은 어떻게 돼나요?",
    answer:
      `파트너분들에 대해서 정보를 더 검색해보시면 좋습니다. 
      과거랑 상관없이 미래에 어떻게 살고 싶은지 그려보고, 롤모델도 생각해보세요.
      어린아이로 돌아가서 처음부터 새롭게 배운다는 마음을 준비해주세요.
      노트북,태블릿,노트 등 필기할 것을 준비하세요. (단 모든 내용을 다 기록할 필요는 없습니다.)
      줌(zoom)으로 비대면 강의 진행시 목소리 참여를 위해 마이크 준비가 필요합니다. (이어폰 마이크도 괜찮습니다.)`,
  },

  
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}


export const SubQna = () => {
  return (
    <div className="bg-gray-50">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">자주하는 질문들</h2>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {faqs.map((faq, i) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">{faq.question}</span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    {i === 7 ?
                     <p className="text-base text-gray-500">
                        1. 파트너분들에 대해서 정보를 더 검색해보시면 좋습니다. <br />
                        2. 과거랑 상관없이 미래에 어떻게 살고 싶은지 그려보고, 롤모델도 생각해보세요.<br />
                        3. 어린아이로 돌아가서 처음부터 새롭게 배운다는 마음을 준비해주세요.<br />
                        4. 노트북,태블릿,노트 등 필기할 것을 준비하세요. (단 모든 내용을 다 기록할 필요는 없습니다.)<br />
                        5. 줌(zoom)으로 비대면 강의 진행시 목소리 참여를 위해 마이크 준비가 필요합니다. (이어폰 마이크도 괜찮습니다.)<br />
                      </p> :
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    }
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  </div>
  )
}
