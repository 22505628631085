import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Idea from '../images/idea.jpg';


interface ProgramCardProps {
  reviewPosts: any
}


export const LectureCard:React.FC<ProgramCardProps> = ({reviewPosts}) => {
  const history = useHistory();
  const moveToDetail = () => {
    history.push('/program/idea')
  } 
  return (
    <Link to={'/program/idea'}>
      <div key={reviewPosts.id} style={{backgroundImage: `url(${Idea})`, backgroundSize: '100% 41%', backgroundRepeat:"no-repeat", height:"490px"}} 
        className="transform hover:scale-105 p-5 border-main bg-white cursor-pointer bg-opacity-50 shadow-lg h-96 border-2 rounded-2xl border-transparent border-opacity-50 hover:border-opacity-100"
      >
        <div className="w-full mt-3 text-white GmarketB text-2xl">1. 위대한 계획</div>
        <div className="w-full text-white GmarketB text-2xl">(아이디어 만들기)</div>
        <div className="w-8/12 mt-6 text-white GmarketM text-lg max-w-lg">머리속에 있는 아이디어 이제는 구체화 해봅시다.</div>
        <div className="mt-12 text-black GmarketB text-2xl">"4주간 힘들겁니다."</div>
        <div className="mt-5 text-black GmarketL text-sm">- 배우고, 정리하고, 발표합니다.</div>
        <div className="text-black GmarketL text-sm">- 가능할까? 했다면 가능하게 만들어봅시다.</div>
        <div className="text-black GmarketL text-sm">- 스스로가 볼 수 있도록 정리해봅시다.</div>
        <div className="text-black GmarketL text-sm">- 행동할 수 있는 시작을 만들어봅시다.</div>
        <div className="text-black text-center mt-6 text-sm"><div className="mt-2 text-xl font-bold text-red-500 "><span className="text-gray-500 text-sm line-through mr-2">550,000원</span>110,000 원 (-80%)</div></div>
        <div className="grid justify-items-center">
          <Link to={'/program/idea/apply'}><button className="mt-2 text-white GmarketM text-sm px-6 py-2 shadow-lg rounded-3xl border justify-center bg-main">수강신청 하러가기</button></Link>
        </div>
      </div>
    </Link>
  )
}