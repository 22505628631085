import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Boat from '../images/boat.png';


interface ProgramCardProps {
  reviewPosts: any
}


export const Regular2LectureCard:React.FC<ProgramCardProps> = ({reviewPosts}) => {
  const moveToDetail = () => {
    alert('항해 정규반은 내년 3월에 시작될 예정입니다.')
  }
  return (
    <div key={reviewPosts.id} style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% 41%', backgroundRepeat:"no-repeat", height:"490px"}} 
      className="transform hover:scale-105 p-5 border-main cursor-pointer bg-white bg-opacity-50 shadow-lg h-96 border-2 rounded-2xl border-transparent border-opacity-50 hover:border-opacity-100"
      onClick={moveToDetail}
    >
      <div className="w-full mt-3 text-main GmarketB text-2xl">3. 항해의 시작</div>
      <div className="w-full ml-2 text-main GmarketB text-2xl">(초기창업단계)</div>
      <div className="w-10/12 mt-5 text-main GmarketM text-lg max-w-lg">팀원을 만들고, 자본을 만들고 본격적인 항해를 시작합니다.</div>
      <div className="mt-12 text-black GmarketB text-2xl">"3달간 지옥입니다."</div>
      <div className="mt-4 text-black GmarketL text-sm">- 배우고, 정리하고, 발표합니다.</div>
      <div className="text-black GmarketL text-sm">- 사업계획서와 발표용 PPT를 만듭니다.</div>
      <div className="text-black GmarketL text-sm">- 팀원을 찾고 팀을 만들어갑니다.</div>
      <div className="text-black GmarketL text-sm">- 예산을 책정하고 년간 계획을 정합니다.</div>
      <div className="text-black GmarketL text-sm">- 잠은 다 잤습니다.</div>
      <div className="text-black GmarketL text-sm">- 이제 시작입니다.</div>
      <div className="grid justify-items-center">
        <button className="mt-7 text-white GmarketM text-sm px-6 py-2 shadow-lg rounded-3xl border justify-center bg-main"><Link to={'/program/idea'}>수강신청 하러가기</Link></button>
      </div>
    </div>
  )
}