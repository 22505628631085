import { Header2 } from "../components/header2"
import { MastPaymentFooter } from "../components/MastPaymentFooter"
import Kim from "../images/kim.png";
import Shin from "../images/sintaesoon.png";
import Lee from "../images/Leewonyeop.png";

export const LandingPage = () => {
  return (
    <>
      <div className="text-center mx-auto w-screen bg-indigo-800 text-white flex items-center justify-center text-4xl GmarketM" style={{height:"240px"}}>
        창업 아이템 만들기 1기
      </div>
      <div className="flex">
        <div className="flex-1"></div>
        <div className="mx-auto mt-12 GmarketM flex-shrink-0">
          <div className="mt-12 text-xl"> 요즘 창업에 도전하는 사람들의 수가 급속도로 늘고 있습니다. </div>
          <div className="mt-3 text-xl">하지만, 많은 사람들이 창업을 함에도 불구하고 왜 살아남은 회사의 수가 현저히 낮을까요?</div>
          <div className="mt-3 text-xl"> 많은 이유들이 있겠지만,  초보창업자가 가장 많이 하는 실수는 <span className="text-red-500">“명확하지않는 사업아이템”</span>입니다.</div>
          <div className="mt-24 text-xl">
            <div className="">그렇다면 어떤 창업아이템이 </div> 
            <div className="grid grid-cols-3 gap-3 mt-2">
              <div className="border bg-indigo-800 text-white px-3 py-20 text-xl rounded-md text-center">1. 고객들에게</div>
              <div className="border bg-indigo-800 text-white px-3 py-20 text-xl rounded-md text-center">2. 투자자들에게</div>
              <div className="border bg-indigo-800 text-white px-3 py-20 text-xl rounded-md text-center">3. 정부지원사업 심사위원들에게</div>
            </div>
            <div className="text-right">매력적으로 보일까요?</div>
          </div>
          <div className="text-xl GmaarketM mt-24">1. 4주 후 당신은..</div>
          <div className="ml-5 mb-24 text-xl mt-3">
            <div>1. 남들에게 자신의 아이템에 대해 명확하게 설명할 수 있게 될 것입니다.</div>
            <div>2. 고객들이 내가 만든 서비스를 왜 사용해야하는지 납득시킬 수 있을 것입니다.</div>
            <div>3. 가능성이 있는 창업 아이템을 만드는 방법에 대해 알게 될것입니다.</div>
            <div>4. 실제로 자신만의 창업 아이템을 가지게 될 것입니다.</div>
          </div>
          <div className="text-xl GmaarketM mt-24">2. 모집대상</div> 
          <div className="ml-5 mb-24 text-xl mt-3">
            <div>1) 언젠가는 출퇴근으로부터 자유로워지고 싶은 직장인</div>
            <div>2) 창업을 원하지만 어떻게 시작할지 감이 안오는 분</div>
            <div>3) 창업 관련 온라인강의는 많이 들어봤지만 혼자서 하기에는 너무 벅찬 예비창업가</div>
            <div>4) 대단한 성공도 좋지만 일단 월천이 목표이신 분</div>
            <div>5) 창업에 실패하면 잃을 게 많다라고 생각하는 분</div>
          </div>
          <div className="text-xl GmaarketM mt-24">3. 누가 교육을 하나요?</div> 
          <div className="ml-5 mb-24 text-xl mt-3">
            <div>1) 김기현 대표님</div>
            <div className="grid grid-cols-3">
              <div><img src={Kim} style={{height:"500px"}} /></div>
              <div className="col-span-2 mt-12">
                <div>벤처박스 심사위원 및 멘토 : 2020년 ~ 현재</div>
                <div>넥스트챌린지 파트너: 2020년 ~ 현재</div>
                <div>3개 기업 창업: 2019년 ~ 현재</div>
                <div>서울벤처인큐베이터(SVI) 멘토: 2017년 ~ 현재</div>
                <div>PSWC (pre-startup winning camp) 멘토: 2017년 ~ 현재</div>
                <div>6개 기업 투자 : 2017년 ~ 현재</div>
                <div>주식회사 인스텝스 창업: 2012년 ~ 현재</div>
                <div>이화여대, 한양대 창업 강의: 2021년</div>
                <div>IBK 창공(창업공장) 멘토링 및 강의: 2020년</div>
                <div>청년창업사관학교 멘토: 2019년</div>
                <div>KT 아키텍트 10대 서비스 선정 및 투자유치: 2014년</div>
                <div>광고대행사 (주)이엠넷 대리 : 2010년 ~ 2012년</div>
                <div>(주) CJ 푸드빌 투썸플레이스 부산점 메인매니저: 2009년 ~ 2010년</div>
                <div>구두쇼핑몰 짚신 창업: 2007년 ~ 2008년</div>
                <div>아이작앤로뎀 창업: 2005년 ~ 2006년</div>
                <div>헌책방 창업: 2004년 ~ 2007년</div>
              </div>
            </div>
            <div className="mt-24">2) 신태순 대표님</div>
            <div className="grid grid-cols-3 mt-12">
              <div ><img src={Shin} style={{height:"500px"}} /></div>
              <div className="col-span-2 mt-12">
                <div>30여개 주식회사 기획,공동창업, 콘텐츠마케팅 기획</div>
                <div>각종 대학,기업 콘텐츠마케팅,창업 강의</div>
                <div>유튜브 - 제로창업 운영</div>
                <div>팟캐스트 - 해적들의창업이야기 운영</div>
                <div>오디오클립 - 무자본창업에기름붓기 운영</div>
                <div>경기도 홍보위원회 위원</div>
                <div>스타트업/기업 마케팅 자문 및 CMO</div>
                <div>콘텐츠마케팅아시아포럼 2019 연사</div>
                <div className="mt-12">저서</div>
                <div>{'<나는 1주일에 4시간 일하고 1000만원 번다>'}</div>
                <div>{'<해적들의 창업이야기>'}</div>
                <div>{'<나는 자본없이 먼저팔고창업한다>'}</div>
                <div>{'<게으르지만 콘텐츠로 돈은 잘 법니다>'}</div>
              </div>
            </div>
            <div className="mt-24">3) 이원엽 대표님</div>
            <div className="grid grid-cols-3">
              <div className="mt-16"><img src={Lee} style={{height:"500px"}} /></div>
              <div className="col-span-2 mt-12">
              <div>2021.06 TIPS 창업사업화, 해외마케팅 선정</div>
              <div>2021.05 Data-STARS 8기 선정</div>
              <div>2020.12 중소기업벤처부 장관상 수상</div>
              <div>2020.11 TIPS 선정</div>
              <div>2020.08 데이터진흥원 데이터바우처 사업 선정</div>
              <div>2020.06 교보 이노스테이지 선발팀</div>
              <div>2020.05 창업진흥원 창업도약패키지 선정</div>
              <div>2019.11 교원그룹 스타트업 프라이즈 우수상 수상</div>
              <div>2019.08 K-스타트업 2019 세종지역 1위 수상</div>
              <div>2019.05 sba 액셀러레이팅 보육기업</div>
              <div>2019.01 씽즈부설연구소 설립</div>
              <div>2018.12 벤처기업인증</div>
              <div>2018.10 2018 서울창업박람회 Tech Demoday 3위 수상</div>
              <div>2018.06 법인 설립</div>
              <div>2016년 하우투메리 마케팅 팀장</div>
              <div>2013~2016년 굿웨이브 CMO, 기업 EXIT</div>
              <div>2012년 인포뱅크 미디어사업부 대리</div>
              <div>2011년 CJ E&M 영화/방송 사업부 매니저</div>
              </div>
            </div>
          </div>
          <div className="text-xl GmaarketM mt-24">4. 차별점</div> 
          <div className="ml-5 mb-24 text-xl mt-3">
            <div>1. 우리 수업에 낙오자는 없을것 입니다 </div>
            <div className="ml-4"> (마지막 날에 1박 2일 해커톤으로 사업아이템이 나올때까지 못빠져나갑니다. )</div>
            <div>2. 기업가 정신 교육 - 우리의 생각이 항상 사업가 관점 생각으로 바뀌게 될것입니다.</div>
            <div>3. 실천 중심 교육 - 듣게 하는게 아니라 하게 할것입니다.</div>
          </div>
          <div className="text-xl GmaarketM mt-24">5. 특별혜택</div> 
          <div className="ml-5 mb-24 text-xl mt-3">
            <div>1기 수강생에 한해, 예비창업가 과정 수강료를 50%할인해드립니다.</div>
            <div>추후 오프라인 해커톤 시, 초대해드립니다. ( 지방에서도 해커톤 진행 계획 ) </div>
          </div>
          <div className="text-xl GmaarketM mt-24">6. 강의 날짜/시간</div> 
          <div className="ml-5 mb-24 text-xl mt-5">
            <div className="text-blue-700">1) 매주 목요일 오후 7시 ~ 9시  ( 줌 ) : 교육을 듣고 </div>
            <div className="mt-5 ml-5">
              <div>아이디어를 찾는 방법(PSM)을 배우게 됩니다.</div>
              <div>방법이 이해 되도록 다양한 사례와 예시를 배웁니다.</div>
              <div>오랫동안 흔들리지 않도록 기업가정신을 배웁니다.</div>
              <div>툴 사용을 통해 아이디어를 이미지화합니다</div>
            </div>
            <div className="text-blue-700 mt-5">2) 매주 목요일 오후 9시 ~ 11시 ( 메타버스- 게더타운 ) : 그날 바로 실천합니다.</div>
            <div className="mt-5 ml-5">
              <div>수업이 이해되었는지 스스로 창업일지를 작성합니다.</div>
              <div>자기소개, 아이디어 발표, 사례 발표등 다양한 발표를 진행합니다.</div>
              <div>다른 사람의 아이디어에 살을 붙이고, 토론에 참여합니다.</div>
              <div>자신의 아이디어를 발표하고, 토론합니다.</div>
            </div>
          </div>
          <div className="text-xl GmaarketM mt-24">7. 비용</div> 
          <div className="ml-5 mb-48 text-xl mt-3 ">
            <div>1기에 한해 80% 할인된 금액으로 말도 안되는 퀄리티의 교육을 들을 수 있습니다.</div>
            <div>55만원 → 11만원 ( 온라인 강의보다 낮은 금액으로 맞춤형 피드백을 들을 수 있습니다. )</div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <MastPaymentFooter />
    </>
  )
}