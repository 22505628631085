import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LevelStatus } from "./Levelstatus"
import Boat from '../images/boat.gif';
import { GET_PSM_QUERY, COMMENT_PSM_MUTATION } from "./logbook2";
import { getBM } from "../__generated__/getBM";
import { commentBMMutation, commentBMMutationVariables } from "../__generated__/commentBMMutation";


interface IForm {
  understand_3: string;
  question_3: string;
  question3_1: string;
  question3_2: string;
  question3_3: string;
  question3_4: string;
  question3_5: string;
  question3_6: string;
}

export const COMMENT_BM_MUTATION = gql`
  mutation commentBMMutation($commentBMInput: commentBMInput!) {
    commentBM(input: $commentBMInput) {
      ok,
      error
    }
  }
`;

export const GET_BM_QUERY = gql`
  query getBM {
    getBM {
      ok
      error
      bms {
        understand_3,
        question_3,
        question3_1,
        question3_2,
        question3_3,
        question3_4,
        question3_5,
        question3_6,
      }
    }
  }
`;

export const LogBook4 = () => {
  const [step, setStep] = useState(1);
  const { data } = useQuery<getBM>(GET_BM_QUERY);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  const temporarySave = () => {
      alert('임시저장 되었습니다.')
      // commentPSMMutation({
      //   variables: {
      //     commentPSMInput: {
      //       problem,
      //       solution,
      //       BM
      //     }
      //   }
      // });
  }
  const previous = () => {
    setStep(step-1);
  }
  const onCompleted = (data: commentBMMutation) => {
    const {
      commentBM: { ok, error },
    } = data;

    if (ok) {
      alert('BM 작성완료!')
      window.location.reload();
    } else {
      alert(error)
    }
  };
  const [ commentBMMutation, { data: commentBMMutationResult, loading } ] = useMutation<commentBMMutation, commentBMMutationVariables>(COMMENT_BM_MUTATION, { onCompleted});
  const onSubmit = () => {
    const { understand_3, question_3, question3_1, question3_2, question3_3, question3_4, question3_5, question3_6 } = getValues();
    if( step === 8 ) {
      if((understand_3 && question_3 && question3_1 && question3_2 && question3_3 && question3_4 && question3_5 && question3_6 )) {
        alert('제출되었습니다.')
        commentBMMutation({
          variables: {
            commentBMInput: {
              understand_3,
              question_3,
              question3_1,
              question3_2,
              question3_3,
              question3_4,
              question3_5,
              question3_6,
              
            }
          }
        });
        
      } else {
        alert('모든 질문에 답변해주세요.')
      }
    } else {
      setStep(step +1);
    }
  }
  const inValid = () => {
    console.log(errors)
  }


  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap " >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        {/* <div className="text-4xl GmarketM transition-opacity text-center mb-12" style={{width:" 500px"}}>3일차 항해일지</div> */}
        {/* <div className="text-lg GmarketM transition-opacity text-center mb-20 text-red-400" style={{width:" 500px"}}>수강생에 한해서 강사님의 구체적인 피드백을 받을 수 있습니다.</div> */}
        <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
        {step ===1 && 
          <div className=" mt-10">
            <label htmlFor="understand_3" className=" text-lg font-medium text-gray-700 ">
              1.  비즈니스 모델 이해 되셨나요? (1-10)
            </label>
            <div className="mt-1">
              <textarea
              {...register("understand_3", {
                required: "이름을 입력해주세요",
              })}
                id="understand_3"
                name="understand_3"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.understand_3 : ''}
              />
            </div>
          </div>
        }
        {step ===2 && 
          <div className="mt-20">
            <label htmlFor="question_3" className=" text-lg font-medium text-gray-700">
              2. 이해가 되지 않는다면 어떤 점이 이해되지 않았나요? (간단히 기록해주세요)
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question_3", {
                  required: "이름을 입력해주세요",
                })}
                id="question_3"
                name="question_3"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.question_3 : ''}
              />
            </div>
          </div>
        }
        {step ===3 && 
          <div className="mt-20">
            <label htmlFor="question3_1" className=" text-lg font-medium text-gray-700">
              3. 비즈니스 모델이 무슨말인가요? 스스로 정의해봅시다.
            </label>
            <p className="mt-2 text-sm text-gray-500">- (수업중에 나온 말로 정의해도 됩니다. ex 돈을 버는 다양한 방법)</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question3_1", {
                  required: "이름을 입력해주세요",
                })}
                id="question3_1"
                name="question3_1"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.question3_1 : ''}
              />
            </div>
          </div>
        }
        {step === 4 &&  
          <div className="mt-20">
            <label htmlFor="question3_2" className=" text-lg font-medium text-gray-700">
              4. 기업을 하나 정해보세요. 기업의 비즈니스 모델을 찾아봅시다.
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 쿠팡 - 판매 수수료, 직접판매, 택배배송등</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question3_2", {
                  required: "이름을 입력해주세요",
                })}
                id="question3_2"
                name="question3_2"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.question3_2 : ''}
              />
            </div>
          </div>
        }
        {step ===5 && 
          <div className="mt-20">
            <label htmlFor="question3_3" className=" text-lg font-medium text-gray-700">
              5. 위 기업이 가장 먼저 돈을 버는 방법은 무엇이었나요?
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 쿠팡 - 쿠폰판매</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question3_3", {
                  required: "이름을 입력해주세요",
                })}
                id="question3_3"
                name="question3_3"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.question3_3 : ''}
              />
            </div>
          </div>
        }
        {step === 6 && 
          <div className="mt-20">
            <label htmlFor="question3_4" className=" text-lg font-medium text-gray-700">
              6. 위 기업에 ceo가 나라면 어떤 사업에 해보고 싶으신가요??
            </label>
            <p className="mt-2 text-sm text-gray-500">- (창업 비용이 많다면 하고 싶은 창업 아이템이라고 생각하면 돼요)</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question3_4", {
                  required: "이름을 입력해주세요",
                })}
                id="question3_4"
                name="question3_4"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.question3_4 : ''}
              />
            </div>
          </div>
        }
        {step === 7 && 
          <div className="mt-20">
            <label htmlFor="question3_5" className=" text-lg font-medium text-gray-700">
              7. 그 아이디어는 어떻게 돈을 벌수 있나요?
            </label>
            <p className="mt-2 text-sm text-gray-500">- (위 아이디어(창업아이템)에서 가장 먼저 돈을 버는 방법은 무엇인가요?)</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question3_5", {
                  required: "이름을 입력해주세요",
                })}
                id="question3_5"
                name="question3_5"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.question3_5 : ''}
              />
            </div>
          </div>
        }
        {step === 8 && 
          <div className="mt-20">
            <label htmlFor="question3_6" className=" text-lg font-medium text-gray-700">
              8. 위 아이디어를 지금 하지 못하는 이유는 무엇인가요?
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question3_6", {
                  required: "이름을 입력해주세요",
                })}
                id="question3_6"
                name="question3_6"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getBM?.bms ? data.getBM?.bms[0]?.question3_6 : ''}
              />
            </div>
          </div>
        }
        <div className="flex justify-center mt-12">
          {/* <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            임시 저장
          </button> */}
          {step !== 1 &&
            <button
              type="button"
              onClick={previous}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
            >
              이전
            </button>
          }
          <button
            type="button"
            onClick={temporarySave}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {'임시저장'}
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {step === 8 ? '제출' : '다음'}
          </button>
        </div>
        </form>
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}