import { Footer } from "../components/footer"
import { Header2 } from "../components/header2"


export const Terms = () => {
  return (
    <>
      <Header2 page={'terms'}/>
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl mt-20">
        
        <p>안녕하세요. 마스트벤처스입니다.</p>

        <p>마스트벤처스 이용약관(이하 ‘이 약관’)은 마스트벤처스 서비스 이용과 관련한 회사와 회원 간의 권리와 의무에 대한 내용을 담고 있습니다. 다만, 회사는 다양한 서비스를 제공하는 과정에서 이 약관 외에 개별 약관 또는 정책 등을 적용할 수 있습니다.</p>

        <p>회사는 회원의 권리를 보호하기 위하여 위와 같은 내용을 쉽게 알 수 있도록 작성하여 명확하게 표시하고 있습니다.</p>

        <p>마스트벤처스 서비스 이용 전 꼭 확인하여 주세요 </p>


        <p>1. 용어의 뜻</p>

        <p>이 약관에서 사용하는 용어의 뜻은 다음과 같습니다. 다만, 별도로 정의되지 않은 용어 중 법령상 용어는 해당 법령상의 정의를 따르고, 일반적인 거래상 용어는 거래관행에 따릅니다.</p>
        <p>‘마스트벤처스’이란 회사가 정보통신망을 이용하여 회원에게 클래스콘텐츠 및 상품을 판매하거나 또는 판매를 중개하는 서비스 플랫폼을 말합니다.</p>
        <p>‘서비스’란 회사가 마스트벤처스를 통하여 회원에게 유료 또는 무료로 제공하는 서비스 일체를 말합니다.</p>
        <p>‘클래스콘텐츠’란 지식, 정보 등을 전달하기 위하여 만든 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ 음향ㆍ이미지 및 영상 등(이들의 복합체를 포함한다)의 자료 또는 정보를 말합니다.</p>
        <p>‘상품’이란 회원에게 유상 또는 무상으로 제공하는 유형 또는 무형의 재화를 말합니다.</p>
        <p>‘회원’이란 마스트벤처스를 이용하기 위하여 이 약관에 동의하고 회원가입절차를 마친 후 회사로부터 회원가입의 승낙을 받은 개인 또는 단체를 말합니다.</p>
        <p>‘회원가입’이란 회원이 회사와의 사이에 이 약관의 내용에 따라 마스트벤처스 이용계약을 체결하는 것을 말합니다.</p>
        <p>‘회원탈퇴’란 회원이 회사와의 사이에 이 약관의 내용에 따라 마스트벤처스 이용계약을 해지하는 것을 말합니다.</p>
        <p>‘회원정보’란 회원이 회사에 대하여 회원가입 시 제공하는 정보 일체를 말합니다.</p>
        <p>‘계정’이란 회원이 마스트벤처스에 접속하여 서비스를 이용하기 위하여 필요한 정보의 집합체로서 전자우편주소와 비밀번호 등의 정보 일체를 말합니다.</p>
        <p>‘크리에이터’란 클래스콘텐츠 제작에 있어 그 과정의 전체를 기획하고 책임을 지는 개인 또는 단체를 말합니다.</p>



        <p>2. 서비스 이용</p>

        <p>2.1 회사는 회원에게 클래스콘텐츠, 상품 등의 판매 또는 판매중개를 포함하여 지식과 정보를 제공할 수 있는 다양한 서비스를 제공합니다.</p>

        <p>2.2 회원은 컴퓨터, 휴대전화 등 정보통신기기를 사용하여 서비스를 이용할 수 있으며, 개별 서비스의 구체적인 내용과 이용 조건은 개별 약관 및 정책, 공지사항, 서비스 초기화면 등에서 확인할 수 있습니다.</p>

        <p>2.3 회사는 회원 모두에게 기본적으로 동일한 내용의 서비스를 제공합니다. 다만, 「청소년 보호법」 등 관련 법령 또는 개별 서비스 제공 관련한 특별한 사정에 따라 연령 또는 일정한 등급을 기준으로 이용자를 구분하여 서비스를 제공하거나 서비스의 내용, 이용 시간, 이용 횟수 등을 다르게 하는 등 서비스 이용을 제한할 수 있습니다. 이와 관련한 자세한 내용은 각 서비스상의 안내, 공지사항 등에서 확인할 수 있습니다.</p>

        <p>2.4 회사는 회원이 이 약관에 위배되는 행위를 하는 경우 서비스 제공을 중단할 수 있습니다.</p>


        <p>3. 회원가입과 회원탈퇴</p>

        <p>3.1 회원가입은 회원이 이 약관에 따라 회사에게 회원가입을 신청하고 회사로부터 회원가입에 대한 승낙을 받은 때 완료됩니다. 이와 관련하여 회사는 회원의 실명을 확인하는 절차를 진행할 수 있습니다.</p>

        <p>3.2 회사는 기본적으로 회원이 이 약관에 따라 신청한 회원가입을 승낙합니다. 다만, 아래와 같은 경우 회사는 회원가입의 승낙을 연기하거나 거부할 수 있습니다.</p>
        <p>회원가입에 필수적으로 요구되는 정보의 제공을 거절한 경우</p>
        <p>타인의 정보 또는 허위의 정보를 입력하여 회원가입을 신청한 경우</p>
        <p>사회질서를 파괴할 목적으로 회원가입을 신청한 경우</p>
        <p>관련 법령, 이용약관 등의 위반을 이유로 계정 이용이 정지된 경우</p>
        <p>회사의 영업을 방해하거나 회사에 대하여 손해를 가하였거나, 그러한 목적으로 회원가입을 신청하였다고 의심할 만한 사유가 있는 경우</p>
        <p>영리목적으로 회원가입을 신청한 경우</p>
        <p>기타 물리적ㆍ기술적 사유로 회원가입을 승낙할 수 없는 경우</p>


        <p>3.3 회원은 언제든지 이 약관에 따라 회원탈퇴를 신청할 수 있으며 회사는 기본적으로 회원이 신청한 회원탈퇴를 승낙합니다. 다만, 아래의 어느 하나에 해당하는 경우 회사는 회원탈퇴의 승낙을 거절하거나 보류할 수 있습니다.</p>
        <p>회원이 회사에 대하여 이행하여야 하는 법률상 의무가 존재하는 경우</p>
        <p>회원과 회사 사이에 분쟁이 발생하거나 발생할 가능성이 상당한 경우</p>

        <p>3.4 회사는 아래의 어느 하나에 해당하는 경우 회원의 신청 여부와 관계없이 회원탈퇴를 진행할 수 있습니다.</p>
        <p>회원이 개정 약관에 대하여 동의하지 아니하고, 해당 회원에게 개정 전 약관을 적용할 수 없는 사정이 있는 경우</p>

        <p>3.5 회사는 회원탈퇴 시 회원으로부터 제공받은 정보를 관계 법령과 개인정보 처리방침 등에 따라 삭제 또는 파기하거나 일정 기간동안 보관합니다. 다만, 회원이 회원탈퇴 시까지 작성한 게시물은 삭제되지 않으며 회원탈퇴 후 회사에 대하여 그 게시물의 삭제를 요구할 수 없습니다.</p>


        <p>4. 회원의 권리와 의무</p>

        <p>4.1 회원은 대한민국 법령과 이 약관(개별 약관과 정책을 포함)을 준수하여야 합니다.</p>

        <p>4.2 회원은 회사가 서비스를 통하여 제공하는 서비스를 유료 또는 무료로 이용할 수 있습니다. 회사는 유료서비스의 구체적인 내용과 이용조건을 서비스 초기화면에 게시합니다.</p>

        <p>4.3 회원은 상시적으로 서비스를 이용할 수 있습니다. 다만, 회사는 아래의 사유가 발생한 경우 회원의 마스트벤처스 이용을 일시적으로 중단할 수 있습니다.</p>
        <p>전기통신설비의 점검, 보수, 교체</p>
        <p>정보통신망의 장애</p>
        <p>기타 법적ㆍ기술적 장애</p>


        <p>4.4 회원은 계정을 관리하고 계정에 관한 내용을 제3자에게 제공하거나 유출되게 하여서는 아니 됩니다.</p>

        <p>4.5 회원은 클래스콘텐츠를 포함한 일체의 서비스를 회원 본인만이 이용할 수 있고 제3자에게 이용권한을 제공(담보, 신탁 등의 설정을 포함)하거나 이용을 허락할 수 없습니다.</p>

        <p>4.6 회원은 서비스에 포함되어 있거나 서비스를 통하여 취득한 클래스콘텐츠와 정보를 아카이브, 복제, 배포, 수정, 전시, 시연, 출판, 라이선스, 2차적 저작물로 생성, 판매 또는 권유하거나 이용할 수 없습니다.</p>

        <p>4.7 회원은 서비스 내 콘텐츠보호기능을 우회, 삭제, 수정, 무효화, 약화 또는 훼손하거나, 서비스에 접근하는 데 로봇, 스파이더, 스크레이퍼나 기타 자동화 수단을 이용하거나, 서비스를 통하여 접근 가능한 소프트웨어나 기타 제품, 프로세스를 역컴파일, 리버스 엔지니어링 또는 역어셈블하거나, 코드나 제품을 삽입하거나 어떤 방식으로든 서비스를 조작하거나, 데이터 마이닝, 데이터 수집 또는 추출 방법을 사용할 수 없습니다.</p>

        <p>4.8 회원은 컴퓨터바이러스 기타 컴퓨터 코드, 파일이나 프로그램을 포함하여, 서비스와 관련된 컴퓨터 소프트웨어나 하드웨어 또는 통신 장비의 기능을 방해, 파괴 또는 제한하기 위해 설계된 자료를 업로드, 게시, 이메일 전송, 또는 다른 방식으로 발송, 전송할 수 없습니다.</p>

        <p>4.9 회원은 서비스 내에서 게시물을 작성할 수 있습니다. 다만, 회원이 작성한 게시물이 아래의 어느 하나에 해당하는 표현을 포함하는 경우 회사는 삭제를 요청하거나 삭제할 수 있습니다.</p>
        <p>대한민국 또는 외국의 법령에 위배되는 표현</p>
        <p>대한민국 또는 외국에서의 범죄와 관련되는 표현</p>
        <p>회원 또는 제3자의 영리를 목적으로 한 표현</p>
        <p>음란성ㆍ포악성ㆍ잔인성ㆍ사행성 등이 포함된 표현</p>
        <p>사회질서를 파괴하는 표현</p>
        <p>사실 또는 허위사실의 적시에 의하여 회사 또는 제3자의 명예를 훼손하는 표현</p>
        <p>회사 및 제3자의 지식재산을 침해하는 표현</p>
        <p>개인의 인권 또는 평등권을 침해하는 차별적 표현</p>


        <p>4.10 회원은 마스트벤처스 이용과 관련하여 아래와 같은 행위를 할 수 없습니다. 그럼에도 불구하고 회원이 다음과 같은 행위를 하는 경우 회사는 회원에 대하여 게시물 삭제, 계정의 일시 또는 영구 이용정지, 손해배상 청구, 형사 고소 또는 고발 등의 조치를 취할 수 있습니다.</p>
        <p>대한민국 또는 외국의 법령을 위반하는 행위</p>
        <p>이 약관(개별 약관 또는 정책을 포함)을 위반하는 행위</p>
        <p>마스트벤처스를 직접 또는 간접적으로 이용한 불법행위</p>
        <p>부정한 정보를 입력하여 회원가입을 진행하는 행위</p>
        <p>위계, 위력 등의 방법으로 회사의 업무를 방해하는 행위</p>
        <p>위계, 위력 등의 방법으로 회원의 서비스 이용을 방해하는 행위</p>
        <p>타인의 개인정보 또는 결제수단을 도용하는 행위</p>
        <p>본인의 계정 또는 결제수단을 제3자에게 제공(담보, 신탁 등의 설정을 포함)하거나 이용을 허락하는 행위</p>
        <p>회사 또는 제3자의 지식재산을 침해하는 행위</p>
        <p>회사 또는 제3자의 명예 또는 신용을 훼손하는 행위</p>
        <p>음란성ㆍ포악성ㆍ잔인성ㆍ사행성 등이 포함된 정보를 게시하는 행위</p>
        <p>개인의 인권 또는 평등권을 침해하는 차별행위</p>
        <p>회사의 사전 허락 없이 본인 또는 제3자의 영리목적으로 마스트벤처스를 이용하는 행위</p>
        <p>제3자의 정보를 무단으로 수집하는 행위</p>
        <p>회사가 운영하는 서비스를 훼손하거나 방해하거나 전서버에 과부하를 가져오는 행위</p>
        <p>회사가 정한 정보 이외의 정보(컴퓨터 프로그램)의 송신 또는 게시</p>



        <p>5. 계정의 이용정지</p>

        <p>5.1 회사는 이 약관에 따라 회원의 계정 이용을 일시적 또는 영구적으로 정지할 수 있습니다.</p>

        <p>5.2 회사는 회원의 계정의 이용정지 시작일로부터 1주 전 이를 회원에게 통지합니다. 다만, 회사는 부득이한 경우 통지기간을 단축할 수 있고 사후적으로 이용정지의 통지를 할 수 있습니다.</p>

        <p>5.3 회사는 계정의 이용이 정지된 회원이 게시한 정보의 전부 또는 일부를 삭제할 수 있습니다.</p>


        <p>6. 지식재산의 보호</p>

        <p>6.1 회사가 마스트벤처스를 통하여 회원에게 제공하는 클래스콘텐츠 등의 저작물은 회사 또는 제3자의 지식재산으로서 대한민국과 외국의 법령에 의하여 보호됩니다.</p>

        <p>6.2 회사는 회원과 제3자의 소중한 지식재산을 보호하기 위하여 노력합니다. 만일 회사가 회원의 지식재산을 침해한 경우 회사는 관련 법령에 따라 회원이 입은 손해를 배상합니다.</p>

        <p>6.3 회원이 마스트벤처스에 정보를 게시하는 경우 회사 또는 제3자는 그 정보를 회사 또는 마스트벤처스의 홍보, 마케팅 등 영리적 목적으로 이용할 수 있습니다. 다만, 회원이 회사에게 그 이용에 대한 반대의사를 통지하는 경우 회사는 그 이용을 중단합니다.</p>

        <p>6.4 회사는 영업상 필요에 따라 회원이 게시한 정보의 전부 또는 일부를 수정 또는 삭제하거나 게시장소를 변경할 수 있습니다.</p>


        <p>7. 개인정보보호</p>

        <p>7.1 회사는 회원의 소중한 개인정보를 보호하기 위하여 「개인정보 보호법」에 따라 개인정보 처리방침을 수립하고 이를 준수하고 있습니다. 회원은 개인정보 처리방침을 마스트벤처스에서 확인할 수 있습니다.</p>

        <p>7.2 회사는 개인정보 처리방침에 따라 회원의 개인정보를 수집하고 이용할 수 있습니다. 다만, 회원은 언제든지 개인정보 제공을 거부하거나 개인정보의 삭제를 요청할 수 있습니다.</p>

        <p>7.3 회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 따라 회원에게 문자메시지(SMS), 전자우편(SMS) 기타 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송할 수 있습니다. 이 경우 회사는 회원에게 광고 수신을 거절할 수 있는 방법을 회원에게 함께 제공하며 회원은 광고 수신을 거절할 수 있습니다.</p>


        <p>8. 약관의 해석</p>

        <p>8.1 이 약관에 명시되지 않은 사항은 「약관의 규제에 관한 법률」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업 진흥법」 등 관계 법령과 개인정보 처리방침 등 지침 등에 의합니다.</p>

        <p>8.2 회사는 이 약관을 신의성실의 원칙에 따라 공정하게 해석하며 고객에 따라 다르게 해석하지 아니합니다. 또한 이 약관의 내용 중 뜻이 명백하지 않은 부분은 고객에게 유리하게 해석합니다.</p>

        <p>8.3 회사는 마스트벤처스를 구성하는 개별 서비스에 관하여 개별 약관 또는 정책을 운영할 수 있습니다. 이 경우 개별 약관 또는 정책은 이 약관과 동일하게 회원에게 효력을 가지며 이 약관과 상호 간에 모순이 있는 경우 개별 약관 또는 정책이 우선적으로 효력을 가집니다.</p>


        <p>9. 회사의 책임과 면책</p>

        <p>9.1 회사의 귀책사유로 인하여 회원이 손해를 입은 경우 회사는 회원에게 손해를 배상합니다. 다만, 손해배상은 회원이 실제로 입은 손해를 한도로 합니다.</p>

        <p>9.2 회사가 통신판매중개를 하는 경우 회사는 통신판매의 당사자가 아니며 회사는 회원과 통신판매중개의뢰자 간의 거래에 대하여 책임을 부담하지 아니합니다. 다만, 회사는 회원에게 「전자상거래 등에서의 소비자보호에 관한 법률」에 따라 통신판매중개의뢰자에 관한 정보를 제공합니다.</p>

        <p>9.3 회사는 서비스의 적법성, 정확성, 진실성, 신뢰성, 유효성 등을 보증하지 아니합니다.</p>

        <p>9.4 회사는 회원에게 유료 또는 무료로 제공하는 상품에 대하여 「제조물 책임법」에 따른 제조물 책임을 부담하지 아니합니다. 다만, 회원이 회사에게 상품의 제조업자 또는 공급한 자에 관한 정보를 요청하는 경우 회사는 상당한 기간 내에 그 정보를 회원에게 제공합니다.</p>

        <p>9.5 회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 따라 회원정보를 포함한 마스트벤처스 데이터 일체에 관하여 보안에 필요한 기술적 조치를 취하고 있습니다. 다만, 회사는 위와 같은 기술적 조치에도 불구하고 제3자가 해킹, 컴퓨터바이러스 등의 방법으로 회사의 정보통신망 또는 이와 관련된 정보시스템을 공격하는 행위를 하여 발생한 사태로 인하여 회원에게 발생한 손해에 대하여는 책임을 부담하지 아니합니다.</p>

        <p>9.6 회사는 마스트벤처스를 구성하는 데이터의 무결성을 보증하지 아니합니다.</p>

        <p>9.7 회사는 아래의 어느 하나에 해당하는 경우에 대하여서는 책임을 부담하지 않습니다.</p>
        <p>회원의 귀책사유에 의하여 발생한 손해</p>
        <p>회사의 귀책사유 또는 구체적인 피해사실이 증명되지 않은 손해</p>
        <p>회사가 통신판매중개자로서 중개한 거래에서 통신판매자와 회원 간에 발생한 분쟁과 관련한 손해</p>
        <p>서비스의 적법성, 정확성, 진실성, 신뢰성, 유효성 등과 관련하여 발생한 손해</p>
        <p>회원에게 공급된 상품에 관한 「제조물 책임법」상의 제조물 책임과 관련한 손해</p>
        <p>천재지변, 쟁의행위, 감염병 등 회사가 통제하기 어려운 사정으로 인하여 발생한 손해</p>
        <p>회원 간 또는 크리에이터와 회원 간의 분쟁으로 인하여 발생한 손해</p>



        <p>10. 약관의 개정</p>

        <p>10.1 회사는 「약관의 규제에 관한 법률」, 「전자상거래 및 소비자보호에 관한 법률」 등 관계 법령을 위배하지 아니하는 범위 내에서 이 약관을 개정할 수 있습니다.</p>

        <p>10.2 회사가 이 약관을 개정하는 경우 회원에게 주요 개정내용과 시행일 등을 명시하여 시행일로부터 7일 전에 전자적 방법으로 알려드리겠습니다. 다만, 회원에게 불리한 내용이 포함되는 경우 시행일로부터 30일 전에 알려드리겠습니다.</p>

        <p>10.3 회사는 이 약관을 개정할 경우 회원에게 개정 약관에 대한 동의를 확인합니다. 회원은 이에 대하여 동의하거나 거부할 수 있으며 회사는 회원이 7일 이내 동의 여부를 표시하지 않는 경우 동의한 것으로 간주할 수 있습니다. 다만, 회원이 개정 약관에 동의하지 않는 경우 회원탈퇴절차가 진행될 수 있습니다.</p>


        <p>11. 통지와 공고</p>

        <p>11.1 회사는 이 약관에 따른 통지를 회원으로부터 제공받은 전자우편주소, 전화번호 등을 통하여 전자적 방법으로 통지합니다.</p>

        <p>11.2 회사는 불특정 다수의 회원을 대상으로 하는 통지를 갈음하여 마스트벤처스에 1주 이상 전자적 방법으로 공고할 수 있습니다. 다만, 회원의 서비스 이용과 관련하여 중대한 영향을 미치는 사항은 개별적으로 통지합니다.</p>


        <p>12. 준거법 및 재판관할</p>

        <p>12.1 이 약관의 해석과 적용은 대한민국 법령에 의합니다.</p>

        <p>12.2 회사와 회원 간에 분쟁이 발생한 경우 그 분쟁에 관한 재판관할은 「민사소송법」에 의합니다. 다만, 회원이 외국에 거주하는 경우 대한민국 서울중앙지방법원을 제1심의 전속적 관할법원으로 합니다.</p>


        <p>마스트벤처스 서비스와 관련하여 궁금하신 사항이 있으시면 고객센터(대표번호: 070-5100-5577 / 평일 10:00~17:00)로 문의 주시기 바랍니다.</p>
      </div>
      <Footer />
    </>
  )
}