import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Design from '../images/design3.png';


interface ProgramCardProps {
  reviewPosts: any
}


export const Regular1LectureCard:React.FC<ProgramCardProps> = ({reviewPosts}) => {
  const moveToDetail = () => {
    alert('배 설계 및 조립반 과정은 12월에 시작될 예정입니다.')
  }
  return (
    <div key={reviewPosts.id} style={{backgroundImage: `url(${Design})`, backgroundSize: '100% 41%', backgroundRepeat:"no-repeat", height:"490px"}} 
      className="transform  hover:scale-105 p-5 border-main cursor-pointer bg-white  bg-opacity-50 shadow-lg h-96 border-2 rounded-2xl border-transparent border-opacity-50 hover:border-opacity-100"
      onClick={moveToDetail}
    >
      <div className="w-full mt-3 text-main GmarketB text-2xl">2. 배 설계 및 제작 </div>
      <div className="w-full ml-2 text-main GmarketB text-2xl">(예비창업단계)</div>
      <div className="w-8/12 mt-5 text-main GmarketM text-lg max-w-lg">아이디어를 구체화 하고, 직접 실행해봅니다.</div>
      <div className="mt-12 text-black GmarketB text-2xl">"3달간 빡셀겁니다."</div>
      <div className="mt-4 text-black GmarketL text-sm">- 배우고, 정리하고, 발표합니다.</div>
      <div className="text-black GmarketL text-sm">- 실현 가능한 것들을 실행합니다.</div>
      <div className="text-black GmarketL text-sm">- 간단한 문서와 PPT를 제작합니다.</div>
      <div className="text-black GmarketL text-sm">- 초기 고객을 만나봅니다.</div>
      <div className="text-black GmarketL text-sm">- 밤샘(지옥주간)을 경험합니다.</div>
      <div className="grid justify-items-center">
        <button className="mt-12 text-white GmarketM text-sm px-6 py-2 shadow-lg rounded-3xl border justify-center bg-main"><Link to={'/program/idea'}>수강신청 하러가기</Link></button>
      </div>
    </div>
  )
}