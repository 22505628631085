import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Boat from '../images/boat.png';


interface ProgramCardProps {
  reviewPosts: any
}


export const SubRegular2LectureCard:React.FC<ProgramCardProps> = ({reviewPosts}) => {
  const moveToDetail = () => {
    alert('항해 정규반은 내년 3월에 시작될 예정입니다.')
  }
  return (
    <div key={reviewPosts.id} style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% 41%', backgroundRepeat:"no-repeat", height:"490px"}} 
      className="transform hover:scale-105 p-5 border-main cursor-pointer bg-white bg-opacity-50 shadow-lg h-96 border-2 rounded-2xl border-transparent border-opacity-50 hover:border-opacity-100"
      onClick={moveToDetail}
    >
      <div className="w-full mt-3 text-main GmarketB text-2xl">3. 초기창업단계</div>
      {/* <div className="w-full ml-2 text-main GmarketB text-2xl">(초기창업단계)</div> */}
      <div className="w-8/12 mt-6 text-main GmarketM text-lg max-w-lg font-bold">예비 단계보다는 더 깊게 고객을 만나봅시다.</div>
      <div className="mt-20 text-black GmarketB" style={{fontSize:"1.42rem"}}>"3년을 3개월만에 해봅시다."</div>
      <div className="mt-4 text-black GmarketL text-sm">- 배우고, 정리하고, 발표합니다.</div>
      <div className="text-black GmarketL text-sm">- 사업계획서와 발표용 PPT도 제작합니다.</div>
      <div className="text-black GmarketL text-sm">- 팀원을 만나고 팀을 만들어봅시다.</div>
      <div className="text-black GmarketL text-sm">- 예산을 정해보고 년간 계획을 정합니다.</div>
      <div className="text-black GmarketL text-sm">- 너무 행복해서 잠이 오지 않을겁니다..</div>
      <div className="text-black GmarketL text-sm">- 이제 당신의 사업이 시작됩니다.</div>
      <div className="grid justify-items-center">
        <button className="mt-7 text-white GmarketM text-sm px-6 py-2 shadow-lg rounded-3xl border justify-center bg-main"><Link to={'/program/idea'}>수강신청 하러가기</Link></button>
      </div>
    </div>
  )
}