import { useState } from "react"
import { useHistory } from "react-router"
import { Footer } from "../components/footer"
import { Header2 } from "../components/header2"
import { CheckIcon } from '@heroicons/react/solid'
import Design from '../images/shipdesign.png';
import Sailing from '../images/sailing.png';
import Reason1 from '../images/reason1.png';
import Reason2 from '../images/reason2.png';
import Graph from '../images/graph.gif';
import Hackerton from '../images/hackerton.gif';
import Bms from '../images/bms.gif';
import { VideoCard } from "../components/videoCard"
import Main from '../images/sailing.jpg';
import { MastPaymentFooter } from "../components/MastPaymentFooter"

const steps = [
  { name: '1주차', description: '창업아이디어', href: '#', status: 'current' },
  {
    name: '2주차',
    description: 'PSM 작성하기',
    href: '#',
    status: 'current',
  },
  { name: '3주차', description: 'PW 작성하기', href: '#', status: 'current' },
  { name: '4주차', description: 'PB 작성하기', href: '#', status: 'current' },
  { name: '4.5주차', description: '해커톤', href: '#', status: 'hackerton' },
  { name: '5주차', description: 'PC 작성하기', href: '#', status: 'current' },
  { name: '6주차', description: 'MVP 제작', href: '#', status: 'MVP' },
  {
    name: '7주차',
    description: 'PSM 작성하기',
    href: '#',
    status: 'MVP',
  },
  { name: '8주차', description: 'MVP 제작', href: '#', status: 'MVP' },
  { name: '9주차', description: '마케팅', href: '#', status: 'MVP' },
  { name: '9.5주차', description: '해커톤', href: '#', status: 'hackerton' },
  { name: '10주차', description: '고객확보', href: '#', status: 'MVP' },
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export const Curriculum = () => {
  const history = useHistory();
  const [ paidstudent, setPaidstudent ] = useState(false);

  const checkPaidStudent = () => {
    if(localStorage.getItem('role') === 'PaidStudent') {
      setPaidstudent(true);
    } else {
      alert('결제한 수강생에 의해 보여집니다.')
      history.push('/program/idea/apply')
    }
  }
  return (
    <>
      <Header2 page={'curriculum'} />
      <div className="mx-auto max-w-md  px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="grid md:grid-cols-5 sm:max-w-7xl mx-auto ">
          <div className="col-span-3">
            <div className="w-11/12 sm:max-w-7xl mx-auto text-black sm:pl-6 pt-10 sm:pt-40 pb-12 text-2xl sm:text-4xl GmarketB">창업은 항해와 같습니다.</div>
            <div className="w-11/12 sm:max-w-7xl mx-auto text-black sm:pl-6 pb-10 text-base  sm:text-xl GmarketL">
              <p>우리는 출항을 할때는 전혀 예측조차 못했던  </p>
              <p>거친 폭풍우를 만나 배를 잃기도 하고, </p>
              <p>방향감각을 잃어 목적지와 다른 방향으로 가기도 합니다. </p> <br />
              <p className="text-red-500 GmarketM md:text-xl lg:text-2xl">마스트벤처스가<br /> 목적지까지 갈 수 있는 돛이 되어 드리겠습니다.</p><br />
              <p className="font-extrabold GmarketM md:text-lg lg:text-xl">( 이 배의 선장은 본인이라는 것을 절대 잊지 마세요!! )</p>< br />
            </div>
          </div>
          <div className="col-span-2 sm:pt-40">
            <img src={Main} style={{width:'100%', height: "330px"}}></img>
          </div>
        </div>

        <p className="mt-20 md:mt-52 text-lg GmarketB font-extrabold tracking-tight sm:text-3xl text-center">마스트벤처스의 교육과정은 두가지입니다.</p>
        <div className="hidden sm:grid sm:grid-cols-2 md:mt-20">
          <div style={{backgroundImage: `url(${Design})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"400px", backgroundColor:"#e8e6e1", backgroundPosition: "center 40%"}} 
            className="relative group mr-5"
          ></div>
          <div style={{backgroundImage: `url(${Sailing})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"400px", backgroundColor:"#e8e6e1", backgroundPosition: "center 40%"}} 
            className="relative group ml-5"
          ></div>
          <div className="text-center text-base md:text-2xl GmarketM mt-1">목적지까지 갈 수 있는 배 설계과정 ( 선박 설계반 )</div>
          <div className="text-center text-base md:text-2xl GmarketM mt-1">목적지를 향해 가는 항해과정 ( 항해 정규반 )</div>
        </div>
        <div className=" sm:hidden md:mt-20">
          <div style={{backgroundImage: `url(${Design})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"300px", backgroundColor:"#e8e6e1", backgroundPosition: "center 40%"}} 
            className="relative group "
          ></div>
          <div className="text-center text-sm md:text-2xl GmarketM mt-1">목적지까지 갈 수 있는 배 설계과정 ( 선박 설계반 )</div>
          <div style={{backgroundImage: `url(${Sailing})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"400px", backgroundColor:"#e8e6e1", backgroundPosition: "center 40%"}} 
            className="relative group mt-5"
          ></div>
          <div className="text-center text-base md:text-2xl GmarketM mt-1">목적지를 향해 가는 항해과정 ( 항해 정규반 )</div>
        </div>


        <p className="mt-60 text-2xl GmarketB font-extrabold tracking-tight sm:text-3xl text-center">마스트벤처스의 항해스킬</p>
        
        <p className="mt-4 text-md GmarketL text-gray-900 tracking-tight sm:text-md text-center">'어디에서도 볼 수 없는 마스트벤처스의 스파르타 창업실천교육'</p>
        <div className=" sm:max-w-6xl mx-auto grid sm:grid-cols-2">
          <div className="mx-auto mt-20 sm:pl-20 md:col-span-1 md:mt-40">
            <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">1. 실천 중심 실전 수업</div>
            <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
            <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">온라인 강의를 듣는다고 성장할까요?</div>
            <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">잘 배우는 것도 중요하지만 실천하는 게 더 중요합니다.</div>
            <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">우리는 배우고, 실천하고, 점검합니다.</div>
          </div>
          <div className="sm:col-span-1 hidden sm:grid sm:justify-items-end" style={{backgroundImage: `url(${Graph})`, backgroundSize: '100% 100%', backgroundRepeat:"no-repeat", height:"450px"}}  ></div>
          <div className="sm:col-span-1  sm:hidden" style={{backgroundImage: `url(${Graph})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"450px"}}  ></div>
        </div>
        <div className="my-20 max-w-6xl mx-auto grid md:grid-cols-2">
          <div className="pl-20 md:col-span-1 hidden sm:grid sm:justify-items-end ml-28" style={{backgroundImage: `url(${Hackerton})`, backgroundSize: '85% 100%', backgroundRepeat:"no-repeat", height:"360px"}}></div>
          <div className=" md:col-span-1 md:mt-24">
            <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">2. OOO 데이</div>
            <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
            <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">해커톤데이, 팁업데이, O2O데이, PSM데이 등</div>
            <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">1박2일 동안 리더도 되어보고, 팀원도 되어보며</div>
            <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">해결하지 못했던 문제 함께 해결해나갑니다.</div>
          </div>
          <div className="pl-20 md:col-span-1 sm:hidden" style={{backgroundImage: `url(${Hackerton})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"360px"}}></div>
        </div>
        <div className="my-20 max-w-6xl mx-auto grid md:grid-cols-2">
          <div className="sm:pl-28 md:col-span-1 md:mt-32">
            <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">3. 체계적인 성장 관리 (SGS)</div>
            <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
            <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">창업의 항해를 시작하면 물어볼 곳도 없고 의심만 쌓이고, 행동은 느려집니다. </div>
            <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">항해일지를 통해 정리하고, 정비하여 성장을 관리합니다.</div>
          </div>
          <div className="md:col-span-1 hidden sm:grid sm:justify-items-end ml-12 mt-12" style={{backgroundImage: `url(${Bms})`, backgroundSize: '70% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
          <div className="md:col-span-1 sm:hidden" style={{backgroundImage: `url(${Bms})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
        </div>
        <div className="my-36 max-w-6xl mx-auto grid md:grid-cols-2">
          <div className="pl-20 md:col-span-1 hidden sm:grid sm:justify-items-end" style={{backgroundImage: `url(${Reason2})`, backgroundSize: '85% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
          <div className=" md:col-span-1 md:mt-20">
            <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">4. 온오프라인 커뮤니티</div>
            <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
            <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">혼자서 갈 수도 있지만 함께 멀리 갑시다.</div>
            <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">항해일지를 정리하고, 토론과 발표를 통해 생각의 폭을 넓힙니다.</div>
          </div>
          <div className="pl-20 md:col-span-1 sm:hidden" style={{backgroundImage: `url(${Reason2})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
        </div>
        {/* <div className="grid grid-cols-3">
          <div className="grid  mt-20">
            <div>
              <div className="text-lg text-main mb-3">항해할 배를 설계하는 과정입니다.</div>               
              <img
                src={Boat}
                alt=""
                />
                
            </div>
          </div>
          <div className="grid justify-center">
          <nav aria-label="Progress">
          <ol role="list" className="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                {step.status === 'MVP' ? (
                  <>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="-ml-px absolute mt-0.5 top-7 left-3 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                  ) : null}
                  <a href={step.href} className="relative flex items-start group" aria-current="step">
                    <span className="h-20 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-6 h-6 flex items-center justify-center bg-white border-2 border-blue-700 rounded-full">
                        <span className="h-2.5 w-2.5  rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col mt-7">
                      <span className="text-base font-semibold tracking-wide uppercase text-blue-700">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                  </a>
                </>
                ) : step.status === 'current' ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-7 left-3 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                    ) : null}
                    <a href={step.href} className="relative flex items-start group" aria-current="step">
                      <span className="h-20 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-6 h-6 flex items-center justify-center bg-white border-2 border-main rounded-full">
                          <span className="h-2.5 w-2.5  rounded-full" />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col mt-7">
                        <span className="text-base font-semibold tracking-wide uppercase text-main">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </a>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div className="-ml-px absolute mt-0.5 top-7 left-3 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                    ) : null}
                    <a href={step.href} className="relative flex items-start group" aria-current="step">
                      <span className="h-20 flex items-center" aria-hidden="true">
                        <span className="relative z-10 w-6 h-6 flex items-center justify-center bg-white border-2 border-red-500 rounded-full">
                          <span className="h-2.5 w-2.5  rounded-full" />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col mt-7">
                        <span className="text-base font-semibold tracking-wide uppercase text-red-500">{step.name}</span>
                        <span className="text-sm text-gray-500">{step.description}</span>
                      </span>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
          </div>
          <div></div>
        </div>
        



        
        
      </div>
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="text-3xl font-bold mt-20">마스트벤처스 스타트업 수업에서는 이런 것을 합니다.</div>
        <div className="text-lg">
          <p className="mt-10">정부에서 매년 수조를 투입하여 창업기업을 만들고 있습니다. </p>
          <p>유니콘 기업을 넘어서 데카콘 기업이 생겨나고 있습니다.</p>
          <p>창업하기 정말 좋은 시대에 있지만 창업은 그렇게 쉽지 않습니다.</p>
          <p>창업하기 어려운 이유는 무엇일까요? 제가 생각할때는 막연함 이라고 생각합니다.</p>
          <p>많은 수업에서 배우지만</p>
          <p className="mt-10">창업을 하면 이 것이 중요합니다.</p>
          <p className-="ml-2"> - 아이디어 : 아이디어를 어떻게 짤 것인가.</p>
          <p className-="ml-2"> - 사람 : 사람을 어떻게 찾아올 것인가.</p>
          <p className-="ml-2"> - 자본 : 자본은 어디서 어떻게 끌어올 것인가.</p>
          <p className="mt-10">그래서 워너메이커 수업에서는</p>
          <p>- 창업 아이템을 어떻게 찾는지,</p>
          <p>- 이 아이템을 어떻게 정리하는지,</p>
          <p>- 이 아이템을 정리하여 사업화시킬때는 어떻게 하는지,</p>
          <p>- 아이템의 사업계획서는 어떻게 작성하는지,</p>
          <p>- 사업계획서 기반으로 발표는 어떻게 하는지,</p>
          <p>- 사업을 진행하기 위해 팀 빌딩을 어떻게 하는지를 알도록 함께 실천하는 시간을 갖습니다.</p>
          <p className="mt-10">마스트벤쳐스에서 수업에서 가장 중요하게 생각하는 것은 이것입니다. </p>
          <p>"생각을 행동으로 하는 것".</p>
          <p className="mt-10">그래서 본 수업에서는</p>
          <p>- 아이디어를 끄집어 내고,</p>
          <p>- 아이디어를 만들고,</p>
          <p>- 아이디어를 정리하고,</p>
          <p>- 아이디어 고객을 만나보고</p>
          <p>- 아이디어에 대한 간단한 계획서를 작성하고,</p>
          <p>- 간단한 계획서를 기반으로 사업계획서를 작성하고,</p>
          <p>- 시각화를 위한 ppt를 작성하고,</p>
          <p>- ppt를 기반으로 발표하고,</p>
          <p>- 가설에 대한 실제 고객을 만나고</p>
          <p>- 고객을 만나기 위해 광고도 진행해보고</p>
          <p>- 함께 사업할 사람들을 모집해보고</p>
          <p>- 스타트업들이 많이 쓰는 프로그램을 직접 써 보고 (예: 슬랙)</p>
          <p>- 사업을 도와줄 사람, 기관등을 만나보고</p>
          <p>- 매출을 일으켜보고</p>
          <p>- 정부지원금 또는 전문투자기관으로 부터 투자까지 받아보는 것이 학기 수업 과정 입니다.</p>
          <p>- 기업가정신을 배웁니다.</p>
          <p>- 토론하고 토의하고 아이디어를 정리합니다.</p>
          <p>- 용어정리 (스타트업 용어)</p>
          <p className="mt-10">우리는 사업을 하는 가장 중요한 이유 "소비자를 만나는 것"을 함께 실행합니다.</p>
          <p>내 상품을 사줄 사람이 없다면 그 사업은 실패한 것이나 다름없기 때문이지요.</p>
          <p>내 소비자를 어떻게 정리하고, 어떻게 설득하고, 어떤 식으로 만날 것인지 </p>
          <p>정비하고, 정리합니다.</p>
          <p className="mt-10">아이디어가 없다면 아이디어를 끌어내는 수업을 매주 하고, 그것을 실행하는 시간들로 이루어지며 결국 내가 창업을 할 수 밖에 없도록 하는 시간들이 될 것입니다.</p>
          <p className="mt-10">아이템을 찾는 것도, 내 아이템을 소개하는 것도 어려운데, 자신을 소개하는 것도 어렵습니다.</p>
        </div>
        <div className="text-3xl font-bold mt-20 hover:text-indigo-800"><button onClick={checkPaidStudent}># 마스트벤처스 4주간 아이디어 수업에서는 이런 것을 가르칩니다.</button></div>
        <div className={classNames( paidstudent ? 'block' : 'hidden', '')}>
        <p> 워너메이커에서 알려드릴 창업 아이템을 찾는 방법은 크게 두 가지 입니다.</p>

        <p> 1. 문제를 기반으로 창업하는 것 - 스타트업 창업</p>
        <p> 2. 사회 현상을 기반으로 아이템을 찾는 것 - 관찰을 통한 창업</p>
        <p> 3. 시장을 쪼개서 사업을 정리하기 - 시장 쪼개기</p>
        <p> 4. 기존에 있던 것을 새롭게 정의하는것 - 시장 분류하기</p>
        <p> 5. 남들이 하지 않는 것 찾기 - 조은이 IP를 기반으로 한 창업 (롱테일 법칙)</p>

        <p> 1 ) **문제를 기반으로 창업하는 것은 일단 문제에 대한 정의가 명확해야 합니다.**</p>

        <p> - 뭐가 문제야?</p>
        <p> - 어떻게 해결할래?</p>
        <p> - 그래서 돈은 누가 낼래?</p>

        <p> 워너메이커의 아이디어로 창업한 '댓글 관리 서비스 회사'를 예로 들어보겠습니다.</p>

        <p> - 뭐가 문제야? : 악플이 많이 달리는 것이 문제야.</p>
        <p> - 어떻게 해결할래? : 시놉티콘(역감시)으로 이 악플 현상을 해결할거야.</p>
        <p> - 그래서 돈은 누가 낼래? :  악플을 달아서 벌금을 무는 사람들이 이 돈을 내게 할 거야.</p>

        <p> 사람들이 연예인 관련 글에 왜 악플을 달게 될까요?</p>

        <p> '악플을 달아도 나에게 피해가 없다.' 라고 생각하기 때문에 악플이 생기는 것입니다.</p>

        <p> 한 예로 변호사 유투버를 보면 '댓글 청정지역'이라고 불립니다. 그 곳에 악플을 달면 나에게 피해가 생길 수 있다고 생각하기 때문이죠.</p>

        <p> 바로 나를 지켜보는 사람이 없다라고 생각하기 때문입니다. 그리고 사실 악플러를 고소하려면 해당 연예인이 직접 고소를 하거나 연예인의 소속사가 고소를 해야 하죠. </p>

        <p> 연예인 관련 글에 댓글이 많이 달리기에 악플러들은 보통 '설마 나 하나 고소를 하겠어?'라는 마음으로 악플을 답니다.  </p>

        <p> 그래서 이 회사에서는 바로 이런 점을 활용하여 연예인의 팬클럽에 속한 사람들이 이러한 악플들을 찾았을 때 바로 '악플러를 고소할 수 있도록' 합니다.</p>

        <p> 그리고 팬클럽에 의해 관리되고 있는 게시물이나 채널같은 경우 '이 게시글은 ooo에 의해 관리되어지고 있습니다'라는 댓글을 달리는 거죠. 누군가가 나를 지켜보고 있다는 마음이 드는 순간 악플을 다는 사람들이 낮아진다는 것이죠.</p>

        <p> 그런데 문제를 기반으로 그 문제를 해결하기 위한 창업을 해야 하는 것은 알지만, 어떤 문제가 있는지 찾기 어려워 하시는 분들도 분명 있습니다. </p>

        <p> 그런 경우, '사회 현상으로 아이템을 찾는 것'을 먼저 시작할 수 있습니다.</p>

        <p> '이게 있었으면 좋겠다' 라는 것을 찾아보는 것이죠. 그리고 이 것을 찾으면 다시 문제를 기반으로 창업하는 것으로 넘어오면 됩니다.</p>

        <p> 아이템을 새롭게 찾는 방법 </p>

        <p> 무엇이 더 나은가? 더 나은것이 무엇이가? </p>

        <p> 이것에. 대한 답은 스스로 내리는 것이 아니라 소비자가 내려야한다</p>

        <p> 2) **사회 현상으로 아이템을 찾는 것은 주변 환경을 잘 관찰해보는 것에서 시작됩니다.**</p>

        <p> 가장 첫번째 일은 '관찰' 입니다. 주변 사람들을 앉아서 관찰하는 것이죠.</p>

        <p> 가장 쉽게 말해 내가 있는 장소에서 대다수의 사람들이 하고 있는 것을 찾아보는 것부터 시작을 할 수 있습니다.</p>

        <p> 이 때 스스로 이런 질문을 해 볼 수 있습니다.</p>

        <p> - 여기 있는 사람들의 공통점은 뭐가 있을까?</p>
        <p> - 공통점 중에 공통적으로 돈을 내고 있는 것은 무엇이 있을까?</p>
        <p> - 그것을 해결하기 위해서 뭘 노력하고 있을까?</p>

        <p> 지금 제가 앉아있는 한 카페를 예시로 들어보겠습니다.</p>

        <p> 이 카페는 송도에 '카페꼼마'라는 곳으로 출판사 문학동네에서 만든 북카페로, 사람들이 장시간 책을 읽거나 공부를 하기에 최적화된 장소입니다.</p>

        <p> 위에 질문들을 참고하여 카페를 둘러보니 많은 분들이 책을 읽거나 동영상 강의 시청 등 노트북을 하며 목을 길게 빼거나 숙이고 계신 것이 보였습니다. </p>

        <p> '거북목'이 되기 쉬운 자세로 앉아 있는 것이죠. 이 거북목은 현대사회에서 일어나고 있는 '현상'중 하나 입니다.</p>

        <p> 그리고 요즘 사람들은 '거북목'을 치료하기 위해 돈을 투자합니다. 심지어 목 교정을 할 수 있도록 8만원 대의 '디닥넥'이라는 넥밴드를 사기도 하죠.</p>

        <p> 그런데 이런 넥밴드를 외부에서 휴대하며 사용하기에는 불편한 점이 많습니다. 이 것을 매일 가지고 다니기도 힘들죠. 그럼 이러한 문제점을 카페가 해결해 줄 수 있다면 어떨까요?</p>

        <p> 이렇게 사회 현상을 관찰해 냈다면, 다시 문제를 기반으로 창업을 하는 질문들을 던져봅니다.</p>

        <p> - 뭐가 문제야?</p>
        <p> - 어떻게 해결할래?</p>
        <p> - 그래서 돈은 누가 낼래?</p>

        <p> 아이템을 찾는 법 3번째 : 내가 쓸꺼야! 나의 상황에 필요한 창업 </p>

        <p> 그리고 아래와 같이 채워 보았어요.</p>

        <p> - 뭐가 문제야? : 사람들이 카페에서 있을 때 책을 읽거나 노트북으로 공부를 하는데, 이렇게 장시간 자세를 잡게 될 경우 거북목이 쉽게 될 수 있다는 것이 문제야.</p>
        <p> - 어떻게 해결할래? : 카페에서 거북목을 예방할 수 있는 테이블로 바꿔보는 것은 어떨까? (테이블에 부착가능한 거치대(독서대)를 만든다.)</p>
        <p> - 그래서 돈은 누가 낼래?: 테이블 개조 비용은 카페 사장님이 낸다. 그리고 이용자가 보증금을 낸다. 보증금을 내는 사람들은 그 카페에 더 자주 오게 될 테고 카페에 장시간 머무를 확률이 높다. 그래서 카페에서 쓰는 돈이 더 많아질 수 있다.</p>

        <p> 결국 카페에서 고객에게 말을 하는 것은 "카페에서 책을 편하게 보세요, 공부를 편하게 하세요"인 것입니다.</p>

        <p> 동영상 강의, 장시간 공부, 책을 장시간 읽는 사람들에게 필요한 것이 뭘까를 고민하면 그것 중 하나가 책상인 것이죠.</p>

        <p> 저 같으면 이렇게 만들 것입니다.</p>

        <p> 책상 한가운데에 구멍을 뚫어서 독서대와 연결할 수 있도록 만듭니다. 카페에서 평소에는 책상 자체로 배치하고, 독서대를 원하는 사람들은 프런트에 말을 해서 보증금을 받고 대여할 수 있도록 만듭니다. </p>

        <p> 카페에 있는 테이블을 전체 교체를 하는 것이 아닌, 원래 있던 테이블을 그대로 사용하는 방식이기에 카페 사장님들도 큰 부담없이 시도해볼 수 있는 방법이죠.</p>

        <p> 사람들이 자신의 거북목 예방을 위해 독서대를 가지고 카페에 오는 것이 아니라 카페가 자신의 고객을 위해 그것을 미리 준비할 수 있도록 돕는 것입니다.</p>

        <p> 여기에서 보증금은 꼭 있어야 합니다. 예를 들어 보증금을 연회비 3만원으로 설정할 수 있어요. </p>

        <p> 왜 보증금이 필요할까요? 사람들은 보증금을 넣어두었기 때문에 이 곳에 계속 와야겠다고 생각합니다.</p>

        <p> 사실  카페에게 필요한 것은 '고객관리 데이터'입니다. 보통 카페에서 고객 정보를 받을 수 있는 것은 '포인트 적립'밖에 없어요. 그런데 연회비가 있고, 보증금이 있으면 고객은 이 독서대를 활용하기 위해 자신이 카페에 온 것을 인증할 수 밖에 없습니다. 결국 고객이 얼마나 이 카페에 왔는지가 데이터로 쌓입니다.</p>

        <p> 이게 쌓이면 카페에서는 손님에 대한 객단가 파악이 가능합니다. 그럼 고객에게 맞춘 타겟 마케팅을 하기가 좋아지죠.</p>

        <p> 또, 아이템으로 카페 사장님들이 이득을 볼 수 있는 명확한 근거가 있기 때문에 카페를 돌아다니며 영업을 하기도 수월합니다.</p>

        <p> 또, 카페에서 흔히 볼 수 있는 문제점으로 '먹다 남은 음식'이 있습니다.</p>

        <p> - 관찰: 먹다가 음식 남았을 때가 포장하기가 불편해.</p>

        <p> 고객은 먹다 남은 음식을 포장해서 가고 싶어합니다.</p>

        <p> 그럼 이 때는 음식을 좀 한정 지어야 할 수 있어요. 고객이 프런트에 다시 와서 포장을 맡기지 않아도 되는 음식이면서 포장이 쉬운 음식(쿠키, 빵 등)을 생각해야 하죠.</p>

        <p> 이를 해결하기 위해 처음부터 고객이 카페에서 쿠키를 주문할 때 밑에 깔아주는 종이와 함께 빵포장 은박 철사 고리를 같이 줘서 고객이 스스로 포장해서 갈 수 있게 할 수 있습니다.</p>

        <p> 고객은 내가 먹다남은 음식을 프런트에 말하지 않고도 자유롭게 포장을 해 갈 수 있어 좋고, 카페 입장에서는 음식물 처리가 줄어들기 때문에 결국 환경에도 이득이 되는 사업 아이템인 셈이죠.</p>

        <p> 5) 사용법을 변경하여 창업하기 </p>

        <p> 사용법만 바꿔도 </p>

        <p> 외국에 '블랙벨트'라는 회사가 있습니다. 매출 몇 백억에 달하는 회사죠. </p>

        <p> 여기는 운동할 수 있는 검은색 끈을 팝니다. 대신 그 끈을 활용할 수 있는 여러 사용법을 알려주며 이 끈을 팔고 있습니다.</p>

        <p> 이처럼 사람들은 사용법만 바꿔져도 새로운 사용을 하게 됩니다.</p>

        <p> 대표적인 예가 아이패드에요.</p>

        <p> 처음에 살 때는 '공부해야지'라는 마음으로 샀다가 사용법을 좀 더 터득하면 넷플릭스 등 다양한 콘텐츠를 소비하는 활동을 하는 것 처럼 말이죠.</p>

        <p> 한양대학교 창업 강의를 간 적이 있습니다. 거기에서 한 학생을 만났는데, 이런 이야기를 하는 겁니다.</p>

        <p> - 학생: 대표님, 저는 이렇게 아이템을 찾았어요. 제가 카페에 갔는데, 거기에서 모든 사람들이 아이패드를 꺼내는 거에요! 사람들이 아이패드로 뭐를 할까를 봤는데 다들 책을 보더라구요! 그래서 저는 생각했어요. 아이패드에서 '책을 볼 수 있는 어플'을 만들어야겠다구요!</p>

        <p> 이 학생은 이렇게 관찰을 하는 것에서 창업 아이디어를 찾았습니다. 그런데 이 학생도 지금은 창업을 접었어요.</p>

        <p> 이 친구 옆에 아이디어가 생긴 다음의 스텝을 알려줄 선생님이 없던 것이 저는 가장 아쉽습니다. 이 친구 옆에 선생님이 있었다면 달랐겠다 라는 생각이 저는 많이 들었거든요.</p>

        <p> 이 친구가 창업 아이디어를 낸 다음으로 해야 했던 것은 이것이었습니다. </p>

        <p> 3) 시장을 쪼개서 창업하기 </p>

        <p> 회사를 구성하는 부서가 있습니다. 각 부서는 회사에 필요하기 때문에 </p>

        <p> **경쟁사 어플들을 다 써보면서 뭐가 불편한지를 찾는 것.**</p>

        <p> 이 친구는 '어플을 만들면 끝이다' 라고 생각을 했지만, 사실은 그게 아닙니다.</p>

        <p> 아이패드로 책을 읽는 어플을 많습니다. 그리고 사람들이 아이디어를 생각해도 '이 아이디어가 이미 있다'고 생각하는 순간 경쟁사가 있다라고 생각하고 포기하게 됩니다. 이 학생도 이런 부분으로 인해 포기를 해 버렸어요</p>

        <p> 저는 이게 가장 아쉽습니다.</p>

        <p> 오히려 경쟁사가 이미 있기 때문에 시장 수요가 있는 것이고, 나는 경쟁사들이 해결하지 못한 문제를 찾는것만 해도 되는 것인데 말이죠.</p>

        <p> 우리는 아이템을 새롭게 내가 창조하는 것보다 '기존에 있던 것을 어떻게 바꾸지' 라는 것을 생각해야 합니다.</p>

        <p> 저는 이런 사고방식을 갖는 것을 돕고, 아이템이 생각이 났을 때 스스로 그 아이디어를 확장하는 방법을 알고 실천하게끔 교육하고 싶습니다.</p>
      </div> */}
      </div>
      <MastPaymentFooter />
      <Footer />
    </>
  )
}