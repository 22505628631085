import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SubReviewCarousel} from '../subcomponents/reviewCarousel';
import Main from '../images/main_img.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import Helmet from 'react-helmet';
import { SubUserNotification } from '../subcomponents/userNotification'
import { SubMainHeader } from '../subcomponents/mainHeader';
import { SubVideoCard } from '../subcomponents/videoCard';
import { SubFooter } from '../subcomponents/subfooter';
import { Link, useHistory } from 'react-router-dom';
import Sailing from '../images/sailing.jpg';
import { SubLectureCard } from '../subcomponents/lectureCard';
import Debate from '../images/main.jpg';
import Reason1 from '../images/reason1.png';
import Reason2 from '../images/reason2.png';
import { SubQna } from '../subcomponents/qna';
import { SubRegular2LectureCard } from '../subcomponents/regular2LectureCard';
import { SubRegular1LectureCard } from '../subcomponents/regular1LectureCard';
import { SubMastPaymentFooter } from '../subcomponents/MastPaymentFooter';


export const SubMain = () => {
  const history = useHistory();

  const moveToIntro = () => {
    history.push('/program/idea/apply')
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      {/* <UserNotification /> */}
      <Helmet
        title="마스트벤처스"
        meta={[
          {
            name: 'description',
            content:
              "보다 현실적이고 실질적인 피드백으로 험난한 여정이지만 함께 나아갈 수 있도록 창업의 항해에 마스트벤처스가 돛이 되어 드립니다.",
          },
          {
            property: 'og:title',
            content: '마스트벤처스',
          },
          {
            property: 'og:image',
            content: '../images/mast_logo.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <SubMainHeader ismain={true} page={'main'} />
      <div style={{backgroundImage: `url(${Sailing})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat"}} 
              className="relative group h-screen "
      >
        <div className="max-w-7xl mx-auto pl-6 pt-40 sm:pt-60 text-xl sm:text-3xl GmarketM text-main">위대한 창업가를 만듭니다.</div>
        <div className="max-w-7xl mx-auto pl-6 pt-5 sm:pt-8 text-2xl sm:text-5xl GmarketB text-main" >직장을 다녀도, 학교를 다녀도,  <br />누구든 괜찮습니다. 창업 함께 해봅시다.</div>
        <div className="max-w-7xl mx-auto px-3 pt-8 text-base sm:text-xl GmarketL text-main">창업! 너무 해보고 싶지만 혼자서 하지 두렵고 무서웠다면</div>
        <div className="max-w-7xl mx-auto px-3 pt-1 text-base  sm:text-xl GmarketL text-main">나 같은 사람은 절대 창업에 어울리지 않다고 생각했다면</div>
        <div className="max-w-7xl mx-auto px-3 pt-1 text-base  sm:text-xl GmarketL text-main">이제는 함께 창업해요.</div>
        <div className="max-w-7xl mx-auto pl-6 pt-8 pb-60 text-lg text-main">
          <button className="rounded-full border py-2 px-5 text-base sm:text-lg text-white bg-main hover:bg-blue-900" onClick={moveToIntro}>수강 신청하기</button>
        </div>
      </div>
      <div className="my-52 max-w-7xl mx-auto grid md:grid-cols-5">
        <div className="pl-10 md:col-start-1 md:col-span-3 rounded-lg" style={{backgroundImage: `url(${Debate})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"360px"}} 
        ></div>
        <div className="px-5 md:col-start-4 md:col-span-2 mt-5 md:mt-12">
          <div className="text-lg GmarketM">아이디어 발굴 수업: 제작, 정리, 토론, 발표</div>
          <div className="text-2xl GmarketB pt-6">자신의 창업 아이템을 만듭니다.</div>
          <div className="text-2xl GmarketB">지금 당장 실현 가능한 사업아이템을 만들고,</div>
          <div className="text-2xl GmarketB">진짜 창업을 시작합니다.</div>
          <div className="text-lg sm:text-md GmarketM mt-10">아이템이 없어도 아이템이 있으면 더 구체적으로</div>
          <div className="text-lg sm:text-md GmarketM mt-1">실제 고객을 만들 수 있는 창업 아이템을 만듭니다.</div>
          {/* <div className="text-base sm:text-sm GmarketM mt-2 sm:mt-1 text-gray-500">틀린 것은 없습니다. 하지만 이제는 아이디어가 사업이 될 수 있게</div>
          <div className="text-base sm:text-sm GmarketM text-gray-500">구체적으로 정리하고 실천해봅시다.</div> */}
        </div>
      </div>
      <p className="mt-20 sm:mt-52 text-2xl GmarketB font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center">
        스타트업 창업 커리큘럼
      </p>
      <p className="text-base mt-5 GmarketM font-extrabold tracking-tight sm:text-lg text-center mb-10 text-gray-500">
        이론과 실행, 창업가를 만듭니다.
      </p>
      <div className="max-w-6xl px-3 sm:px-0 grid-cols-1 md:grid-cols-2 mx-auto grid lg:grid-cols-3 gap-16 mb-52"> 
        <SubLectureCard reviewPosts={''} />
        <SubRegular1LectureCard reviewPosts={''} />
        <SubRegular2LectureCard reviewPosts={''} />
      </div>
      <p className="mt-52 text-2xl GmarketB font-extrabold tracking-tight sm:text-3xl text-center">마스트벤처스에서</p>
      <p className="text-2xl GmarketB font-extrabold tracking-tight sm:text-3xl text-center">시작해야 하는 이유</p>
      <p className="mt-4 text-md GmarketL text-gray-900 tracking-tight sm:text-md text-center">'마음먹으세요. 쉽지만은 않을껍니다.'</p>
      <div className="my-20 sm:max-w-6xl mx-auto grid sm:grid-cols-2">
        <div className="mx-auto sm:pl-20 md:col-span-1 md:mt-20">
          <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">하나. 창업을 한번도 해보지 않아도 가능!</div>
          <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
          <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">수백번 먹었던 마음은 이제 그만 먹어도 됩니다.</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">창업부터 고객을 만나는 단계까지</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">끊임없이 함께 합니다.</div>
        </div>
        <div className="sm:col-span-1 hidden sm:grid sm:justify-items-end" style={{backgroundImage: `url(${Reason1})`, backgroundSize: '70% 80%', backgroundRepeat:"no-repeat", height:"360px"}}  ></div>
        <div className="sm:col-span-1  sm:hidden" style={{backgroundImage: `url(${Reason1})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"360px"}}  ></div>
      </div>
      <div className="my-20 max-w-6xl mx-auto grid md:grid-cols-2">
        <div className="pl-20 md:col-span-1 hidden sm:grid sm:justify-items-end" style={{backgroundImage: `url(${Reason2})`, backgroundSize: '85% 80%', backgroundRepeat:"no-repeat", height:"360px"}}></div>
        <div className=" md:col-span-1 md:mt-20">
          <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">둘. 토론, 토의, 발표합니다.</div>
          <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
          <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">매주 배운 내용으로 발표하고</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">매주 새로운 방식을 토론하고, 토의 합니다.</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">그렇게 나의 방식을 만듭니다.</div>
        </div>
        <div className="pl-20 md:col-span-1 sm:hidden" style={{backgroundImage: `url(${Reason2})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"360px"}}></div>
      </div>
      <div className="my-20 max-w-6xl mx-auto grid md:grid-cols-2">
        <div className="sm:pl-20 md:col-span-1 md:mt-20">
          <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">셋. 이론과 실행을 배웁니다.</div>
          <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
          <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">스타트업에서 가장 필요한 것만 배웁니다.</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">그리고 실행하게 됩니다.</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">성장과 성취를 맛보게 될 겁니다.</div>
        </div>
        <div className="md:col-span-1 hidden sm:grid sm:justify-items-end" style={{backgroundImage: `url(${Reason1})`, backgroundSize: '70% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
        <div className="md:col-span-1 sm:hidden" style={{backgroundImage: `url(${Reason1})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
      </div>
      <div className="my-20 max-w-6xl mx-auto grid md:grid-cols-2">
        <div className="pl-20 md:col-span-1 hidden sm:grid sm:justify-items-end" style={{backgroundImage: `url(${Reason2})`, backgroundSize: '85% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
        <div className=" md:col-span-1 md:mt-20">
          <div className="text-base text-center sm:text-left md:text-2xl GmarketB text-main">넷. 파트너가 함께 합니다.</div>
          <div className="w-5 h-1 hidden sm:inline-block bg-gray-500 mt-2 pl-1" ></div>
          <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">창업은 외롭기에 혼자 내버려두지 않을겁니다.</div>
          <div className="mt-2 text-center sm:text-left text-sm sm:text-lg GmarketM">파트너가 언제나 함께합니다.</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">우리는 포기하지 않을겁니다.</div>
          <div className="text-center sm:text-left text-sm sm:text-lg GmarketM">당신이 세상을 바꿀 수 있도록.</div>
        </div>
        <div className="pl-20 md:col-span-1 sm:hidden" style={{backgroundImage: `url(${Reason2})`, backgroundSize: '100% 80%', backgroundRepeat:"no-repeat", height:"360px"}} ></div>
      </div>

      {/* <p className="mt-52  text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl text-center">
        마스트벤처스 미리보기
      </p>
      <div className="grid grid-cols-4 gap-12 mt-24 mb-52 justify-items-stretch md:hidden">
        <div className="col-start-1 col-span-3">
          <VideoCard videoId="s4PNxgvQGFQ?t=464" />
          <VideoCard videoId="obNXXizoLug" />
        </div>
        
      </div>
      <div className="hidden md:overflow-hidden md:grid md:grid-cols-10 md:gap-5 mt-20 md:mb-24 md:justify-items-stretch ">
        <div className="col-start-3 col-span-2">
          <VideoCard videoId="s4PNxgvQGFQ?t=464" />
        </div>
        <div className="col-start-6 col-span-2">
          <VideoCard videoId="obNXXizoLug" />
        </div>
      </div> */}
      
      <SubReviewCarousel ismain={true} />
      <SubQna />
      <SubMastPaymentFooter />
      <SubFooter />
    </>
  )
}
