import { LockClosedIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { LevelStatus } from "../components/Levelstatus"
import Boat from '../images/boat.gif';
import { SubMastPaymentFooter } from "../subcomponents/MastPaymentFooter";

export const SubStep3 = () => {
  const [level, setLevel] = useState(1);

  useEffect(()=> {
    if(!(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin')) {
      setLevel(3);
    }
  }, []);
  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap h-screen" >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        <div className="text-center text-main text-xl">내 단계 : {level}</div>
        {!(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin') ?
          <div className="bg-main transform hover:scale-105 text-center mt-10 px-36 py-4 text-lg text-white cursor-pointer flex items-center" ><LockClosedIcon className="w-5 h-5 mr-2" />수업 경로</div> : 
          <div className="bg-main transform hover:scale-105 text-center mt-10 px-36 py-4 text-lg text-white cursor-pointer " >수업 경로</div>
        
        }
        {!(localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin') ?
          <div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer flex items-center" ><LockClosedIcon className="w-5 h-5 mr-2" />제출 이력</div> : 
          <div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer" >제출 이력</div>
        }
        {
          (localStorage.getItem('role') === 'PaidStudent' || localStorage.getItem('role') === 'Admin') ?
            <Link to={'/step/sailingStart'}><div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer">창업일지 작성</div></Link> :
            <>
              <Link to={'/step/sailingTest'}><div className="bg-main transform hover:scale-105 text-center mt-2 px-36 py-4 text-lg text-white cursor-pointer">창업일지 맛보기 </div></Link>
              <SubMastPaymentFooter />
            </>
        }
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}