/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router'

export const TestFeedback = () => {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const moveToProgram = () => {
    history.push('/program/idea')
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="text-center mt-3 text-2xl font-bold">강사님의 피드백 (예시) </div>
                <div className="mx-auto mt-3 flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    1. 문제가 뭐야? ( Problem )
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      생각지도 못한 문제를 발견하셨네요. 문제를 적을때는 최대한 쉽게 적는 것이 좋습니다.
                      누구나 그 문제를 들었을 때,  "아! 이건 진짜 문제지!"라고 공감이 간다면 
                      훨씬 더 좋은 문제발견이라고 할 수 있을것 같아요.
                      한번 더 문제를 명확하고 쉽게 작성할 수 있는지 생각해보시고, 이 문제를 주변친구나
                      가족들에게 이 문제를 설명해보세요. 문제에 대해 공감을 한다면 해결할 필요가 있는 
                      사업아이템이 될 수 있을것 같아요.
                    </p>
                  </div>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    2. 어떻게 해결할꺼야? ( Solution )
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      잘 작성해 주셨네요. 해결방법을 적을 때는 좀 현실적으로 가능한지, 내가 이 해결방법으로
                      해결할 능력이 있는지도 한번 더 고려해주면 좋을것 같아요. 지금 해결책보다 더 쉽게 
                      해결할 수있는 방법이 없는지 한번 생각해보세요!
                    </p>
                  </div>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    3. 돈을 어떻게 벌것인가? ( Money )
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      돈을 누가 낼 의향이 있는지 고객에 대한 정의를 디테일하게 한번 해보세요.
                      예를들면, 대학교를 다니고 있는 20대 초반의 여성, 막 취업을 한 사회초년생 등 
                      어떤 사람이 돈 낼 의향이 있을지 한번 더 생각해보세요. 그리고 정의한 고객 유형이 주변에 있다면
                      한번 설득해보세요.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={moveToProgram}
                >
                  강의 소개 보러가기
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
