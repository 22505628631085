import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LevelStatus } from "./Levelstatus"
import Boat from '../images/boat.gif';
import { commentPSMMutation, commentPSMMutationVariables } from "../__generated__/commentPSMMutation";
import { getPSM } from "../__generated__/getPSM";


interface IForm {
  question_1: string;
  understand_1: string;
  problem: string;
  solution: string;
  BM: string;
  q1_comment: string;
  q1_pass:string;
}

export const COMMENT_PSM_MUTATION = gql`
  mutation commentPSMMutation($commentPSMInput: commentPSMInput!) {
    commentPSM(input: $commentPSMInput) {
      ok,
      error
    }
  }
`;

export const GET_PSM_QUERY = gql`
  query getPSM {
    getPSM {
      ok
      error
      bms {
        understand_1,
        question_1,
        problem,
        solution,
        BM,
      }
    }
  }
`;

export const LogBook2 = () => {
  const [step, setStep] = useState(1);
  const { data } = useQuery<getPSM>(GET_PSM_QUERY);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  const temporarySave = () => {
      alert('임시저장 되었습니다.')
      // commentPSMMutation({
      //   variables: {
      //     commentPSMInput: {
      //       problem,
      //       solution,
      //       BM
      //     }
      //   }
      // });
  }
  const previous = () => {
    setStep(step-1);
  }
  const onCompleted = (data: commentPSMMutation) => {
    const {
      commentPSM: { ok, error },
    } = data;

    if (ok) {
      alert('PSM 작성완료!')
      window.location.reload();
    } else {
      alert(error)
    }
  };
  const [ commentPSMMutation, { data: commentPSMMutationResult, loading } ] = useMutation<commentPSMMutation, commentPSMMutationVariables>(COMMENT_PSM_MUTATION, { onCompleted});
  const onSubmit = () => {
    const { understand_1, question_1, problem, solution, BM } = getValues();
    if( step === 5 ) {
      if((understand_1 && question_1 && problem && solution && BM)) {
        alert('제출되었습니다.')
        commentPSMMutation({
          variables: {
            commentPSMInput: {
              understand_1,
              question_1,
              problem,
              solution,
              BM
            }
          }
        });
        
      } else {
        alert('모든 질문에 답변해주세요.')
      }
    } else {
      setStep(step +1);
    }
  }
  const inValid = () => {
    console.log(errors)
  }


  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap " >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        {/* <div className="text-4xl GmarketM transition-opacity text-center mb-12" style={{width:" 500px"}}>3일차 항해일지</div> */}
        {/* <div className="text-lg GmarketM transition-opacity text-center mb-20 text-red-400" style={{width:" 500px"}}>수강생에 한해서 강사님의 구체적인 피드백을 받을 수 있습니다.</div> */}
        <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
        {step ===1 && 
          <div className=" mt-10">
            <label htmlFor="understand_1" className=" text-lg font-medium text-gray-700 ">
              1. 오늘 수업 이해 되셨나요? ( 1 ~ 10 )
            </label>
            <div className="mt-1">
              <textarea
              {...register("understand_1", {
                required: "이름을 입력해주세요",
              })}
                id="understand_1"
                name="understand_1"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPSM?.bms ? data.getPSM?.bms[0]?.understand_1 : ''}
              />
            </div>
          </div>
        }
        {step ===2 && 
          <div className=" mt-10">
            <label htmlFor="question_1" className=" text-lg font-medium text-gray-700 ">
              2. 이해가 되지 않는다면 어떤 점이 이해되지 않았나요? (간단히 기록해주세요.)
            </label>
            <div className="mt-1">
              <textarea
              {...register("question_1", {
                required: "이름을 입력해주세요",
              })}
                id="question_1"
                name="question_1"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPSM?.bms ? data.getPSM?.bms[0]?.question_1 : ''}
              />
            </div>
          </div>
        }
        {step ===3 && 
          <div className=" mt-10">
            <div className="text-lg font-medium text-gray-900">현재 생각하는 아이디어를 PSM에 맞춰 적어봅시다.</div>
            <label htmlFor="problem" className=" text-lg font-medium text-gray-700 ">
              3. 문제가 뭐야? (Problem)
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 : 폰을 켜서 최근에 받은 앱을 소개 할 겁니다.</p>
            <p className="mt-2 text-sm text-gray-500">- 최근에 받은 앱은 어떤 문제를 생각해서 만들었을까요?   </p>
            <p className="mt-2 text-sm text-gray-500">- ex. 디즈니플러스 : 팬데믹으로 사람들이 영화관 가기 어려워졌다 /  </p>
            <p className="mt-2 text-sm text-gray-500"> 넷플릭스가 너무 뜨고 있다 / 디즈니 콘텐츠를 한곳에서 보는 곳이 필요했다.</p>
            <div className="mt-1">
              <textarea
              {...register("problem", {
                required: "이름을 입력해주세요",
              })}
                id="problem"
                name="problem"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPSM?.bms ? data.getPSM?.bms[0]?.problem : ''}
              />
            </div>
          </div>
        }
        {step ===4 && 
          <div className="mt-20">
            <label htmlFor="solution" className=" text-lg font-medium text-gray-700">
              4. 어떻게 해결할꺼야? (Solution)
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 위의 앱은 어떤 방식으로 문제를 해결하나요?</p>
            <p className="mt-2 text-sm text-gray-500">- ex. 디즈니플러스 : 자사콘텐츠만 볼수 있는 앱, 웹 서비스를 만들었다.</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("solution", {
                  required: "이름을 입력해주세요",
                })}
                id="solution"
                name="solution"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPSM?.bms ? data.getPSM?.bms[0]?.solution : ''}
              />
            </div>
          </div>
        }
        {step ===5 && 
          <div className="mt-20">
            <label htmlFor="BM" className=" text-lg font-medium text-gray-700">
              5. 돈은 누가 낼꺼야? ( 돈을 지불하게 될 대상을 적어보세요 )
            </label>
            <p className="mt-2 text-sm text-gray-500">- 아이디어가 없는 분은 위의 앱은 누가 돈을 내게 될지 적어보세요.</p>
            <p className="mt-2 text-sm text-gray-500">- ex. 디즈니플러스 : 콘텐츠가 보고 싶은 사람들이 월 구독으로 돈을 낸다. </p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("BM", {
                  required: "이름을 입력해주세요",
                })}
                id="BM"
                name="BM"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getPSM?.bms ? data.getPSM?.bms[0]?.BM : ''}
              />
            </div>
          </div>
        }
        <div className="flex justify-center mt-12">
          {/* <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            임시 저장
          </button> */}
          {step !== 1 &&
            <button
              type="button"
              onClick={previous}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
            >
              이전
            </button>
          }
          {/* <button
            type="button"
            onClick={temporarySave}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {'임시저장'}
          </button> */}
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {step === 5 ? '제출' : '다음'}
          </button>
        </div>
        </form>
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}