import { useState } from "react"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LevelStatus } from "../components/Levelstatus"
import Boat from '../images/boat.gif';


interface IForm {
  introduction: string;
}

export const SubLogBook1_1 = () => {
  const [step, setStep] = useState(1);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  const temporarySave = () => {
      alert('임시저장 되었습니다.')
      // commentPSMMutation({
      //   variables: {
      //     commentPSMInput: {
      //       problem,
      //       solution,
      //       BM
      //     }
      //   }
      // });
  }

  const onSubmit = () => {
    const { introduction } = getValues();
    alert('제출되었습니다.')
    
    }
  const inValid = () => {
    console.log(errors)
  }


  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap h-screen" >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        <div className="hidden sm:block text-4xl GmarketM transition-opacity text-center mb-12" style={{width:" 500px"}}>1일차 항해일지</div>
        <div className="sm:hidden text-2xl GmarketM transition-opacity text-center mb-12" style={{width:" 100vw"}}>1일차 항해일지</div>
        <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
          {step === 1 && 
            <div className=" mt-10">
              <label htmlFor="introduction" className=" text-lg font-medium text-gray-700 pl-3 sm:pl-0">
                자기소개 작성
              </label>
              <p className="mt-2 text-sm text-gray-500 pl-3 sm:pl-0">- 자유양식입니다. 자기소개를 간단히 기록해보세요.</p>
              <p className="mt-2 text-sm text-gray-500 pl-3 sm:pl-0">- 발표 시간은 3분 입니다. </p>
              <p className="mt-2 text-sm text-gray-500 pl-3 sm:pl-0">- 3분을 다 사용하셔도 돼고, 간단하게 발표하셔도 됩니다.  </p>
              <p className="mt-2 text-sm text-gray-500 pl-3 sm:pl-0">- 첫째날 저녁에 발표하게 됩니다. 두려워하지마세요. 모두가 함께 합니다. </p>
              <div className="mt-1 px-2 sm:px-0">
                <textarea
                {...register("introduction", {
                  required: "이름을 입력해주세요",
                })}
                  id="introduction"
                  name="introduction"
                  rows={3}
                  className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={''}
                />
                {/* <p className="mt-2 text-sm text-gray-500">문제인식에 대해 자세하게 작성해주세요</p>
                <p className="mt-2 text-sm text-gray-500">문제인식에 대해 자세하게 작성해주세요</p>
                <p className="mt-2 text-sm text-gray-500">문제인식에 대해 자세하게 작성해주세요</p> */}
              </div>
            </div>
          }
        <div className="flex justify-center mt-12">
          <button
            type="button"
            onClick={temporarySave}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {'임시저장'}
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {step === 1 ? '제출' : '다음'}
          </button>
        </div>
        </form>
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}