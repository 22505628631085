import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { LevelStatus } from "./Levelstatus"
import Boat from '../images/boat.gif';
import { GET_PSM_QUERY, COMMENT_PSM_MUTATION } from "./logbook2";
import { getTail } from "../__generated__/getTail";
import { commentTailMutation, commentTailMutationVariables } from "../__generated__/commentTailMutation";


interface IForm {
  understand_2: string;
  question_2: string;
  question2_1: string;
  question2_2: string;
  question2_3: string;
  question2_4: string;
  question2_5: string;
  question2_6: string;
}

export const COMMENT_TAIL_MUTATION = gql`
  mutation commentTailMutation($commentTailInput: commentTailInput!) {
    commentTail(input: $commentTailInput) {
      ok,
      error
    }
  }
`;

export const GET_TAIL_QUERY = gql`
  query getTail {
    getTail {
      ok
      error
      bms {
        understand_2,
        question_2,
        question2_1,
        question2_2,
        question2_3,
        question2_4,
        question2_5,
        question2_6
      }
    }
  }
`;

export const LogBook3 = () => {
  const [step, setStep] = useState(1);
  const { data } = useQuery<getTail>(GET_TAIL_QUERY);
  const { register, getValues, handleSubmit, formState: { errors } } = useForm<IForm>();

  
  const previous = () => {
    setStep(step-1);
  }
  const onCompleted = (data: commentTailMutation) => {
    const {
      commentTail: { ok, error },
    } = data;

    if (ok) {
      alert('생각에 꼬리물기 작성완료!')
      window.location.reload();
    } else {
      alert(error)
    }
  };
  const [ commentTailMutation, { data: commentTailMutationResult, loading } ] = useMutation<commentTailMutation, commentTailMutationVariables>(COMMENT_TAIL_MUTATION, { onCompleted});
  const onSubmit = () => {
    const { understand_2, question_2, question2_1, question2_2, question2_3, question2_4, question2_5, question2_6 } = getValues();
    if( step === 8 ) {
      if((understand_2 && question_2 && question2_1 && question2_2 && question2_3 && question2_4 && question2_5 && question2_6 )) {
        alert('제출되었습니다.')
        commentTailMutation({
          variables: {
            commentTailInput: {
              understand_2,
              question_2,
              question2_1,
              question2_2,
              question2_3,
              question2_4,
              question2_5,
              question2_6,
              
            }
          }
        });
        
      } else {
        alert('모든 질문에 답변해주세요.')
      }
    } else {
      setStep(step +1);
    }
  }


  const inValid = () => {
    console.log(errors)
  }


  return (
    //style={{backgroundImage: `url(${Boat})`, backgroundSize: '100% ', backgroundRepeat:"no-repeat", height:"100vh"}}
    <div className="flex content-center flex-wrap " >
      <div className="flex-1"></div> 
      <div className="flex-shrink-0">
        {/* <div className="text-4xl GmarketM transition-opacity text-center mb-12" style={{width:" 500px"}}>3일차 항해일지</div> */}
        {/* <div className="text-lg GmarketM transition-opacity text-center mb-20 text-red-400" style={{width:" 500px"}}>수강생에 한해서 강사님의 구체적인 피드백을 받을 수 있습니다.</div> */}
        <form action="/" onSubmit={handleSubmit(onSubmit, inValid)} className="mx-auto" style={{}}>
        {step === 1 && 
          <div className=" mt-10">
            <label htmlFor="understand_2" className=" text-lg font-medium text-gray-700 ">
              1. 생각에 꼬리물기 이해 되셨나요? (1–10)
            </label>
            <p className="mt-2 text-sm text-gray-500">- (어려울수 있습니다. 상처받지 않아요 솔직하게 적어주세요)</p>
            {/* <p className="mt-2 text-sm text-gray-500">- 그 앱이 생각하는 문제는 무엇인가요?  </p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙(slack) : 개인용과 분리된 업무용 메신저가 필요하다. </p> */}
            <div className="mt-1">
              <textarea
              {...register("understand_2", {
                required: "질문 1번에 대한답변을 입력해주세요",
              })}
                id="understand_2"
                name="understand_2"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.understand_2 : ''}
              />
            </div>
          </div>
        }
        {step === 2  && 
          <div className=" mt-10">
            <label htmlFor="question_2" className=" text-lg font-medium text-gray-700 ">
              2. 이해가 되지 않는다면 어떤점이 이해가 되지 않았는지 적어주세요 (간단히 기록해주세요)
            </label>
            {/* <p className="mt-2 text-sm text-gray-500">- 그 앱이 생각하는 문제는 무엇인가요?  </p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙(slack) : 개인용과 분리된 업무용 메신저가 필요하다. </p> */}
            <div className="mt-1">
              <textarea
              {...register("question_2", {
                required: "질문 1번에 대한답변을 입력해주세요",
              })}
                id="question_2"
                name="question_2"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.question_2 : ''}
              />
            </div>
          </div>
        }
        {step === 3 && 
          <div className=" mt-10">
            <label htmlFor="question2_1" className=" text-lg font-medium text-gray-700 ">
              3. 어떤 문제를 발견하셨나요? 평소 내가 생각했던 불편함은 어떤 것이 있나요?
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 마스크를 며칠동안 썻는지 모르겠어. 하루지나면 자동으로 색깔이 변하는 스티커를 붙인다면 어떨까?</p>
            {/* <p className="mt-2 text-sm text-gray-500">- 그 앱이 생각하는 문제는 무엇인가요?  </p>
            <p className="mt-2 text-sm text-gray-500">- ex. 슬랙(slack) : 개인용과 분리된 업무용 메신저가 필요하다. </p> */}
            <div className="mt-1">
              <textarea
              {...register("question2_1", {
                required: "질문 1번에 대한답변을 입력해주세요",
              })}
                id="question2_1"
                name="question2_1"
                rows={3}
                className="p-2 shadow-sm mt-5 block w-full h-48 focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.question2_1 : ''}
              />
            </div>
          </div>
        }
        {step ===4 && 
          <div className="mt-20">
            <label htmlFor="question2_2" className=" text-lg font-medium text-gray-700">
              4. 내 주변 사람들이 생각하는 불편함은 어떤 것이 있을까요? 고민해봅시다.
            </label>
            <p className="mt-2 text-sm text-gray-500"> ex. 사람들은 나처럼 마스크를 며칠씩이나 사용할까? 하루만에 버리진 않을까? </p>
            <p className="mt-2 text-sm text-gray-500"> 향기나는 스티커를 구매할까? 그냥 버리지 않을까?</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question2_2", {
                  required: "질문 2번에 대한답변을 입력해주세요",
                })}
                id="question2_2"
                name="question2_2"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.question2_2 : ''}
              />
            </div>
          </div>
        }
        {step ===5 && 
          <div className="mt-20">
            <label htmlFor="question2_3" className=" text-lg font-medium text-gray-700">
              5. 지금 주변을 관찰해보시고, 주변에 있는 사람들은 어떤 불편함을 가지고 있을지 한번 적어보세요
            </label>
            <p className="mt-2 text-sm text-gray-500">- ex. 찾는 방법은 시간을 내서 나가보세요, 버스도 타보고, 지하철도 타보고, 카페도 가보고 사람을 관찰해보세요.</p>
            <p className="mt-2 text-sm text-gray-500">- 사무실에 있는 사람들의 행동을 유심히 관찰해보세요. 일주일간 찾아보시기 바랍니다.</p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question2_3", {
                  required: "질문 3번에 대한답변을 입력해주세요",
                })}
                id="question2_3"
                name="question2_3"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.question2_3 : ''}
              />
            </div>
          </div>
        }
        {step === 6 && 
          <div className="mt-20">
            <label htmlFor="question2_4" className=" text-lg font-medium text-gray-700">
              6. 불편함을 해결한 기업을 적어보세요 (생각나지 않는 다면 집의 가전 가구 등을 참고해보세요)
            </label>
            {/* <p className="mt-2 text-sm text-gray-500">- 위 플랫폼에 관한 소감을 적어보세요..</p>
            <p className="mt-2 text-sm text-gray-500">- 사용하고 있다면 사용후기, 불편함이 있다면 불편함 등 자신의 생각을 적어보세요. </p> */}
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question2_4", {
                  required: "이름을 입력해주세요",
                })}
                id="question2_4"
                name="question2_4"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.question2_4 : ''}
              />
            </div>
          </div>
        }
        {step === 7 && 
          <div className="mt-20">
            <label htmlFor="question2_5" className=" text-lg font-medium text-gray-700">
              7. 불편함을 해결하게 만든 스타트업을 적어보세요 
            </label>
            <p className="mt-2 text-sm text-gray-500">- (생각나지 않는 다면 스마트폰 앱 중에 찾아보세요)</p>
            <p className="mt-2 text-sm text-gray-500">- (불편함을 해결한 기업을 카페에 적어보세요. 최근에 생긴 스타트업을 적어본다면 더 좋겠네요) </p>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question2_5", {
                  required: "이름을 입력해주세요",
                })}
                id="question2_5"
                name="question2_5"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.question2_5 : ''}
              />
            </div>
          </div>
        }
        {step === 8 && 
          <div className="mt-20">
            <label htmlFor="question2_6" className=" text-lg font-medium text-gray-700">
              8. 다시 한번 내가 찾은 불편함을 적어봅시다.
            </label>
            {/* <p className="mt-2 text-sm text-gray-500">- 위 플랫폼에 관한 소감을 적어보세요..</p>
            <p className="mt-2 text-sm text-gray-500">- 사용하고 있다면 사용후기, 불편함이 있다면 불편함 등 자신의 생각을 적어보세요. </p> */}
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                {...register("question2_6", {
                  required: "이름을 입력해주세요",
                })}
                id="question2_6"
                name="question2_6"
                rows={3}
                className="p-2 shadow-sm mt-5 h-48 block w-full focus:ring-main focus:border-main sm:text-sm border border-gray-300 rounded-md"
                defaultValue={data?.getTail?.bms ? data.getTail?.bms[0]?.question2_6 : ''}
              />
            </div>
          </div>
        }
        <div className="flex justify-center mt-12">
          {/* <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            임시 저장
          </button> */}
          {step !== 1 &&
            <button
              type="button"
              onClick={previous}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
            >
              이전
            </button>
          }
          {/* <button
            type="button"
            onClick={temporarySave}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {'임시저장'}
          </button> */}
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main hover:bg-indigo-700 focus:outline-none focus:ring-2 "
          >
            {step === 8 ? '제출' : '다음'}
          </button>
        </div>
        </form>
      </div> 
      <div className="flex-1"></div> 
    </div>

  )
}