import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { MainHeader } from '../components/mainHeader';
import { Footer } from '../components/footer';
import { Link, useHistory } from 'react-router-dom';
import Debate from '../images/main.jpg';
import { ReviewCard } from '../components/reviewCard';
import { Switch } from '@headlessui/react'
import { MastPaymentFooter } from '../components/MastPaymentFooter';


function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}



export const ProgramDetail = () => {
  const history = useHistory();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const apply = () => {

    if(localStorage.getItem('token')) {
      history.push('/program/idea/apply')
    } else {
      localStorage.setItem('directpage', '/program/idea/apply')
      history.push('/login')
    }
  }

  return (
    <>
      {/* <UserNotification /> */}
      <MainHeader ismain={false} page={'programdetail'} />
      <div className="mt-40 max-w-7xl mx-auto grid md:grid-cols-5">
        <div className="px-5 md:col-span-2 md:mt-12">
          <div className="text-4xl sm:text-5xl GmarketB">아이디어 발굴하기</div>
          <div className="text-md sm:text-lg GmarketL pt-12">먼 바다를 항해 할 수 있는 좋은 배를 만드는 것과 같이</div>
          <div className="text-md sm:text-lg GmarketL">오랫동안 사랑 받는 기업을 만들기 위해 </div>
          <div className="text-md sm:text-lg GmarketL">초석인 아이디어를 만듭니다.</div>
          <div className="text-md sm:text-lg GmarketL mt-2">4주간 배우고, 발표하고, 토론하며, 작성하여 </div>
          <div className="text-md sm:text-lg GmarketL">나만의 아이디어를 만들어갑니다.</div>
          <button className="mt-7 text-white GmarketM text-sm px-6 py-3 shadow-lg rounded-3xl border bg-main justify-center"><a onClick={apply}>수강신청 하러가기</a></button>
        </div>
        <div className="hidden sm:flex pl-10 md:col-span-3 rounded-lg" style={{backgroundImage: `url(${Debate})`, backgroundSize: 'cover', backgroundRepeat:"no-repeat", height:"370px"}}></div>
      </div>
      <div style={{backgroundColor: "#313131"}} className="w-screen h-40 sm:h-40 mt-16 text-center text-white GmarketM text-sm sm:text-3xl">
        <div className="pt-16">
          <span className="px-2 py-4 bg-main sm:mr-3">4주간</span> 
          <span>배우고 </span>
          <span className="sm:ml-10 px-2 py-4 bg-main sm:mr-3">4주간</span> 
          <span className="sm:mr-10">발표하고</span> 
          <span className="px-2 py-4 bg-main sm:mr-3">4주간</span> 
          <span>토론합니다.</span>
        </div>
      </div>
      <p className="mt-52 pl-5 text-2xl GmarketB font-extrabold tracking-tight sm:text-4xl max-w-4xl mx-auto">우리는 이렇게 배울거에요!</p>
      <div className="mt-12 max-w-4xl mx-auto grid md:grid-cols-12">
        <div className="md:col-span-1 grid justify-end">
          <div className="hidden sm:flex border-4 border-indigo-900 md:text-2xl rounded-full w-14 h-14 items-center justify-center text-indigo-900 GmarketM">4주</div>
        </div>
        <div className=" md:col-span-11 rounded-lg ml-4 GmarketM md:text-xl ">
          <p className="sm:mt-3 text-indigo-900 text-xl md:text-2xl">매주 목요일 19시-21시 </p>
        </div>
        
      </div>
      <div className="max-w-4xl mx-auto grid sm:grid-cols-12">
        <div className="sm:col-span-1 grid justify-end">
          <div className="grid justify-center pr-7">
            <div className="hidden sm:flex w-1 h-80 bg-indigo-900"></div>
          </div>
        </div>
        <div className=" sm:col-span-11 rounded-lg ml-4 GmarketM sm:text-xl ">
          <p className="mt-5 ">1. 아이디어를 찾는 방법(PSM)을 배우게 됩니다.</p>
          <p className="mt-2 ">2. 방법이 이해 되도록 다양한 사례와 예시를 배웁니다.</p>
          <p className="mt-2 ">3. 오랫동안 흔들리지 않도록 기업가정신을 배웁니다.</p>
          <p className="mt-2 ">4. 툴 사용을 통해 아이디어를 이미지화합니다.</p>
        </div>
      </div>
      <div className="max-w-4xl mx-auto grid sm:grid-cols-12">
        <div className="sm:col-span-1 grid justify-end">
          <div className="border-4 border-indigo-900 sm:text-2xl rounded-full w-14 h-14 hidden sm:flex items-center justify-center text-indigo-900 GmarketM">4주</div>
        </div>
        <div className=" sm:col-span-11 rounded-lg ml-4 GmarketM md:text-xl ">
          <p className="mt-10 sm:mt-3 text-indigo-900 text-xl md:text-2xl">매주 목요일 21시 ~ 23시</p>
          <p className="mt-5 ">1. 수업이 이해되었는지 스스로 항해일지를 작성합니다.</p>
          <p className="mt-2 ">2. 자기소개, 아이디어 발표, 사례 발표등 다양한 발표를 진행합니다.</p>
          <p className="mt-2 ">3. 다른 사람의 아이디어에 살을 붙이고, 토론에 참여합니다.</p>
          <p className="mt-2 ">4. 자신의 아이디어를 발표하고, 토론합니다.</p>
        </div>
      </div>
      <div className="max-w-4xl mx-auto">
        <p className="mt-52 mb-20 text-xl GmarketB font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center">
          우리는 이런 분을 찾습니다.
        </p>
        <div className="max-w-3xl mx-auto md:text-xl GmarketM">
          <div className="mt-5 p-4 text-center rounded-lg bg-gray-300 ">1. 이 글을 보고 있는 당신! </div>
          <div className="mt-5 p-4 text-center rounded-lg bg-gray-300 ">2. 창업은 특별한 사람만 하는거야 라고 생각하는 YOU</div>
          <div className="mt-5 p-4 text-center rounded-lg bg-gray-300 ">3. 아이디어가 실제로 가능할까? 라고 생각하는 분 </div>
          <div className="mt-5 p-4 text-center rounded-lg bg-gray-300 ">4. 고민의 고민의 고민의 고민만 하고 있는 분 </div>
          <div className="mt-5 p-4 text-center rounded-lg bg-gray-300 ">5. 아이디어가 작아보여 실행이 망설여지는 분 </div>
          <div className="mt-5 p-4 text-center rounded-lg bg-gray-300 ">6. 내 아이디어가 대단해서 발설하는 순간 빼앗길거라 생각하는 사람 </div>
          <div className="mt-5 p-4 text-center rounded-lg bg-gray-300 ">7. 아이디어로 세상을 바꾸고 싶은 분</div>
        </div>
        <div className="text-xl GmarketB font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center my-80">
          <p className="mt-5 ">창업을 해보지 않았어도</p>
          <p className="mt-5 ">나이가 어려도</p>
          <p className="mt-5 ">나이가 많아도</p>
          <p className="mt-5 ">성별도</p>
          <p className="mt-5 ">세상을 바꾸는데 아무런 문제가 없습니다.</p>
        </div>
      </div>
      <div className="bg-gray-200 h-auto">
        <p className="mt-68 pt-20 text-xl GmarketB font-extrabold text-gray-900 tracking-tight sm:text-3xl text-center">
          마스트벤처스와 함께한 대표들의 이야기
        </p>
        <ReviewCard filter={'idea'} />
      </div>
      
      <div className="max-w-4xl mx-auto mb-24">
        <p className="mt-52 text-xl GmarketB font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center">상세 커리큘럼</p>
        <p className="mt-5 mx-auto  text-base w-7/12  text-gray-800 tracking-tight sm:text-xl text-center">
          매주 목요일 오후 7-9시 실전강의 / 9-11시 작성 및 발표 
        </p>
        <p className="mt-16 text-xl GmarketB font-extrabold text-gray-800 tracking-tight sm:text-xl text-center flex item-center justify-center">
          <span className="mr-2">Reality</span>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
              enabled ? 'bg-main' : 'bg-main',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
          <span className="ml-2">Metaverse</span>
        </p>
        { enabled ?
          <div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">0주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">카톡확인 / 항해일지 작성</div>
            </div>
              
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">1주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">자기소개 발표 / 아이디어 발표 </div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">2주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">항해일지 발표</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">3주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">항해일지 발표</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">4주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">항해일지 발표</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">5주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">(선택사항) 온라인 해커톤</div>
            </div>
            {/* <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" md:text-lg pl-3 flex items-center justify-center text-black GmarketM">마지막 날</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">아이디어 최종 발표 및 피드백 </div>
            </div> */}
          </div> :
          <div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">0주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">위대한 항해의 시작 출항준비! 시작하기 전 숙제가 있어요! (카톡확인)</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">1주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">아이디어를 결정하는 질문 3가지 / 기업가정신</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">2주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">아이디어 구조 및 PW 사례 / 아이디어 글 작성</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">3주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">PB 구조 및 사례 / 이미지 구체화 하기</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">4주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">PC 구조의 이해, 사례 / tool 사용</div>
            </div>
            <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" border-2 border-indigo-900 md:text-lg rounded-full w-10 h-10 flex items-center justify-center text-black GmarketM">5주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">(선택사항) 온라인 해커톤 </div>
            </div>
            {/* <div className="mt-10 max-w-4xl mx-auto grid grid-cols-12">
              <div className="col-span-2 grid justify-end">
                <span className=" md:text-lg pl-3 flex items-center justify-center text-black GmarketM">5주</span>
              </div>
              <div className="col-span-10 ml-10 mt-1  text-black md:text-2xl">아이디어 최종 발표 및 피드백 </div>
            </div> */}
          </div> 
        }
        
      </div>
      <MastPaymentFooter />
      <Footer />
    </>
  )
}
