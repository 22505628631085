import { Footer } from "../components/footer"
import { Header2 } from "../components/header2"


export const Refund = () => {
  return (
    <>
      <Header2 page={'refund'} />
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl mt-20">
        
        <p className="text-2xl mb-5">마스트벤처스 환불정책</p>

        <p>안녕하세요. 스타트업을 만드는 컴퍼니빌더 마스트벤처스(이하 ‘회사’) 입니다.</p>
        <p>마스트벤처스 환불정책은(이하 정책) 은 마스트벤처스 서비스 이용 과 관련 된 내용을 담고 있습니다. 마스트벤처스 서비스 이용 전 꼭 확인해주세요.</p>



        <p>1. 수업 (아이디어 발굴학, 스타트업 사관학교 1,2 학기) </p>

        <p>환불요청일 및 환불금액</p>
        <p>수강기간 또는 수강진도 2/18 경과 전	결제대금의 16/18</p>
        <p>수강기간 또는 수강진도 3/18 경과 전	결제대금의 15/18</p>
        <p>수강기간 또는 수강진도 6/18 경과 전	결제대금의 12/18</p>
        <p>수강기간 또는 수강진도 8/18 경과 전	결제대금의 10/18</p>
        <p>수강기간 또는 수강진도 9/18 경과 전	결제대금의 9/18</p>
        <p>수강기간 또는 수강진도 12/18 경과 전	결제대금의 6/18</p>
        <p>수강기간 또는 수강진도 14/18 경과 전	결제대금의 4/18</p>
        <p>수강기간 또는 수강진도 15/18 경과 전	결제대금의 3/18</p>
        <p>수강기간 또는 수강진도 15/18 경과 후	환불 불가</p>


        <p>■수강기간이란 회사가 수강생에게 유상으로 제공하는 수업 이용기간으로서 6개월(180일)을 의미합니다. 개별수업에 따라 최소30일의 수강기간이 제공 될 수 있습니다.</p>
        <p>■ 수강기간은 결제일부터 시작합니다. 단, 결제일 당시 클래스영상을 이용할 수 없는 경우 수강기간은 클래스영상의 이용 가능일부터 시작합니다.</p>
        <p>■ 수강진도란 총 클래스영상 수 중 기 이용한 영상 수의 비율을 의미합니다. 단, 클래스영상 일부 재생 시 이용으로 간주합니다.</p>
        <p>■ 환불요청일 기준 수강기간과 수강진도 중 높은 값을 적용하여 환불금액을 산정합니다.</p>
        <p>■ 회원이 클래스와 함께 제공되는 자료 또는 정보를 다운로드하는 경우 해당 자료 또는 정보가 포함된 클래스영상을 이용한 것으로 간주합니다.</p>


        <p>2. 코칭권</p>

        <p>환불요청일	환불금액</p>
        <p>코칭권 이용기간 만료 전	결제대금 전액</p>
        <p>코칭권 이용기간 만료 후	환불 불가</p>


        <p>■ 코칭권 이용기간은 구매 당시 부여된 기간을 기준으로 합니다.</p>


        <p>3. 상품</p>

        <p>환불요청일	환불금액</p>
        <p>배송 시작 전	결제대금 전액</p>
        <p>출고 시부터 상품 수령 후 7일 경과 전	결제대금 전액 (단, 상품 반송에 소요되는 운송비를 공제)</p>
        <p>상품 수령일로부터 7일 경과 후	환불 불가</p>


        <p>■ 상품에 대한 환불절차는 상품의 미배송 또는 반송이 확인된 이후 진행됩니다.</p>
        <p>■ 상품 구성내용이 표시·광고의 내용과 다른 경우에는 상품을 수령한 날로부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 환불이 가능합니다.</p>
        <p>■ 아래의 경우에는 환불을 요청할 수 없습니다.</p>
        <p>- 회원에게 책임이 있는 사유로 상품이 멸실되거나 훼손된 경우</p>
        <p>- 회원이 상품을 사용하거나 또는 일부 소비하여 상품의 가치가 현저히 감소한 경우</p>
        <p>- 시간이 지나 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우</p>
        <p>- 복제가 가능한 상품의 포장을 훼손한 경우</p>
        <p>- 디지털콘텐츠 상품의 제공이 개시된 경우</p>
        <p>- 회사가 주문에 따라 개별적으로 생산되는 상품 등에 대하여 사전에 환불이 제한될 수 있음을 고지하고 동의를 받은 경우</p>
        <p>- 그 밖에 관계 법령 또는 약관에 정하여진 사유가 있는 경우</p>


        <p>5. 기타 사항</p>

        <p>■ 회사가 마케팅, 이벤트 등과 관련하여 무상으로 부여하는 추가 이용기간(보너스기간)은 환불에 영향을 미치지 아니합니다.</p>
        <p>■ 회사는 환불 시 금융거래수수료, 제세공과금 등을 공제할 수 있습니다.</p>
        <p>■ 회사는 회원이 관계 법령 또는 이용약관 등을 위반한 경우 이용약관 및 정책에 따라 환불을 거부할 수 있습니다.</p>
        <p>■ 결합 상품의 경우 각 구성 상품(용역)의 환불 조건에 의하되 개별 상품 안내페이지를 통하여 고지합니다.</p>


        <p>6. 정책의 개정</p>

        <p>■ 회사는 「약관의 규제에 관한 법률」, 「전자상거래 및 소비자보호에 관한 법률」 등 관계 법령을 위배하지 아니하는 범위 내에서 이 정책을 개정할 수 있습니다.</p>

        <p>■ 회사가 이 정책을 개정하는 경우 회원에게 주요 개정내용과 시행일 등을 명시하여 시행일로부터 7일 전에 전자적 방법으로 알려드리겠습니다. 다만, 회원에게 불리한 내용이 포함되는 경우 시행일로부터 30일 전에 알려드리겠습니다.</p>

        <p>마스트벤처스 서비스와 관련하여 궁금하신 사항이 있으시면 고객센터(대표번호: 070-5100-5577 / 평일 10:00~17:00)로 문의 주시기 바랍니다.</p>
      </div>
      <Footer />
    </>
  )
}